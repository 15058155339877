/* src/pages/followup/components/FollowModal/index.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 1000;
}

.modal-content {
  background: #fff;
  width: 100%;
  border-radius: 16px 16px 0 0;
  padding: 20px;
  position: relative;
  max-height: 90vh;
  overflow-y: auto;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modal-header h3 {
  margin: 0;
  color: #333;
}

.close-btn {
  background: none;
  border: none;
  font-size: 24px;
  color: #999;
  padding: 0;
  cursor: pointer;
}

.trader-brief {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.trader-brief .trader-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 12px;
}

.trader-brief .trader-info {
  flex: 1;
}

.trader-brief .trader-name {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin-bottom: 4px;
}

.trader-brief .trader-strategy {
  font-size: 14px;
  color: #999;
}

.stats-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 15px 0;
  border-bottom: 1px solid #eee;
}

.stat-item {
  text-align: center;
  flex: 1;
}

.stat-value {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.stat-label {
  font-size: 12px;
  color: #999;
  margin-top: 4px;
}

.input-section {
  margin-bottom: 20px;
}

.input-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  font-size: 14px;
  color: #666;
}

.input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 8px 12px;
}

.input-wrapper input {
  flex: 1;
  border: none;
  outline: none;
  font-size: 16px;
}

.input-wrapper input:disabled {
  background: #f5f5f5;
  color: #999;
}

.unit {
  color: #999;
  margin-left: 8px;
}

.confirm-btn {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s;
}

.confirm-btn.disabled {
  background: #ccc;
  color: #666;
  cursor: not-allowed;
}

.confirm-btn.active {
  background: rgb(24, 91, 255);
  color: white;
}