.rechargeorderlist-1 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  box-sizing: border-box;
  width: 100%;
  min-height: 100%;
  color: rgb(48, 49, 51);
  font-size: 14px;
}

.rechargeorderlist-2 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.rechargeorderlist-3 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 0px 10px;
}

.rechargeorderlist-4 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 10px;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  border-bottom: 1px solid rgb(248, 248, 251);
  box-shadow: rgba(3, 3, 3, 0.05) 0px 2px 5px 0px;
  border-radius: 15px;
  margin-top: 10px;
  background-color: rgb(255, 255, 255);
}

.rechargeorderlist-5 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.rechargeorderlist-6 {
  -webkit-tap-highlight-color: transparent;
  font-size: 16px;
  color: rgb(0, 0, 0);
  margin: 5px 0px;
  font-weight: 700;
}

.rechargeorderlist-7 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  font-weight: 700;
}

.rechargeorderlist-8 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.rechargeorderlist-9 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
}

.rechargeorderlist-10 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 14px;
}

.rechargeorderlist-11 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  color: rgb(28, 159, 255);
}

.rechargeorderlist-12 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 10px;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  border-bottom: 1px solid rgb(248, 248, 251);
  box-shadow: rgba(3, 3, 3, 0.05) 0px 2px 5px 0px;
  border-radius: 15px;
  margin-top: 10px;
  background-color: rgb(255, 255, 255);
}

.rechargeorderlist-13 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.rechargeorderlist-14 {
  -webkit-tap-highlight-color: transparent;
  font-size: 16px;
  color: rgb(0, 0, 0);
  margin: 5px 0px;
  font-weight: 700;
}

.rechargeorderlist-15 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  font-weight: 700;
}

.rechargeorderlist-16 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.rechargeorderlist-17 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
}

.rechargeorderlist-18 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 14px;
}

.rechargeorderlist-19 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  color: rgb(28, 159, 255);
}

.rechargeorderlist-20 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  padding: 10px;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  border-bottom: 1px solid rgb(248, 248, 251);
  box-shadow: rgba(3, 3, 3, 0.05) 0px 2px 5px 0px;
  border-radius: 15px;
  margin-top: 10px;
  background-color: rgb(255, 255, 255);
}

.rechargeorderlist-21 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.rechargeorderlist-22 {
  -webkit-tap-highlight-color: transparent;
  font-size: 16px;
  color: rgb(0, 0, 0);
  margin: 5px 0px;
  font-weight: 700;
}

.rechargeorderlist-23 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  font-weight: 700;
}

.rechargeorderlist-24 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.rechargeorderlist-25 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 11px;
}

.rechargeorderlist-26 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 14px;
}

.rechargeorderlist-27 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  color: rgb(28, 159, 255);
}