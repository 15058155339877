.propertycenterlb-new {
  font-family: Arial, sans-serif;
  background: linear-gradient(
    to bottom,
    #f5f5f5 0%,
    #00c18e4b 25%,
    #00c18e2d 50%,
    #f5f5f5 100%
  );
  color: #333;
  min-height: 100vh;
  padding: 0.5rem;
}
.propertycenter-header {
  padding: 0 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  font-size: 1rem;
  color: #333333;
}

/* 资产折合区域 */
.wallet-summary {
  background-image: url("../../../../../public/newPropertyC/top.png");
  background-size: contain;
  background-repeat: no-repeat;
  color: white;
  /* text-align: center; */
  border-radius: 10px;
  padding: 10px;
  width: 91.7%;
  height: 37%;
}
.total-asset {
  margin-left: 24px;
}

.total-asset .title {
  font-size: 0.9rem;
  margin-top: 10px;
}

.total-asset .amount {
  font-size: 1.1rem;
  font-weight: 600;
  margin: 10px 0 4px;
}

.total-asset .approximate {
  font-size: 0.85rem;
}

.wallet-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 18px 8px 12px;
  height: 30px;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.5);
}

.action-btn {
  background-color: transparent;
  /* background-color: white; */
  /* color: #00c18f; */
  padding: 0 20px;
  border: none;
  border-radius: 20px;
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 4px;
  > img {
    width: 1rem;
    height: 1rem;
  }
}

/* 搜索区域 */
.search-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background-color: rgba(255, 255, 255, 0.589);
  margin: 8px 8px;
  height: 30px;
  border-radius: 12px;
  font-weight: 600;
}

.search-bar-left {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #00c18f;
  > img {
    width: 1rem;
    height: 1rem;
  }
}

.search-bar input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
}

.small-assets-toggle {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #00c18f;
  > img {
    width: 1rem;
    height: 1rem;
  }
}

/* 资产列表 */
.assets-list {
  margin: 24px 8px;
  /* padding: 0 20px; */
}

.asset-item {
  display: flex;
  justify-content: space-between;
  background-image: url("../../../../../public/newPropertyC/listBg.png");
  background-size: contain;
  background-repeat: no-repeat;
  height: 20.3vw;
  margin-bottom: 4px;
  padding: 12px;
  color: white;
}

.asset-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 12px;
  padding-left: 12px;
}
.available {
  font-size: 0.8rem;
}

.asset-name {
  margin-top: 4px;
  font-size: 1.2rem;
}

.asset-values {
  text-align: right;
  display: flex;
  flex-direction: column;
}

.asset-amount {
  font-size: 1.1rem;
}
.asset-usd {
  font-size: 0.8rem;
  flex-grow: 1;
}
.frozen {
  padding-bottom: 12px;
}

.property-center {
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
  min-height: 100vh;
}

.top-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: #ffffff;
}

.top-nav h1 {
  font-size: 18px;
  font-weight: bold;
}

.back-icon, .menu-icon {
  width: 24px;
  height: 24px;
}

.asset-card {
  background-color: #007bff;
  color: white;
  padding: 20px;
  border-radius: 10px;
  margin: 15px;
  text-align: center;
}

.asset-title {
  font-size: 14px;
  margin-bottom: 10px;
}

.asset-amount {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 5px;
}

.asset-usd {
  font-size: 14px;
  opacity: 0.8;
}

.action-buttons {
  display: flex;
  justify-content: space-around;
  padding: 20px 15px;
  background-color: white;
  border-radius: 10px;
  margin: 15px;
}

.action-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: none;
  border: none;
  font-size: 14px;
}

.action-btn svg {
  width: 24px;
  height: 24px;
  margin-bottom: 5px;
}

.asset-list {
  background-color: white;
  border-radius: 10px;
  margin: 15px;
  padding: 15px;
}

.search-bar {
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  border-radius: 20px;
  padding: 10px;
  margin-bottom: 15px;
}

.search-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.search-bar input {
  flex-grow: 1;
  border: none;
  background: none;
  font-size: 14px;
}

.toggle-small-assets {
  display: flex;
  align-items: center;
  font-size: 12px;
}

.toggle-small-assets input {
  margin-left: 5px;
}

.coin-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  border-bottom: 1px solid #f0f0f0;
}

.coin-info {
  display: flex;
  align-items: center;
}

.coin-icon {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.coin-info h3 {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.coin-info p {
  font-size: 12px;
  color: #888;
}

.coin-value {
  text-align: right;
}

.coin-amount {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.coin-usd {
  font-size: 12px;
  color: #888;
}
