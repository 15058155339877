.optionbox3-1 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  margin: .75rem 1rem 0;
  border-top-left-radius: .63rem;
  border-top-right-radius: .63rem;
  overflow: hidden;
}

.optionbox3-1:after {
  border-color: rgb(30, 39, 48) !important;
}

.optionbox3-2 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  width: 100%;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  z-index: 2;
  top: 0;
  left: 0px;
  right: 0px;
  position: relative !important;
  background: var(--them-background);
}

.optionbox3-2:after {
  border-color: rgb(30, 39, 48) !important;
  content: " ";
  position: absolute;
  left: 0px;
  top: 0px;
  pointer-events: none;
  box-sizing: border-box;
  transform-origin: 0px 0px;
  width: 200%;
  height: 200%;
  transform: scale(0.5);
  z-index: 1;
  border: 0px;
  /*border-bottom: 1px solid rgba(0, 0, 0, 0.1);*/
}

.optionbox3-3 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  position: relative;
  width: 100%;
  height: 100%;
  max-height: inherit;
}

.optionbox3-4 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  position: relative;
  width: 100%;
  height: 100%;
  max-height: inherit;
  overflow: auto;
}

.optionbox3-5 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  width: 100%;
  height: 100%;
}

.optionbox3-6 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  padding: 0px 0.3125rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.optionbox3-6:after {
  border-color: rgb(30, 39, 48) !important;
}

.optionbox3-7 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-content: center;
  height: 42px;
  position: relative;
  z-index: 1;
  text-align: center;
  transition: all 0.2s ease 0s;
  font-size: 0.875rem;
  padding: 0px;
  width: calc(100% / 3);
  color: var(--them-color-green-text);
}

.optionbox3-7:after {
  border-color: rgb(30, 39, 48) !important;
}

.optionbox3-8 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  font-family: sans-serif;
  white-space: nowrap;
}

.optionbox3-8:after {
  border-color: rgb(30, 39, 48) !important;
}

.optionbox3-9 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}

.optionbox3-10 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-content: center;
  height: 42px;
  position: relative;
  z-index: 1;
  text-align: center;
  transition: all 0.2s ease 0s;
  font-size: 0.875rem;
  padding: 0px;
  width: calc(100% / 3);
  color: var(--them-color-gray-text);
}

.optionbox3-10:after {
  border-color: rgb(30, 39, 48) !important;
}

.optionbox3-11 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  font-family: sans-serif;
  white-space: nowrap;
}

.optionbox3-11:after {
  border-color: rgb(30, 39, 48) !important;
}

.optionbox3-12 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}

.optionbox3-13 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-content: center;
  height: 42px;
  position: relative;
  z-index: 1;
  text-align: center;
  transition: all 0.2s ease 0s;
  font-size: 0.875rem;
  padding: 0px;
  width: calc(100% / 3);
  color: rgb(255, 255, 255);
}

.optionbox3-13:after {
  border-color: rgb(30, 39, 48) !important;
}

.optionbox3-14 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  font-family: sans-serif;
  white-space: nowrap;
}

.optionbox3-14:after {
  border-color: rgb(30, 39, 48) !important;
}

.optionbox3-15 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}

.optionbox3-16 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  position: absolute;
  transition: all ease 0.25s;
  transform: translateX(59.484375px);
  bottom: 0px;
  width: 26.359375px;
  height: 0.1875rem;
  background: rgb(240, 193, 99);
  border-radius: 3.125rem;
}

.optionbox3-16:after {
  border-color: rgb(30, 39, 48) !important;
}
