.rechargelist-1 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  box-sizing: border-box;
  width: 100%;
  min-height: 100%;
  color: var(--them-color);
  font-size: 14px;
}

.rechargelist-2 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  box-sizing: border-box;
  min-height: 100%;
  overflow: hidden;
}

.rechargelist-3 {
  -webkit-tap-highlight-color: transparent;
  position: relative;
  border-radius: 10px;
  margin: 4px 5% 0px;
}

.rechargelist-4 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  padding: 10px;
  font-size: 14px;
}

.rechargelist-5 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
}

.rechargelist-6 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.rechargelist-7 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.rechargelist-8 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
}

.rechargelist-9 {
  -webkit-tap-highlight-color: transparent;
  font-size: 18px;
  margin: 15px auto 0px;
  font-weight: 700;
  text-align: center;
}

.rechargelist-10 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  padding: 14px 11px 35px 10px;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  flex-wrap: wrap;
}

.rechargelist-11 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  box-shadow: rgba(3, 3, 3, 0.05) 0px 2px 5px 0px;
  border-radius: 16px;
  /* background: rgb(255, 255, 255); */
  border: solid 1px var(--them-color);
  width: 173px;
  margin-bottom: 10px;
  @apply md:w-1/6;
}

.rechargelist-12 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
}

.rechargelist-13 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  color: var(--them-color);
  font-size: 16px;
  padding: 14px 10px 12px;
  border-bottom: 0.5px solid var(--them-color);
}

.rechargelist-14 {
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  margin: 0px 10px 0px 0px;
  width: 25px;
  height: 26px;
}

.rechargelist-15 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.rechargelist-16 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  max-width: 100%;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
}

.rechargelist-17 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  inset: 0px;
  overflow: hidden;
  display: block;
  z-index: -1;
  visibility: hidden;
  animation: 1ms ease 0s 1 normal none running once-show;
}

.rechargelist-18 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  inset: 0px;
  overflow: hidden;
}

.rechargelist-19 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100000px;
  height: 100000px;
}

.rechargelist-20 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  inset: 0px;
  overflow: hidden;
}

.rechargelist-21 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 200%;
  height: 200%;
}

.rechargelist-22 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  font-weight: 600;
}

.rechargelist-23 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.rechargelist-24 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  padding: 8px 15px 9px 0px;
  color: rgb(28, 159, 255);
  text-align: right;
}

.rechargelist-25 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  margin-right: 2px;
}

.rechargelist-26 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.rechargelist-27 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  font-kerning: auto;
  font-optical-sizing: auto;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1;
  font-family: FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
}

.rechargelist-27:before {
  content: "";
}

.rechargelist-28 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  box-shadow: rgba(3, 3, 3, 0.05) 0px 2px 5px 0px;
  border-radius: 16px;
  background: rgb(255, 255, 255);
  width: 173px;
  margin-bottom: 10px;
}

.rechargelist-29 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
}

.rechargelist-30 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  color: rgb(51, 51, 51);
  font-size: 16px;
  padding: 14px 10px 12px;
  border-bottom: 0.5px solid rgb(241, 243, 244);
}

.rechargelist-31 {
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  margin: 0px 10px 0px 0px;
  width: 25px;
  height: 26px;
}

.rechargelist-32 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.rechargelist-33 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  max-width: 100%;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
}

.rechargelist-34 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  inset: 0px;
  overflow: hidden;
  display: block;
  z-index: -1;
  visibility: hidden;
  animation: 1ms ease 0s 1 normal none running once-show;
}

.rechargelist-35 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  inset: 0px;
  overflow: hidden;
}

.rechargelist-36 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100000px;
  height: 100000px;
}

.rechargelist-37 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  inset: 0px;
  overflow: hidden;
}

.rechargelist-38 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 200%;
  height: 200%;
}

.rechargelist-39 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  font-weight: 600;
}

.rechargelist-40 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.rechargelist-41 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  padding: 8px 15px 9px 0px;
  color: rgb(28, 159, 255);
  text-align: right;
}

.rechargelist-42 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  margin-right: 2px;
}

.rechargelist-43 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.rechargelist-44 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  font-kerning: auto;
  font-optical-sizing: auto;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1;
  font-family: FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
}

.rechargelist-44:before {
  content: "";
}

.rechargelist-45 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  box-shadow: rgba(3, 3, 3, 0.05) 0px 2px 5px 0px;
  border-radius: 16px;
  background: rgb(255, 255, 255);
  width: 173px;
  margin-bottom: 10px;
}

.rechargelist-46 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
}

.rechargelist-47 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  color: rgb(51, 51, 51);
  font-size: 16px;
  padding: 14px 10px 12px;
  border-bottom: 0.5px solid rgb(241, 243, 244);
}

.rechargelist-48 {
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  margin: 0px 10px 0px 0px;
  width: 25px;
  height: 26px;
}

.rechargelist-49 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.rechargelist-50 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  max-width: 100%;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
}

.rechargelist-51 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  inset: 0px;
  overflow: hidden;
  display: block;
  z-index: -1;
  visibility: hidden;
  animation: 1ms ease 0s 1 normal none running once-show;
}

.rechargelist-52 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  inset: 0px;
  overflow: hidden;
}

.rechargelist-53 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100000px;
  height: 100000px;
}

.rechargelist-54 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  inset: 0px;
  overflow: hidden;
}

.rechargelist-55 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 200%;
  height: 200%;
}

.rechargelist-56 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  font-weight: 600;
}

.rechargelist-57 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.rechargelist-58 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  padding: 8px 15px 9px 0px;
  color: rgb(28, 159, 255);
  text-align: right;
}

.rechargelist-59 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  margin-right: 2px;
}

.rechargelist-60 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.rechargelist-61 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  font-kerning: auto;
  font-optical-sizing: auto;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1;
  font-family: FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
}

.rechargelist-61:before {
  content: "";
}

.rechargelist-62 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  box-shadow: rgba(3, 3, 3, 0.05) 0px 2px 5px 0px;
  border-radius: 16px;
  background: rgb(255, 255, 255);
  width: 173px;
  margin-bottom: 10px;
}

.rechargelist-63 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
}

.rechargelist-64 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  color: rgb(51, 51, 51);
  font-size: 16px;
  padding: 14px 10px 12px;
  border-bottom: 0.5px solid rgb(241, 243, 244);
}

.rechargelist-65 {
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  margin: 0px 10px 0px 0px;
  width: 25px;
  height: 26px;
}

.rechargelist-66 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.rechargelist-67 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  max-width: 100%;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
}

.rechargelist-68 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  inset: 0px;
  overflow: hidden;
  display: block;
  z-index: -1;
  visibility: hidden;
  animation: 1ms ease 0s 1 normal none running once-show;
}

.rechargelist-69 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  inset: 0px;
  overflow: hidden;
}

.rechargelist-70 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100000px;
  height: 100000px;
}

.rechargelist-71 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  inset: 0px;
  overflow: hidden;
}

.rechargelist-72 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 200%;
  height: 200%;
}

.rechargelist-73 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  font-weight: 600;
}

.rechargelist-74 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.rechargelist-75 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  padding: 8px 15px 9px 0px;
  color: rgb(28, 159, 255);
  text-align: right;
}

.rechargelist-76 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  margin-right: 2px;
}

.rechargelist-77 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.rechargelist-78 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  font-kerning: auto;
  font-optical-sizing: auto;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1;
  font-family: FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
}

.rechargelist-78:before {
  content: "";
}

.rechargelist-79 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  box-shadow: rgba(3, 3, 3, 0.05) 0px 2px 5px 0px;
  border-radius: 16px;
  background: rgb(255, 255, 255);
  width: 173px;
  margin-bottom: 10px;
}

.rechargelist-80 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
}

.rechargelist-81 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  color: rgb(51, 51, 51);
  font-size: 16px;
  padding: 14px 10px 12px;
  border-bottom: 0.5px solid rgb(241, 243, 244);
}

.rechargelist-82 {
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  margin: 0px 10px 0px 0px;
  width: 25px;
  height: 26px;
}

.rechargelist-83 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;

  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.rechargelist-84 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  max-width: 100%;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
}

.rechargelist-85 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  inset: 0px;
  overflow: hidden;
  display: block;
  z-index: -1;
  visibility: hidden;
  animation: 1ms ease 0s 1 normal none running once-show;
}

.rechargelist-86 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  inset: 0px;
  overflow: hidden;
}

.rechargelist-87 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100000px;
  height: 100000px;
}

.rechargelist-88 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  inset: 0px;
  overflow: hidden;
}

.rechargelist-89 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 200%;
  height: 200%;
}

.rechargelist-90 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  font-weight: 600;
}

.rechargelist-91 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.rechargelist-92 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  padding: 8px 15px 9px 0px;
  color: rgb(28, 159, 255);
  text-align: right;
}

.rechargelist-93 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  margin-right: 2px;
}

.rechargelist-94 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.rechargelist-95 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  font-kerning: auto;
  font-optical-sizing: auto;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1;
  font-family: FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
}

.rechargelist-95:before {
  content: "";
}

.rechargelist-96 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  padding: 14px 11px 35px 10px;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  flex-wrap: wrap;
}
