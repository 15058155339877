.become-trader-btn {
  width: 90%;
  height: 44px;
  margin: 20px auto;
  background: #1677ff;
  border: none;
  border-radius: 22px;
  color: white;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  display: block;
}


.become-trader-btn:active {
  background: #0958d9;
}

.user-info-section {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: #fff;
}

.followup-container {
  min-height: 100vh;
  background: #f5f5f5;
  padding-bottom: 20px;
}

.user-info-section {
  background: #ffffff;
  color: #1a1a1a;
  padding: 24px;
  border-radius: 0 0 24px 24px;
  box-shadow: 
    0 4px 20px rgba(22, 119, 255, 0.08),
    0 2px 4px rgba(22, 119, 255, 0.05);
  position: relative;
}

/* 添加装饰线 */
.user-info-section::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 6px;
  background: linear-gradient(90deg, #1677ff, #4096ff);
  border-radius: 3px 3px 0 0;
}

.user-profile {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.user-avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 12px;
  border: 2px solid rgba(255, 255, 255, 0.3);
}

.user-email {
  font-size: 16px;
  font-weight: 500;
}

.following-count {
  margin-left: auto;
  font-size: 14px;
  opacity: 0.9;
}

.user-stats {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
}

.stat-box {
  flex: 1;
  text-align: center;
}

.stat-box .stat-value {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 4px;
}

.stat-box .stat-label {
  font-size: 12px;
  /* opacity: 0.8; */
}

.follow-tabs {
  background: white;
  margin-top: 16px;
}

.tab-content {
  /* padding: 16px; */
}

.trader-card {
  background: white;
  border-radius: 12px;
  padding: 16px;
  margin-bottom: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
}

.trader-info {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.trader-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 12px;
}

.trader-details {
  flex: 1;
}

.trader-name {
  font-size: 16px;
  font-weight: 500;
  color: #333;
}

.follow-time {
  font-size: 12px;
  color: #999;
  margin-top: 4px;
}

.trader-stats {
  display: flex;
  justify-content: space-between;
}

.stat-item {
  flex: 1;
  text-align: center;
}

.stat-value {
  font-size: 16px;
  font-weight: 500;
  color: #333;
}

.profit-positive {
  color: #00b578;
}

.profit-negative {
  color: #ff3141;
}

.stat-label {
  font-size: 12px;
  color: #999;
  margin-top: 4px;
}

.empty-state {
  text-align: center;
  padding: 40px 0;
  color: #999;
}

.empty-state img {
  width: 120px;
  margin-bottom: 16px;
}

.loading-state {
  text-align: center;
  padding: 40px 0;
  color: #666;
}

/* 适配深色模式 */
@media (prefers-color-scheme: dark) {
  .followup-container {
    background: #1a1a1a;
  }

  .trader-card {
    background: #2a2a2a;
  }

  .trader-name {
    color: #fff;
  }

  .stat-value {
    color: #fff;
  }
}