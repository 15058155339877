.coinPopupTopTitlelb-1 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  z-index: 999;
  position: fixed;
  right: 0px;
  bottom: 0px;
  transition: all 0.25s ease 0s;
  overflow: hidden;
  background: rgb(15, 23, 32);
  top: 0px;
  left: 0px;
  width: 50vw;
  visibility: visible;
  transform: translate(0px);
}

.coinPopupTopTitlelb-1:after {
  border-color: rgb(30, 39, 48) !important;
}

.coinPopupTopTitlelb-2 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  width: 100%;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  height: auto;
  min-height: 100vh;
  max-height: 100vh;
}

.coinPopupTopTitlelb-2:after {
  border-color: rgb(30, 39, 48) !important;
}

.coinPopupTopTitlelb-3 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  position: relative;
  width: 100%;
  height: 100%;
  max-height: inherit;
}

.coinPopupTopTitlelb-4 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  position: relative;
  width: 100%;
  height: 100%;
  max-height: inherit;
  overflow: hidden auto;
}

.coinPopupTopTitlelb-5 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  width: 100%;
  height: 100%;
}

.coinPopupTopTitlelb-6 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  position: relative;
}

.coinPopupTopTitlelb-6:after {
  border-color: rgb(30, 39, 48) !important;
}

.coinPopupTopTitlelb-7 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  padding: 0.9375rem;
}

.coinPopupTopTitlelb-7:after {
  border-color: rgb(30, 39, 48) !important;
}

.coinPopupTopTitlelb-8 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: iconfont2 !important;
  font-style: normal;
  font-size: 1.0625rem;
  transform: rotate(180deg);
  display: block;
  color: rgb(255, 255, 255);
}

.coinPopupTopTitlelb-8:after {
  border-color: rgb(30, 39, 48) !important;
}

.coinPopupTopTitlelb-8:before {
  content: "";
}

.coinPopupTopTitlelb-9 {
  -webkit-tap-highlight-color: transparent;
  white-space: pre-line;
  scrollbar-width: none;
  font-family: sans-serif;
  margin: 0px 0.9375rem;
  font-size: 0.8125rem;
  padding-bottom: 0.125rem;
  color: rgb(255, 255, 255);
}

.coinPopupTopTitlelb-9:after {
  border-color: rgb(30, 39, 48) !important;
}

.coinPopupTopTitlelb-10 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  border-bottom: solid 3px rgb(240, 193, 99);
}

.coinPopupTopTitlelb-11 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  padding: 0.9375rem 0px;
}

.coinPopupTopTitlelb-11:after {
  border-color: rgb(30, 39, 48) !important;
}

.coinPopupTopTitlelb-12 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  align-items: center;
  padding: 0px 0.9375rem;
  height: 2.8125rem;
  font-weight: 700;
  font-size: 0.8125rem;
  transition: all 0.25s ease 0s;
  color: rgb(255, 255, 255);
}

.coinPopupTopTitlelb-12:after {
  border-color: rgb(30, 39, 48) !important;
}

.coinPopupTopTitlelb-13 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  align-items: center;
  padding: 0px 0.9375rem;
  height: 2.8125rem;
  font-weight: 700;
  font-size: 0.8125rem;
  transition: all 0.25s ease 0s;
  color: rgb(255, 255, 255);
  background: rgb(54, 57, 62);
}

.coinPopupTopTitlelb-13:after {
  border-color: rgb(30, 39, 48) !important;
}

.coinPopupTopTitlelb-14 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  align-items: center;
  padding: 0px 0.9375rem;
  height: 2.8125rem;
  font-weight: 700;
  font-size: 0.8125rem;
  transition: all 0.25s ease 0s;
  color: rgb(255, 255, 255);
}

.coinPopupTopTitlelb-14:after {
  border-color: rgb(30, 39, 48) !important;
}

.coinPopupTopTitlelb-15 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  align-items: center;
  padding: 0px 0.9375rem;
  height: 2.8125rem;
  font-weight: 700;
  font-size: 0.8125rem;
  transition: all 0.25s ease 0s;
  color: rgb(255, 255, 255);
}

.coinPopupTopTitlelb-15:after {
  border-color: rgb(30, 39, 48) !important;
}

.coinPopupTopTitlelb-16 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  align-items: center;
  padding: 0px 0.9375rem;
  height: 2.8125rem;
  font-weight: 700;
  font-size: 0.8125rem;
  transition: all 0.25s ease 0s;
  color: rgb(255, 255, 255);
}

.coinPopupTopTitlelb-16:after {
  border-color: rgb(30, 39, 48) !important;
}

.coinPopupTopTitlelb-17 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  align-items: center;
  padding: 0px 0.9375rem;
  height: 2.8125rem;
  font-weight: 700;
  font-size: 0.8125rem;
  transition: all 0.25s ease 0s;
  color: rgb(255, 255, 255);
}

.coinPopupTopTitlelb-17:after {
  border-color: rgb(30, 39, 48) !important;
}

.coinPopupTopTitlelb-18 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  align-items: center;
  padding: 0px 0.9375rem;
  height: 2.8125rem;
  font-weight: 700;
  font-size: 0.8125rem;
  transition: all 0.25s ease 0s;
  color: rgb(255, 255, 255);
}

.coinPopupTopTitlelb-18:after {
  border-color: rgb(30, 39, 48) !important;
}

.coinPopupTopTitlelb-19 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  align-items: center;
  padding: 0px 0.9375rem;
  height: 2.8125rem;
  font-weight: 700;
  font-size: 0.8125rem;
  transition: all 0.25s ease 0s;
  color: rgb(255, 255, 255);
}

.coinPopupTopTitlelb-19:after {
  border-color: rgb(30, 39, 48) !important;
}

.coinPopupTopTitlelb-20 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  align-items: center;
  padding: 0px 0.9375rem;
  height: 2.8125rem;
  font-weight: 700;
  font-size: 0.8125rem;
  transition: all 0.25s ease 0s;
  color: rgb(255, 255, 255);
}

.coinPopupTopTitlelb-20:after {
  border-color: rgb(30, 39, 48) !important;
}

.coinPopupTopTitlelb-21 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  align-items: center;
  padding: 0px 0.9375rem;
  height: 2.8125rem;
  font-weight: 700;
  font-size: 0.8125rem;
  transition: all 0.25s ease 0s;
  color: rgb(255, 255, 255);
}

.coinPopupTopTitlelb-21:after {
  border-color: rgb(30, 39, 48) !important;
}

.coinPopupTopTitlelb-22 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  align-items: center;
  padding: 0px 0.9375rem;
  height: 2.8125rem;
  font-weight: 700;
  font-size: 0.8125rem;
  transition: all 0.25s ease 0s;
  color: rgb(255, 255, 255);
}

.coinPopupTopTitlelb-22:after {
  border-color: rgb(30, 39, 48) !important;
}

.coinPopupTopTitlelb-23 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  align-items: center;
  padding: 0px 0.9375rem;
  height: 2.8125rem;
  font-weight: 700;
  font-size: 0.8125rem;
  transition: all 0.25s ease 0s;
  color: rgb(255, 255, 255);
}

.coinPopupTopTitlelb-23:after {
  border-color: rgb(30, 39, 48) !important;
}
