.lockUpRuning-1 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px 16px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.lockUpRuning-2 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 18px 12px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: rgb(255, 255, 255);
  margin-top: 15px;
  border-radius: 5px;
}

.lockUpRuning-3 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lockUpRuning-4 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.lockUpRuning-5 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  border-style: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  width: 28px;
  height: 28px;
}

.lockUpRuning-6 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px 14px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 16px;
  vertical-align: baseline;
  background: transparent;
  color: rgb(51, 51, 51);
  font-weight: 500;
  flex: 1 1 0%;
  text-align: left;
}

.lockUpRuning-7 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  color: rgb(255, 255, 255);
  font: inherit;
  margin: 0px;
  overflow: visible;
  text-transform: none;
  position: relative;
  display: inline-block;
  height: 32px;
  padding: 0px 8px;
  font-size: 12px;
  line-height: 1.2;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  transition: opacity 0.2s ease 0s;
  appearance: none;
  background-color: rgb(7, 193, 96);
  border: none;
  background: #5ecc9d!important;
}

.lockUpRuning-7:focus {
  outline: 0px;
}

.lockUpRuning-7:before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  border-style: inherit;
  border-width: inherit;
  border-image: inherit;
  border-color: rgb(0, 0, 0);
  border-radius: inherit;
  transform: translate(-50%, -50%);
  
  content: " ";
}

.lockUpRuning-8:focus {
  outline: 0px;
}

.lockUpRuning-8 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 100%;
}

.lockUpRuning-8:before {
  content: " ";
}

.lockUpRuning-9:focus {
  outline: 0px;
}

.lockUpRuning-9 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lockUpRuning-10 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.lockUpRuning-11 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.lockUpRuning-12 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lockUpRuning-12:first-child {
  color: rgb(119, 119, 119);
  font-size: 12px;
  font-weight: 400;
}

.lockUpRuning-12:last-child {
  margin-top: 6px;
  color: rgb(54, 54, 54);
  font-size: 12px;
  font-weight: 600;
}

.lockUpRuning-13 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lockUpRuning-13:first-child {
  color: rgb(119, 119, 119);
  font-size: 12px;
  font-weight: 400;
}

.lockUpRuning-13:last-child {
  margin-top: 6px;
  color: rgb(54, 54, 54);
  font-size: 12px;
  font-weight: 600;
}

.lockUpRuning-14 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.lockUpRuning-15 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lockUpRuning-15:first-child {
  color: rgb(119, 119, 119);
  font-size: 12px;
  font-weight: 400;
}

.lockUpRuning-15:last-child {
  margin-top: 6px;
  color: rgb(54, 54, 54);
  font-size: 12px;
  font-weight: 600;
}

.lockUpRuning-16 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lockUpRuning-16:first-child {
  color: rgb(119, 119, 119);
  font-size: 12px;
  font-weight: 400;
}

.lockUpRuning-16:last-child {
  margin-top: 6px;
  color: rgb(54, 54, 54);
  font-size: 12px;
  font-weight: 600;
}

.lockUpRuning-17 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.lockUpRuning-18 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.lockUpRuning-19 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lockUpRuning-19:first-child {
  color: rgb(119, 119, 119);
  font-size: 12px;
  font-weight: 400;
}

.lockUpRuning-19:last-child {
  margin-top: 6px;
  color: rgb(54, 54, 54);
  font-size: 12px;
  font-weight: 600;
}

.lockUpRuning-20 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lockUpRuning-20:first-child {
  color: rgb(119, 119, 119);
  font-size: 12px;
  font-weight: 400;
}

.lockUpRuning-20:last-child {
  margin-top: 6px;
  color: rgb(54, 54, 54);
  font-size: 12px;
  font-weight: 600;
}

.lockUpRuning-21 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.lockUpRuning-22 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lockUpRuning-22:first-child {
  color: rgb(119, 119, 119);
  font-size: 12px;
  font-weight: 400;
}

.lockUpRuning-22:last-child {
  margin-top: 6px;
  color: rgb(54, 54, 54);
  font-size: 12px;
  font-weight: 600;
}

.lockUpRuning-23 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lockUpRuning-23:first-child {
  color: rgb(119, 119, 119);
  font-size: 12px;
  font-weight: 400;
}

.lockUpRuning-23:last-child {
  margin-top: 6px;
  color: rgb(54, 54, 54);
  font-size: 12px;
  font-weight: 600;
}

.lockUpRuning-24 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.lockUpRuning-25 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lockUpRuning-25:first-child {
  color: rgb(119, 119, 119);
  font-size: 12px;
  font-weight: 400;
}

.lockUpRuning-25:last-child {
  margin-top: 6px;
  color: rgb(54, 54, 54);
  font-size: 12px;
  font-weight: 600;
}

.lockUpRuning-26 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lockUpRuning-26:first-child {
  color: rgb(119, 119, 119);
  font-size: 12px;
  font-weight: 400;
}

.lockUpRuning-26:last-child {
  margin-top: 6px;
  color: rgb(54, 54, 54);
  font-size: 12px;
  font-weight: 600;
}
