.languagechange-1 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  padding: 0.9375rem;
  /* background: #1c1c1f; */
}

.languagechange-1:after {
  border-color: rgb(30, 39, 48) !important;
}

.languagechange-2 {
  -webkit-tap-highlight-color: transparent;
  display: block;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  font-weight: 700;
  font-size: 2.1875rem;
  margin: 0 0 1.25rem;
  /* color: rgba(255, 255, 255, 0.8); */
}

.languagechange-2:after {
  border-color: rgb(30, 39, 48) !important;
}

.languagechange-3 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  white-space: pre-line;
  scrollbar-width: none;
  font-family: sans-serif;
}

.languagechange-3:after {
  border-color: rgb(30, 39, 48) !important;
}

.languagechange-4 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}

.languagechange-5 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}

.languagechange-5:after {
  border-color: rgb(30, 39, 48) !important;
}

.languagechange-6 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  align-items: center;
  padding: 0.78125rem 0px;
  justify-content: space-between;
}

.languagechange-6:active {
  opacity: 0.7;
}

.languagechange-6:after {
  border-color: rgb(30, 39, 48) !important;
}

.languagechange-7 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  white-space: pre-line;
  scrollbar-width: none;
  font-family: sans-serif;
  font-size: 0.9375rem;
  /* color: rgba(255, 255, 255, 0.8); */
}

.languagechange-7:after {
  border-color: rgb(30, 39, 48) !important;
}

.languagechange-8 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}

.languagechange-9 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: iconfont2;
  font-style: normal;
  font-size: 1rem;
  /* color: rgb(240, 193, 99); */
}

.languagechange-9:after {
  border-color: rgb(30, 39, 48) !important;
}

.languagechange-9:before {
  content: "\e645";
}

.languagechange-10 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  align-items: center;
  padding: 0.78125rem 0px;
  justify-content: space-between;
}

.languagechange-10:active {
  opacity: 0.7;
}

.languagechange-10:after {
  border-color: rgb(30, 39, 48) !important;
}

.languagechange-11 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  white-space: pre-line;
  scrollbar-width: none;
  font-family: sans-serif;
  font-size: 0.9375rem;
  /* color: rgba(255, 255, 255, 0.8); */
}

.languagechange-11:after {
  border-color: rgb(30, 39, 48) !important;
}

.languagechange-12 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}

.languagechange-13 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  align-items: center;
  padding: 0.78125rem 0px;
  justify-content: space-between;
}

.languagechange-13:active {
  opacity: 0.7;
}

.languagechange-13:after {
  border-color: rgb(30, 39, 48) !important;
}

.languagechange-14 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  white-space: pre-line;
  scrollbar-width: none;
  font-family: sans-serif;
  font-size: 0.9375rem;
  /* color: rgba(255, 255, 255, 0.8); */
}

.languagechange-14:after {
  border-color: rgb(30, 39, 48) !important;
}

.languagechange-15 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}