/* src/pages/followup/index.css */
.follow-container {
  padding: 16px;
  padding-top: 0px;
  background: #fff;
  min-height: 100dvh;
  color: #333;
  padding-bottom: 100px;
}

.follow-header {
  margin-bottom: 12px;
}

.follow-title {
  font-size: 16px;
  margin: 0;
  font-weight: bold;
  color: #333;
}

.follow-subtitle {
  font-size: 12px;
  color: #666;
  margin: 8px 0 0 0;
}

.follow-functions {
  display: flex;
  gap: 12px;
}

.function-card {
  flex: 1;
  background: #f5f5f5;
  border-radius: 8px;
  padding: 16px;
  cursor: pointer;
}

.function-content {
  position: relative;
  height: 100%;
  /* min-height: 80px; */
}

.text-content {
  position: relative;
  z-index: 1;
}

.text-content h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: #000;
}

.text-content p {
  margin: 4px 0 0;
  font-size: 12px;
  color: #666;
}

.ranking-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}


.function-icon {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 40px;
  height: 40px;
  object-fit: contain;
}
.function-icon1 {
  position: absolute;
  bottom: -4px;
  right: 0;
  width: 48px;
  height: 48px;
  object-fit: contain;
}



.function-content h3 {
  font-size: 16px;
  margin: 0 0 8px 0;
  color: #333;
}

.function-content p {
  font-size: 12px;
  color: #666;
  margin: 0;
}

.trader-card {
  background: linear-gradient(to bottom right, rgb(255, 252, 248) 0%, rgb(252, 248, 240) 100%);
  border: 1px solid rgba(238, 234, 226, 0.5);
  border-radius: 12px;
  padding: 16px;
  margin-bottom: 16px;
}

.trader-info {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.trader-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 12px;
}

.trader-basic {
  flex: 1;
}

.trader-name {
  font-size: 16px;
  font-weight: 500;
  color: #333;
}

.trader-ranking {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #666;
}

.follow-btn {
  background: rgb(24, 91, 255);
  color: white;
  border: none;
  border-radius: 20px;
  padding: 8px 24px;
  cursor: pointer;
  font-size: 14px;
}

.vote-btn {
  background: transparent;
  border: 1px solid #ddd;
  color: #666;
  border-radius: 20px;
  padding: 8px 24px;
  cursor: pointer;
  font-size: 14px;
  margin-right: 12px;
}

.trader-stats {
  margin-bottom: 16px;
}

.stat-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  font-size: 14px;
}

.stat-label {
  color: #666;
}

.stat-value {
  color: #333;
}

.stat-value.profit {
  color: rgb(28, 173, 144);
  font-weight: 500;
}

.stat-value.loss {
  color: rgb(205, 78, 101);
  font-weight: 500;
}

.trader-details {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  padding-top: 16px;
}

.detail-item {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  margin-bottom: 8px;
  color: #666;
}

.detail-item span:last-child {
  color: #333;
}

.section-title {
  font-size: 16px;
  margin: 12px 0 12px;
  color: #333;
  font-weight: 500;
}

.trader-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-size: 14px!important;
}