.reloadPassword-1 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    padding: 0.9375rem;
  }
  
  .reloadPassword-1:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPassword-2 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    margin-bottom: 2.5rem;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  
  .reloadPassword-2:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPassword-3 {
    -webkit-tap-highlight-color: transparent;
    display: block;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    font-weight: 700;
    font-size: 2.1875rem;
    margin: undefined;
    color: rgba(255, 255, 255, 0.8);
  }
  
  .reloadPassword-3:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPassword-4 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    white-space: pre-line;
    scrollbar-width: none;
    font-family: sans-serif;
  }
  
  .reloadPassword-4:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPassword-5 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
  }
  
  .reloadPassword-6 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
  }
  
  .reloadPassword-6:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPassword-7 {
    -webkit-tap-highlight-color: transparent;
    display: block;
    width: 100%;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    z-index: 2;
    top: 0;
    left: 0px;
    right: 0px;
    position: static !important;
    background: rgb(15, 23, 32);
    margin: 0px -0.9375rem 0.625rem;
  }
  
  .reloadPassword-7:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPassword-8 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    position: relative;
    width: 100%;
    height: 100%;
    max-height: inherit;
  }
  
  .reloadPassword-9 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    position: relative;
    width: 100%;
    height: 100%;
    max-height: inherit;
    overflow: auto;
  }
  
  .reloadPassword-10 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    width: 100%;
    height: 100%;
  }
  
  .reloadPassword-11 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
  }
  
  .reloadPassword-11:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPassword-12 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    display: flex;
    align-items: center;
    white-space: nowrap;
    justify-content: center;
    height: 50px;
    font-size: 1rem;
    position: relative;
    z-index: 1;
    text-align: center;
    transition: all 0.2s ease 0s;
    margin-left: 1.0625rem;
    padding: 0px;
    color: rgb(255, 255, 255);
    width: fit-content;
    margin-right: 0.9375rem;
    border-bottom: solid 3px rgb(240, 193, 99);
  }
  
  .reloadPassword-12:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPassword-13 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: none;
    font-family: sans-serif;
    white-space: nowrap;
  }
  
  .reloadPassword-13:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPassword-14 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
  }
  
  .reloadPassword-15 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    display: flex;
    align-items: center;
    white-space: nowrap;
    justify-content: center;
    height: 50px;
    font-size: 1rem;
    position: relative;
    z-index: 1;
    text-align: center;
    transition: all 0.2s ease 0s;
    margin-left: 1.0625rem;
    padding: 0px;
    color: rgb(255, 255, 255);
    width: fit-content;
    margin-right: 0.9375rem;
  }
  
  .reloadPassword-15:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPassword-16 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: none;
    font-family: sans-serif;
    white-space: nowrap;
  }
  
  .reloadPassword-16:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPassword-17 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
  }
  
  .reloadPassword-18 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    position: absolute;
    transition: all ease 0.25s;
    transform: translateX(17.765625px);
    bottom: 0px;
    width: 37.328125px;
    height: 0.1875rem;
    background: rgb(240, 193, 99);
    border-radius: 3.125rem;
  }
  
  .reloadPassword-18:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPassword-19 {
    -webkit-tap-highlight-color: transparent;
    display: block;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    position: relative;
    margin: 0;
    border-radius: 0;
  }
  
  .reloadPassword-19:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPassword-20 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
  }
  
  .reloadPassword-20:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPassword-21 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    padding: 0.46875rem 0;
    border-bottom: 1px solid rgb(0, 0, 0);
  }
  
  .reloadPassword-21:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPassword-22 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    flex: 1 1 0%;
  }
  
  .reloadPassword-22:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPassword-23 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    display: flex;
    align-items: center;
  }
  
  .reloadPassword-23:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPassword-24 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    line-height: 1.4em;
    min-height: 1.4em;
    overflow: hidden;
    scrollbar-width: none;
    font-family: sans-serif;
    font-size: 0.875rem;
    height: 2.25rem;
    flex: 1 1 0%;
    background: inherit;
    color: rgb(255, 255, 255);
  }
  
  .reloadPassword-24:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPassword-25 {
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    outline: none;
    border: none;
    padding: 0px;
    margin: 0px;
    text-decoration: inherit;
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
  }
  
  .reloadPassword-26 {
    -webkit-tap-highlight-color: transparent;
    outline: none;
    border: none;
    padding: 0px;
    margin: 0px;
    text-decoration: inherit;
    width: 100%;
    position: absolute;
    left: 0px;
    color: gray;
    overflow: hidden;
    text-overflow: clip;
    white-space: pre;
    word-break: keep-all;
    pointer-events: none;
    line-height: inherit;
    top: auto !important;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
  }
  
  .reloadPassword-26:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPassword-27 {
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: none;
    font-family: sans-serif;
    outline: none;
    border: none;
    padding: 0px;
    margin: 0px;
    text-decoration: inherit;
    width: 100%;
    position: relative;
    display: block;
    height: 100%;
    background: none;
    color: inherit;
    opacity: 1;
    font: inherit;
    letter-spacing: inherit;
    text-align: inherit;
    text-indent: inherit;
    text-transform: inherit;
    text-shadow: inherit;
  }
  
  .reloadPassword-28 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    display: flex;
    align-items: center;
  }
  
  .reloadPassword-28:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPassword-29 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    padding: 0.46875rem 0;
    border-bottom: 1px solid rgb(0, 0, 0);
  }
  
  .reloadPassword-29:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPassword-30 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    flex: 1 1 0%;
  }
  
  .reloadPassword-30:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPassword-31 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    display: flex;
    align-items: center;
  }
  
  .reloadPassword-31:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPassword-32 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    line-height: 1.4em;
    min-height: 1.4em;
    overflow: hidden;
    scrollbar-width: none;
    font-family: sans-serif;
    font-size: 0.875rem;
    height: 2.25rem;
    flex: 1 1 0%;
    background: inherit;
    color: rgb(255, 255, 255);
  }
  
  .reloadPassword-32:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPassword-33 {
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    outline: none;
    border: none;
    padding: 0px;
    margin: 0px;
    text-decoration: inherit;
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
  }
  
  .reloadPassword-34 {
    -webkit-tap-highlight-color: transparent;
    outline: none;
    border: none;
    padding: 0px;
    margin: 0px;
    text-decoration: inherit;
    width: 100%;
    position: absolute;
    left: 0px;
    color: gray;
    overflow: hidden;
    text-overflow: clip;
    white-space: pre;
    word-break: keep-all;
    pointer-events: none;
    line-height: inherit;
    top: auto !important;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
  }
  
  .reloadPassword-34:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPassword-35 {
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: none;
    font-family: sans-serif;
    outline: none;
    border: none;
    padding: 0px;
    margin: 0px;
    text-decoration: inherit;
    width: 100%;
    position: relative;
    display: block;
    height: 100%;
    background: none;
    color: inherit;
    opacity: 1;
    font: inherit;
    letter-spacing: inherit;
    text-align: inherit;
    text-indent: inherit;
    text-transform: inherit;
    text-shadow: inherit;
  }
  
  .reloadPassword-36 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    display: flex;
    align-items: center;
  }
  
  .reloadPassword-36:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPassword-37 {
    margin-left: auto;
    margin-right: auto;
    padding-left: 14px;
    padding-right: 14px;
    text-align: center;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    background-color: rgb(248, 248, 248);
    cursor: pointer;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    overflow: hidden;
    position: relative;
    border: 0px;
    outline: 0px;
    padding: 0px;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
    width: 3.75rem;
    height: 2.1875rem;
    border-radius: 3.125rem;
    font-size: 0.875rem;
    color: rgb(240, 193, 99);
    background: rgb(0, 0, 0);
  }
  
  .reloadPassword-37:after {
    top: 0px;
    left: 0px;
    transform: scale(0.5);
    transform-origin: 0px 0px;
    display: none;
    content: "";
    position: absolute;
    inset: 0px;
    border: 0px;
    pointer-events: none;
    border-color: rgb(30, 39, 48) !important;
  }
  
  @media (prefers-color-scheme: dark) {
    .reloadPassword-37 {
      color: rgb(214, 214, 214);
      background-color: rgb(52, 52, 52);
    }
  }
  .reloadPassword-38 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    padding: 0.46875rem 0;
    border-bottom: 1px solid rgb(0, 0, 0);
  }
  
  .reloadPassword-38:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPassword-39 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    flex: 1 1 0%;
  }
  
  .reloadPassword-39:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPassword-40 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    display: flex;
    align-items: center;
  }
  
  .reloadPassword-40:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPassword-41 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    line-height: 1.4em;
    min-height: 1.4em;
    overflow: hidden;
    scrollbar-width: none;
    font-family: sans-serif;
    font-size: 0.875rem;
    height: 2.25rem;
    flex: 1 1 0%;
    background: inherit;
    color: rgb(255, 255, 255);
  }
  
  .reloadPassword-41:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPassword-42 {
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    outline: none;
    border: none;
    padding: 0px;
    margin: 0px;
    text-decoration: inherit;
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
  }
  
  .reloadPassword-43 {
    -webkit-tap-highlight-color: transparent;
    outline: none;
    border: none;
    padding: 0px;
    margin: 0px;
    text-decoration: inherit;
    width: 100%;
    position: absolute;
    left: 0px;
    color: gray;
    overflow: hidden;
    text-overflow: clip;
    white-space: pre;
    word-break: keep-all;
    pointer-events: none;
    line-height: inherit;
    top: auto !important;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
  }
  
  .reloadPassword-43:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPassword-44 {
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: none;
    font-family: sans-serif;
    outline: none;
    border: none;
    padding: 0px;
    margin: 0px;
    text-decoration: inherit;
    width: 100%;
    position: relative;
    display: block;
    height: 100%;
    background: none;
    color: inherit;
    opacity: 1;
    font: inherit;
    letter-spacing: inherit;
    text-align: inherit;
    text-indent: inherit;
    text-transform: inherit;
    text-shadow: inherit;
  }
  
  .reloadPassword-45 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    display: flex;
    align-items: center;
  }
  
  .reloadPassword-45:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPassword-46 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    padding: 0.46875rem 0;
    border-bottom: 1px solid rgb(0, 0, 0);
  }
  
  .reloadPassword-46:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPassword-47 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    flex: 1 1 0%;
  }
  
  .reloadPassword-47:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPassword-48 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    display: flex;
    align-items: center;
  }
  
  .reloadPassword-48:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPassword-49 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    line-height: 1.4em;
    min-height: 1.4em;
    overflow: hidden;
    scrollbar-width: none;
    font-family: sans-serif;
    font-size: 0.875rem;
    height: 2.25rem;
    flex: 1 1 0%;
    background: inherit;
    color: rgb(255, 255, 255);
  }
  
  .reloadPassword-49:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPassword-50 {
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    outline: none;
    border: none;
    padding: 0px;
    margin: 0px;
    text-decoration: inherit;
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
  }
  
  .reloadPassword-51 {
    -webkit-tap-highlight-color: transparent;
    outline: none;
    border: none;
    padding: 0px;
    margin: 0px;
    text-decoration: inherit;
    width: 100%;
    position: absolute;
    left: 0px;
    color: gray;
    overflow: hidden;
    text-overflow: clip;
    white-space: pre;
    word-break: keep-all;
    pointer-events: none;
    line-height: inherit;
    top: auto !important;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
  }
  
  .reloadPassword-51:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPassword-52 {
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: none;
    font-family: sans-serif;
    outline: none;
    border: none;
    padding: 0px;
    margin: 0px;
    text-decoration: inherit;
    width: 100%;
    position: relative;
    display: block;
    height: 100%;
    background: none;
    color: inherit;
    opacity: 1;
    font: inherit;
    letter-spacing: inherit;
    text-align: inherit;
    text-indent: inherit;
    text-transform: inherit;
    text-shadow: inherit;
  }
  
  .reloadPassword-53 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    display: flex;
    align-items: center;
  }
  
  .reloadPassword-53:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPassword-54 {
    margin-left: auto;
    margin-right: auto;
    padding-left: 14px;
    padding-right: 14px;
    text-align: center;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    background-color: rgb(248, 248, 248);
    cursor: pointer;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    overflow: hidden;
    position: relative;
    border: 0px;
    color: rgb(255, 255, 255);
    outline: 0px;
    padding: 0px;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
    margin-top: 1.875rem;
    width: 100%;
    height: 2.5rem;
    font-size: 1rem;
    background: rgb(240, 193, 99);
    border-radius: 3.125rem;
  }
  
  .reloadPassword-54:after {
    top: 0px;
    left: 0px;
    transform: scale(0.5);
    transform-origin: 0px 0px;
    display: none;
    content: "";
    position: absolute;
    inset: 0px;
    border: 0px;
    pointer-events: none;
    border-color: rgb(30, 39, 48) !important;
  }
  
  @media (prefers-color-scheme: dark) {
    .reloadPassword-54 {
      color: rgb(214, 214, 214);
      background-color: rgb(52, 52, 52);
    }
  }
  

  .reloadPasswordPhone-1 {
    -webkit-tap-highlight-color: transparent;
    display: block;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    position: relative;
    margin: 0;
    border-radius: 0;
  }
  
  .reloadPasswordPhone-1:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPasswordPhone-2 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
  }
  
  .reloadPasswordPhone-2:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPasswordPhone-3 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    padding: 0.46875rem 0;
    border-bottom: 1px solid rgb(0, 0, 0);
  }
  
  .reloadPasswordPhone-3:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPasswordPhone-4 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    flex: 1 1 0%;
  }
  
  .reloadPasswordPhone-4:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPasswordPhone-5 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    display: flex;
    align-items: center;
    margin-right: 0.625rem;
  }
  
  .reloadPasswordPhone-5:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPasswordPhone-6 {
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    margin: undefined;
    color: hsla(0, 0%, 100%, 0.6);
  }
  
  .reloadPasswordPhone-6:active {
    opacity: 0.7;
  }
  
  .reloadPasswordPhone-6:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPasswordPhone-7 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    white-space: pre-line;
    scrollbar-width: none;
    font-family: sans-serif;
  }
  
  .reloadPasswordPhone-7:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPasswordPhone-8 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
  }
  
  .reloadPasswordPhone-9 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smoothing: antialiased;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: iconfont !important;
    font-style: normal;
    font-size: 0.875rem;
    margin-left: 0.25rem;
  }
  
  .reloadPasswordPhone-9:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPasswordPhone-9:before {
    content: "";
  }
  
  .reloadPasswordPhone-10 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    line-height: 1.4em;
    min-height: 1.4em;
    overflow: hidden;
    scrollbar-width: none;
    font-family: sans-serif;
    font-size: 0.875rem;
    height: 2.25rem;
    flex: 1 1 0%;
    background: inherit;
    color: rgb(255, 255, 255);
  }
  
  .reloadPasswordPhone-10:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPasswordPhone-11 {
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    outline: none;
    border: none;
    padding: 0px;
    margin: 0px;
    text-decoration: inherit;
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
  }
  
  .reloadPasswordPhone-12 {
    -webkit-tap-highlight-color: transparent;
    outline: none;
    border: none;
    padding: 0px;
    margin: 0px;
    text-decoration: inherit;
    width: 100%;
    position: absolute;
    left: 0px;
    color: gray;
    overflow: hidden;
    text-overflow: clip;
    white-space: pre;
    word-break: keep-all;
    pointer-events: none;
    line-height: inherit;
    top: auto !important;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
  }
  
  .reloadPasswordPhone-12:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPasswordPhone-13 {
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: none;
    font-family: sans-serif;
    outline: none;
    border: none;
    padding: 0px;
    margin: 0px;
    text-decoration: inherit;
    width: 100%;
    position: relative;
    display: block;
    height: 100%;
    background: none;
    color: inherit;
    opacity: 1;
    font: inherit;
    letter-spacing: inherit;
    text-align: inherit;
    text-indent: inherit;
    text-transform: inherit;
    text-shadow: inherit;
  }
  
  .reloadPasswordPhone-14 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    display: flex;
    align-items: center;
  }
  
  .reloadPasswordPhone-14:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPasswordPhone-15 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    padding: 0.46875rem 0;
    border-bottom: 1px solid rgb(0, 0, 0);
  }
  
  .reloadPasswordPhone-15:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPasswordPhone-16 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    flex: 1 1 0%;
  }
  
  .reloadPasswordPhone-16:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPasswordPhone-17 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    display: flex;
    align-items: center;
  }
  
  .reloadPasswordPhone-17:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPasswordPhone-18 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    line-height: 1.4em;
    min-height: 1.4em;
    overflow: hidden;
    scrollbar-width: none;
    font-family: sans-serif;
    font-size: 0.875rem;
    height: 2.25rem;
    flex: 1 1 0%;
    background: inherit;
    color: rgb(255, 255, 255);
  }
  
  .reloadPasswordPhone-18:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPasswordPhone-19 {
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    outline: none;
    border: none;
    padding: 0px;
    margin: 0px;
    text-decoration: inherit;
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
  }
  
  .reloadPasswordPhone-20 {
    -webkit-tap-highlight-color: transparent;
    outline: none;
    border: none;
    padding: 0px;
    margin: 0px;
    text-decoration: inherit;
    width: 100%;
    position: absolute;
    left: 0px;
    color: gray;
    overflow: hidden;
    text-overflow: clip;
    white-space: pre;
    word-break: keep-all;
    pointer-events: none;
    line-height: inherit;
    top: auto !important;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
  }
  
  .reloadPasswordPhone-20:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPasswordPhone-21 {
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: none;
    font-family: sans-serif;
    outline: none;
    border: none;
    padding: 0px;
    margin: 0px;
    text-decoration: inherit;
    width: 100%;
    position: relative;
    display: block;
    height: 100%;
    background: none;
    color: inherit;
    opacity: 1;
    font: inherit;
    letter-spacing: inherit;
    text-align: inherit;
    text-indent: inherit;
    text-transform: inherit;
    text-shadow: inherit;
  }
  
  .reloadPasswordPhone-22 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    display: flex;
    align-items: center;
  }
  
  .reloadPasswordPhone-22:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPasswordPhone-23 {
    margin-left: auto;
    margin-right: auto;
    padding-left: 14px;
    padding-right: 14px;
    text-align: center;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    background-color: rgb(248, 248, 248);
    cursor: pointer;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    overflow: hidden;
    position: relative;
    border: 0px;
    outline: 0px;
    padding: 0px;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
    width: 3.75rem;
    height: 2.1875rem;
    border-radius: 3.125rem;
    font-size: 0.875rem;
    color: rgb(240, 193, 99);
    background: rgb(0, 0, 0);
  }
  
  .reloadPasswordPhone-23:after {
    top: 0px;
    left: 0px;
    transform: scale(0.5);
    transform-origin: 0px 0px;
    display: none;
    content: "";
    position: absolute;
    inset: 0px;
    border: 0px;
    pointer-events: none;
    border-color: rgb(30, 39, 48) !important;
  }
  
  @media (prefers-color-scheme: dark) {
    .reloadPasswordPhone-23 {
      color: rgb(214, 214, 214);
      background-color: rgb(52, 52, 52);
    }
  }
  .reloadPasswordPhone-24 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    padding: 0.46875rem 0;
    border-bottom: 1px solid rgb(0, 0, 0);
  }
  
  .reloadPasswordPhone-24:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPasswordPhone-25 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    flex: 1 1 0%;
  }
  
  .reloadPasswordPhone-25:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPasswordPhone-26 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    display: flex;
    align-items: center;
  }
  
  .reloadPasswordPhone-26:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPasswordPhone-27 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    line-height: 1.4em;
    min-height: 1.4em;
    overflow: hidden;
    scrollbar-width: none;
    font-family: sans-serif;
    font-size: 0.875rem;
    height: 2.25rem;
    flex: 1 1 0%;
    background: inherit;
    color: rgb(255, 255, 255);
  }
  
  .reloadPasswordPhone-27:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPasswordPhone-28 {
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    outline: none;
    border: none;
    padding: 0px;
    margin: 0px;
    text-decoration: inherit;
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
  }
  
  .reloadPasswordPhone-29 {
    -webkit-tap-highlight-color: transparent;
    outline: none;
    border: none;
    padding: 0px;
    margin: 0px;
    text-decoration: inherit;
    width: 100%;
    position: absolute;
    left: 0px;
    color: gray;
    overflow: hidden;
    text-overflow: clip;
    white-space: pre;
    word-break: keep-all;
    pointer-events: none;
    line-height: inherit;
    top: auto !important;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
  }
  
  .reloadPasswordPhone-29:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPasswordPhone-30 {
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: none;
    font-family: sans-serif;
    outline: none;
    border: none;
    padding: 0px;
    margin: 0px;
    text-decoration: inherit;
    width: 100%;
    position: relative;
    display: block;
    height: 100%;
    background: none;
    color: inherit;
    opacity: 1;
    font: inherit;
    letter-spacing: inherit;
    text-align: inherit;
    text-indent: inherit;
    text-transform: inherit;
    text-shadow: inherit;
  }
  
  .reloadPasswordPhone-31 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    display: flex;
    align-items: center;
  }
  
  .reloadPasswordPhone-31:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPasswordPhone-32 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    padding: 0.46875rem 0;
    border-bottom: 1px solid rgb(0, 0, 0);
  }
  
  .reloadPasswordPhone-32:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPasswordPhone-33 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    flex: 1 1 0%;
  }
  
  .reloadPasswordPhone-33:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPasswordPhone-34 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    display: flex;
    align-items: center;
  }
  
  .reloadPasswordPhone-34:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPasswordPhone-35 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    line-height: 1.4em;
    min-height: 1.4em;
    overflow: hidden;
    scrollbar-width: none;
    font-family: sans-serif;
    font-size: 0.875rem;
    height: 2.25rem;
    flex: 1 1 0%;
    background: inherit;
    color: rgb(255, 255, 255);
  }
  
  .reloadPasswordPhone-35:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPasswordPhone-36 {
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    outline: none;
    border: none;
    padding: 0px;
    margin: 0px;
    text-decoration: inherit;
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
  }
  
  .reloadPasswordPhone-37 {
    -webkit-tap-highlight-color: transparent;
    outline: none;
    border: none;
    padding: 0px;
    margin: 0px;
    text-decoration: inherit;
    width: 100%;
    position: absolute;
    left: 0px;
    color: gray;
    overflow: hidden;
    text-overflow: clip;
    white-space: pre;
    word-break: keep-all;
    pointer-events: none;
    line-height: inherit;
    top: auto !important;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
  }
  
  .reloadPasswordPhone-37:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .reloadPasswordPhone-38 {
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: none;
    font-family: sans-serif;
    outline: none;
    border: none;
    padding: 0px;
    margin: 0px;
    text-decoration: inherit;
    width: 100%;
    position: relative;
    display: block;
    height: 100%;
    background: none;
    color: inherit;
    opacity: 1;
    font: inherit;
    letter-spacing: inherit;
    text-align: inherit;
    text-indent: inherit;
    text-transform: inherit;
    text-shadow: inherit;
  }
  
  .reloadPasswordPhone-39 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    display: flex;
    align-items: center;
  }
  
  .reloadPasswordPhone-39:after {
    border-color: rgb(30, 39, 48) !important;
  }
  