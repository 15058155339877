.infocard-1 {
  padding: 0px 3.2%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow-y: auto;
}

.infocard-2 {
  box-sizing: border-box;
  margin-bottom: 4%;
  padding: 4% 2.66667% 4% 3.2%;
  width: 44%;
  min-height: 28.8%;
  border-radius: 1.33333%;
  /* background: #fff; */
  border: solid 1px #999;
}

.infocard-3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.infocard-4 {
  width: 8%;
  height: 8%;
}

.infocard-5 {
  width: 5.33333%;
  height: 5.33333%;
}

.infocard-6 {
  margin-top: 4%;
  text-align: left;
  /* color: #f0b90b; */
  color: var(--boutton-background-color);
}

.infocard-7 {
  margin-top: 2.13333%;
  text-align: left;
  font-size: 3.2%;
  color: #858a8f;
}

.infocard-9 {
  box-sizing: border-box;
  margin-bottom: 4%;
  padding: 4% 2.66667% 4% 3.2%;
  width: 44%;
  min-height: 28.8%;
  border-radius: 1.33333%;
  /* background: #fff; */
  border: solid 1px #999;
}

.infocard-10 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.infocard-11 {
  width: 8%;
  height: 8%;
}

.infocard-12 {
  width: 5.33333%;
  height: 5.33333%;
}

.infocard-13 {
  margin-top: 4%;
  text-align: left;
  /* color: #f0b90b; */
  color: var(--boutton-background-color);
}

.infocard-14 {
  margin-top: 2.13333%;
  text-align: left;
  font-size: 3.2%;
  color: #858a8f;
}

.infocard-16 {
  box-sizing: border-box;
  margin-bottom: 4%;
  padding: 4% 2.66667% 4% 3.2%;
  width: 44%;
  min-height: 28.8%;
  border-radius: 1.33333%;
  /* background: #fff; */
  border: solid 1px #999;
}

.infocard-17 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.infocard-18 {
  width: 8%;
  height: 8%;
}

.infocard-19 {
  width: 5.33333%;
  height: 5.33333%;
}

.infocard-20 {
  margin-top: 4%;
  text-align: left;
  /* color: #f0b90b; */
  color: var(--boutton-background-color);
}

.infocard-21 {
  margin-top: 2.13333%;
  text-align: left;
  font-size: 3.2%;
  color: #858a8f;
}

.infocard-22 {
  box-sizing: border-box;
  margin-bottom: 4%;
  padding: 4% 2.66667% 4% 3.2%;
  width: 44%;
  min-height: 28.8%;
  border-radius: 1.33333%;
  /* background: #fff; */
  border: solid 1px #999;
}

.infocard-23 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.infocard-24 {
  width: 8%;
  height: 8%;
}

.infocard-25 {
  margin-top: 4%;
  text-align: left;
  /* color: #f0b90b; */
  color: var(--boutton-background-color);
}

.infocard-26 {
  margin-top: 2.13333%;
  text-align: left;
  font-size: 3.2%;
  color: #858a8f;
}