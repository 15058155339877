.leverRoleInfo-1 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    transition: all 0.25s ease 0s;
    padding: 0;
    pointer-events: initial;
    opacity: 1;
  }
  
  .leverRoleInfo-1:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .leverRoleInfo-2 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    padding: 0.9375rem 0.9375rem 0.03125rem;
  }
  
  .leverRoleInfo-2:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .leverRoleInfo-3 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    font-size: 0.9375rem;
    margin-bottom: 1.25rem;
    font-weight: 700;
    color: rgb(255, 255, 255);
  }
  
  .leverRoleInfo-3:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .leverRoleInfo-4 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    font-size: 0.875rem;
    font-weight: 700;
    margin-bottom: 0.625rem;
    color: rgb(255, 255, 255);
  }
  
  .leverRoleInfo-4:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .leverRoleInfo-5 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    font-size: 0.875rem;
    margin-bottom: 1.25rem;
    color: rgba(255, 255, 255, 0.6);
  }
  
  .leverRoleInfo-5:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .leverRoleInfo-6 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    font-size: 0.875rem;
    font-weight: 700;
    margin-bottom: 0.625rem;
    color: rgb(255, 255, 255);
  }
  
  .leverRoleInfo-6:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .leverRoleInfo-7 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    font-size: 0.875rem;
    margin-bottom: 1.25rem;
    color: rgba(255, 255, 255, 0.6);
  }
  
  .leverRoleInfo-7:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .leverRoleInfo-8 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    font-size: 0.875rem;
    font-weight: 700;
    margin-bottom: 0.625rem;
    color: rgb(255, 255, 255);
  }
  
  .leverRoleInfo-8:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .leverRoleInfo-9 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    font-size: 0.875rem;
    margin-bottom: 1.25rem;
    color: rgba(255, 255, 255, 0.6);
  }
  
  .leverRoleInfo-9:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .leverRoleInfo-10 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    font-size: 0.875rem;
    font-weight: 700;
    margin-bottom: 0.625rem;
    color: rgb(255, 255, 255);
  }
  
  .leverRoleInfo-10:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .leverRoleInfo-11 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    font-size: 0.875rem;
    margin-bottom: 1.25rem;
    color: rgba(255, 255, 255, 0.6);
  }
  
  .leverRoleInfo-11:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .leverRoleInfo-12 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    font-size: 0.875rem;
    font-weight: 700;
    margin-bottom: 0.625rem;
    color: rgb(255, 255, 255);
  }
  
  .leverRoleInfo-12:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .leverRoleInfo-13 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    font-size: 0.875rem;
    margin-bottom: 1.25rem;
    color: rgba(255, 255, 255, 0.6);
  }
  
  .leverRoleInfo-13:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .leverRoleInfo-14 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    font-size: 0.875rem;
    font-weight: 700;
    margin-bottom: 0.625rem;
    color: rgb(255, 255, 255);
  }
  
  .leverRoleInfo-14:after {
    border-color: rgb(30, 39, 48) !important;
  }
  
  .leverRoleInfo-15 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    scrollbar-width: none;
    box-sizing: border-box;
    font-family: sans-serif;
    font-size: 0.875rem;
    margin-bottom: 1.25rem;
    color: rgba(255, 255, 255, 0.6);
  }
  
  .leverRoleInfo-15:after {
    border-color: rgb(30, 39, 48) !important;
  }
  