.noiceInfo-1 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  box-sizing: border-box;
  width: 100%;
  min-height: 100%;
  color: rgb(48, 49, 51);
  font-size: 14px;
}

.noiceInfo-2 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  box-sizing: border-box;
  min-height: 100%;
  padding: 20px 15px;
}

.noiceInfo-2:before {
  content: "";
  left: 0px;
  right: 0px;
  height: 1px;
  transform: scaleY(0.5);
  background-color: rgb(200, 199, 204);
  position: fixed;
  top: 44px;
  z-index: 3001;
}

.noiceInfo-3 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  padding: 0px 0px 10px;
  border-bottom: 0.5px solid rgb(200, 199, 204);
}

.noiceInfo-4 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 5px;
  color: rgb(51, 51, 51);
}

.noiceInfo-5 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  padding: 10px 0px;
}

.noiceInfo-6 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
}

.noiceInfo-7 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
}

.noiceInfo-8 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.noiceInfo-9 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.noiceInfo-10 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  max-width: 100%;
}
