.zixunlist-over-x {
  width: 100vw;
  overflow-x: auto;
  scrollbar-width: none;
}
.zixunlist-1 {
  overflow-y: hidden;
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  gap: .75rem;
  width: 100%;
  overflow-x: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

.zixunlist-1:after {
  border-color: rgb(30, 39, 48) !important;
}

.zixunlist-2 {
  margin: 0px;
  border-radius: .88rem;
  -webkit-tap-highlight-color: transparent;
  display: block;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  /* width: 8.13rem; */
  margin-bottom: -0.3125rem;
  background: var(--them-color-white-bg);
  position: relative;
}

.zixunlist-2:after {
  border-color: rgb(30, 39, 48) !important;
}

.zixunlist-3 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--them-color-black-text);
}

.zixunlist-3:after {
  border-color: rgb(30, 39, 48) !important;
}

.zixunlist-4 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  white-space: pre-line;
  scrollbar-width: none;
  font-family: sans-serif;
  font-size: 0.625rem;
}

.zixunlist-4:after {
  border-color: rgb(30, 39, 48) !important;
}

.zixunlist-5 {
  margin-top: .3rem ;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  justify-content: center;
  font-size: 0.75rem;
  font-weight: 500;
}

.zixunlist-6 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  font-style: normal;
  font-size: 0.625rem;
  line-height: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  color: var(--them-color-red-text);
  padding: 0.125rem 0.1875rem;
  border-radius: 0.125rem;
  margin-left: 0.3125rem;
  position: absolute;
  bottom: .5rem;
}

.zixunlist-6:after {
  border-color: rgb(30, 39, 48) !important;
}

.zixunlist-7 {
  margin-top: .25rem;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  font-weight: 500;
  font-size: 1.13rem;
  display: block;
  text-align: center;
  color: rgb(243, 100, 100);
}

.zixunlist-7:after {
  border-color: rgb(30, 39, 48) !important;
}

.zixunlist-8 {
  box-sizing: border-box;
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  height: 1.5rem;
  margin-bottom: 1.5rem;
}

.zixunlist-8:after {
  border-color: rgb(30, 39, 48) !important;
}

.zixunlist-9 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1 1 0%;
  justify-content: center;
  align-items: center;
}

.zixunlist-9:after {
  border-color: rgb(30, 39, 48) !important;
}

.zixunlist-10 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  display: block;
  box-sizing: border-box;
  font-family: sans-serif;
}

.zixunlist-11 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  display: block;
  font-family: sans-serif;
  width: 115.938px;
  height: 49.6719px;
  background: none;
  position: relative;
}

.zixunlist-12 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  font-family: sans-serif;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.zixunlist-13 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.zixunlist-14 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  position: absolute;
  inset: 0px;
  overflow: hidden;
  display: block;
  z-index: -1;
  visibility: hidden;
  animation: 1ms ease 0s 1 normal none running once-show;
  font-family: sans-serif;
}

.zixunlist-15 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  position: absolute;
  inset: 0px;
  overflow: hidden;
}

.zixunlist-16 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100000px;
  height: 100000px;
}

.zixunlist-17 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  position: absolute;
  inset: 0px;
  overflow: hidden;
}

.zixunlist-18 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 200%;
  height: 200%;
}

.zixunlist-19 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  width: 33.3333vw;
  padding: 0.9375rem 0.625rem 0px;
  margin-bottom: -0.3125rem;
}

.zixunlist-19:after {
  border-color: rgb(30, 39, 48) !important;
}

.zixunlist-20 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.625rem;
  color: rgb(255, 255, 255);
}

.zixunlist-20:after {
  border-color: rgb(30, 39, 48) !important;
}

.zixunlist-21 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  white-space: pre-line;
  scrollbar-width: none;
  font-family: sans-serif;
  font-size: 0.625rem;
}

.zixunlist-21:after {
  border-color: rgb(30, 39, 48) !important;
}

.zixunlist-22 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}

.zixunlist-23 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  font-style: normal;
  font-size: 0.625rem;
  line-height: 1;
  display: block;
  color: rgb(255, 255, 255);
  padding: 0.125rem 0.1875rem;
  border-radius: 0.125rem;
  margin-left: 0.3125rem;
  background: rgb(243, 100, 100);
}

.zixunlist-23:after {
  border-color: rgb(30, 39, 48) !important;
}

.zixunlist-24 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  font-size: 0.8125rem;
  font-weight: 700;
  display: block;
  text-align: center;
  color: rgb(243, 100, 100);
}

.zixunlist-24:after {
  border-color: rgb(30, 39, 48) !important;
}

.zixunlist-25 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  height: 4.8125rem;
}

.zixunlist-25:after {
  border-color: rgb(30, 39, 48) !important;
}

.zixunlist-26 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1 1 0%;
  justify-content: center;
  align-items: center;
}

.zixunlist-26:after {
  border-color: rgb(30, 39, 48) !important;
}

.zixunlist-27 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  display: block;
  box-sizing: border-box;
  font-family: sans-serif;
}

.zixunlist-28 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  display: block;
  font-family: sans-serif;
  width: 115.938px;
  height: 49.6719px;
  background: none;
  position: relative;
}

.zixunlist-29 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  font-family: sans-serif;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.zixunlist-30 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.zixunlist-31 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  position: absolute;
  inset: 0px;
  overflow: hidden;
  display: block;
  z-index: -1;
  visibility: hidden;
  animation: 1ms ease 0s 1 normal none running once-show;
  font-family: sans-serif;
}

.zixunlist-32 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  position: absolute;
  inset: 0px;
  overflow: hidden;
}

.zixunlist-33 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100000px;
  height: 100000px;
}

.zixunlist-34 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  position: absolute;
  inset: 0px;
  overflow: hidden;
}

.zixunlist-35 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 200%;
  height: 200%;
}

.zixunlist-36 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  width: 33.3333vw;
  padding: 0.9375rem 0.625rem 0px;
  margin-bottom: -0.3125rem;
}

.zixunlist-36:after {
  border-color: rgb(30, 39, 48) !important;
}

.zixunlist-37 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.625rem;
  color: rgb(255, 255, 255);
}

.zixunlist-37:after {
  border-color: rgb(30, 39, 48) !important;
}

.zixunlist-38 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  white-space: pre-line;
  scrollbar-width: none;
  font-family: sans-serif;
  font-size: 0.625rem;
}

.zixunlist-38:after {
  border-color: rgb(30, 39, 48) !important;
}

.zixunlist-39 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}

.zixunlist-40 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  font-style: normal;
  font-size: 0.625rem;
  line-height: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  color: var(--them-color-green-text);
  padding: 0.125rem 0.1875rem;
  border-radius: 0.125rem;
  margin-left: 0.3125rem;
  position: absolute;
  bottom: .5rem;
}

.zixunlist-40:after {
  border-color: rgb(30, 39, 48) !important;
}

.zixunlist-41 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  font-size: 1rem; 
  font-weight: 500;
  display: block;
  text-align: center;
  color: rgb(64, 145, 151);
}

.zixunlist-41:after {
  border-color: rgb(30, 39, 48) !important;
}

.zixunlist-42 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  height: 4.8125rem;
}

.zixunlist-42:after {
  border-color: rgb(30, 39, 48) !important;
}

.zixunlist-43 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1 1 0%;
  justify-content: center;
  align-items: center;
}

.zixunlist-43:after {
  border-color: rgb(30, 39, 48) !important;
}

.zixunlist-44 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  display: block;
  box-sizing: border-box;
  font-family: sans-serif;
}

.zixunlist-45 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  display: block;
  font-family: sans-serif;
  width: 115.938px;
  height: 49.6719px;
  background: none;
  position: relative;
}

.zixunlist-46 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  font-family: sans-serif;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.zixunlist-47 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.zixunlist-48 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  position: absolute;
  inset: 0px;
  overflow: hidden;
  display: block;
  z-index: -1;
  visibility: hidden;
  animation: 1ms ease 0s 1 normal none running once-show;
  font-family: sans-serif;
}

.zixunlist-49 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  position: absolute;
  inset: 0px;
  overflow: hidden;
}

.zixunlist-50 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100000px;
  height: 100000px;
}

.zixunlist-51 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  position: absolute;
  inset: 0px;
  overflow: hidden;
}

.zixunlist-52 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 200%;
  height: 200%;
}