.changePassword-1 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 0.93333rem;
  vertical-align: baseline;
  padding-top: 10px;
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100vh;
}

.changePassword-2:focus {
  outline: 0px;
  margin-top: 20px;
}

.changePassword-2:after {
  display: table;
  clear: both;
  content: "";
  margin-top: 20px;
}

.changePassword-2 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  margin-top: 20px;
}

.changePassword-3:focus {
  outline: 0px;
}

.changePassword-3:after {
  /* position: absolute;
  box-sizing: border-box;
  content: " ";
  pointer-events: none;
  inset: -50%;
  border: 0px solid rgb(235, 237, 240);
  transform: scale(0.5);
  border-width: 1px 0px; */
}

.changePassword-3 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  position: relative;
}

.changePassword-4:focus {
  outline: 0px;
}

.changePassword-4 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 20px;
  border: 0px;
  outline: 0px;
  font-size: 1rem;
  vertical-align: baseline;
  background: transparent;
  position: relative;
  display: flex;
  width: 100%;
  overflow: hidden;
  color: #fff;
  line-height: 24px;
}

.changePassword-4:after {
  position: absolute;
  box-sizing: border-box;
  content: " ";
  pointer-events: none;
  right: 16px;
  bottom: 0px;
  left: 16px;
  border-bottom: 1px solid rgb(235, 237, 240);
  transform: scaleY(0.5);
}

.changePassword-5:focus {
  outline: 0px;
}

.changePassword-5 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  margin-right: 4px;
}

.changePassword-6 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 18px;
  vertical-align: baseline;
  background: transparent;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  font-family: iconfont !important;
  color: var(--boutton-background-color);
}

.changePassword-7:focus {
  outline: 0px;
}

.changePassword-7 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: middle;
  background: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 0%;
  position: relative;
  overflow: visible;
  color: #fff;
  text-align: left;
  overflow-wrap: break-word;
}

.changePassword-8:focus {
  outline: 0px;
}

.changePassword-8 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.changePassword-9 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  color: #fff;
  font: inherit;
  margin: 0px;
  overflow: visible;
  text-transform: none;
  vertical-align: middle;
  display: block;
  width: 100%;
  min-width: 0px;
  padding: 0px;
  line-height: inherit;
  text-align: left;
  background-color: transparent;
  border: 0px;
  resize: none;
}

.changePassword-9:focus {
  outline: 0px;
}

.changePassword-9:placeholder {
  color: rgb(150, 151, 153);
}

.changePassword-9:disabled {
  color: rgb(200, 201, 204);
  cursor: not-allowed;
  opacity: 1;
  -webkit-text-fill-color: rgb(200, 201, 204);
}

.changePassword-9:read-only {
  cursor: default;
}

.changePassword-10:focus {
  outline: 0px;
}

.changePassword-10 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px 8px;
  border: 0px;
  outline: 0px;
  font-size: 1.2rem;
  vertical-align: baseline;
  background: transparent;
  flex-shrink: 0;
  margin-right: -8px;
  line-height: inherit;
  color: var(--boutton-background-color);
}

.changePassword-11:focus {
  outline: 0px;
}

.changePassword-11 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 1.2rem;
  vertical-align: baseline;
  background: transparent;
  position: relative;
  font-style: normal;
  font-variant: normal;
  font-kerning: auto;
  font-optical-sizing: auto;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: inherit;
  font-family: vant-icon;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  display: block;
  color: var(--boutton-background-color);
}

.changePassword-11:before {
  display: inline-block;
  content: "";
}

.changePassword-12:focus {
  outline: 0px;
}

.changePassword-12 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 20px;
  border: 0px;
  outline: 0px;
  font-size: 1rem;
  vertical-align: baseline;
  background: transparent;
  position: relative;
  display: flex;
  width: 100%;
  overflow: hidden;
  color: #fff;
  line-height: 24px;
}

.changePassword-12:after {
  position: absolute;
  box-sizing: border-box;
  content: " ";
  pointer-events: none;
  right: 16px;
  bottom: 0px;
  left: 16px;
  border-bottom: 1px solid rgb(235, 237, 240);
  transform: scaleY(0.5);
}

.changePassword-13:focus {
  outline: 0px;
}

.changePassword-13 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  margin-right: 4px;
}

.changePassword-14 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 18px;
  vertical-align: baseline;
  background: transparent;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  font-family: iconfont !important;
  color: var(--boutton-background-color);
}

.changePassword-15:focus {
  outline: 0px;
}

.changePassword-15 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: middle;
  background: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 0%;
  position: relative;
  overflow: visible;
  color: #fff;
  text-align: left;
  overflow-wrap: break-word;
}

.changePassword-16:focus {
  outline: 0px;
}

.changePassword-16 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.changePassword-17 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  color: #fff;
  font: inherit;
  margin: 0px;
  overflow: visible;
  text-transform: none;
  vertical-align: middle;
  display: block;
  width: 100%;
  min-width: 0px;
  padding: 0px;
  line-height: inherit;
  text-align: left;
  background-color: transparent;
  border: 0px;
  resize: none;
}

.changePassword-17:focus {
  outline: 0px;
}

.changePassword-17:placeholder {
  color: rgb(150, 151, 153);
}

.changePassword-17:disabled {
  color: rgb(200, 201, 204);
  cursor: not-allowed;
  opacity: 1;
  -webkit-text-fill-color: rgb(200, 201, 204);
}

.changePassword-17:read-only {
  cursor: default;
}

.changePassword-18:focus {
  outline: 0px;
}

.changePassword-18 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px 8px;
  border: 0px;
  outline: 0px;
  font-size: 1.2rem;
  vertical-align: baseline;
  background: transparent;
  flex-shrink: 0;
  margin-right: -8px;
  line-height: inherit;
  color: var(--boutton-background-color);
}

.changePassword-19:focus {
  outline: 0px;
}

.changePassword-19 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 1.2rem;
  vertical-align: baseline;
  background: transparent;
  position: relative;
  font-style: normal;
  font-variant: normal;
  font-kerning: auto;
  font-optical-sizing: auto;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: inherit;
  font-family: vant-icon;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  display: block;
  color: var(--boutton-background-color);
}

.changePassword-19:before {
  display: inline-block;
  content: "";
}

.changePassword-20:focus {
  outline: 0px;
}

.changePassword-20 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 20px;
  border: 0px;
  outline: 0px;
  font-size: 1rem;
  vertical-align: baseline;
  background: transparent;
  position: relative;
  display: flex;
  width: 100%;
  overflow: hidden;
  color: #fff;
  line-height: 24px;
}

.changePassword-20:after {
  position: absolute;
  box-sizing: border-box;
  content: " ";
  pointer-events: none;
  right: 16px;
  bottom: 0px;
  left: 16px;
  border-bottom: 1px solid rgb(235, 237, 240);
  transform: scaleY(0.5);
  display: none;
}

.changePassword-21:focus {
  outline: 0px;
}

.changePassword-21 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  margin-right: 4px;
}

.changePassword-22 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 18px;
  vertical-align: baseline;
  background: transparent;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  font-family: iconfont !important;
  color: var(--boutton-background-color);
}

.changePassword-23:focus {
  outline: 0px;
}

.changePassword-23 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: middle;
  background: transparent;
  -webkit-box-flex: 1;
  flex: 1 1 0%;
  position: relative;
  overflow: visible;
  color: #fff;
  text-align: left;
  overflow-wrap: break-word;
}

.changePassword-22:after {
  position: absolute;
  box-sizing: border-box;
  content: " ";
  pointer-events: none;
  right: 16px;
  bottom: 0px;
  left: 16px;
  border-bottom: 1px solid rgb(235, 237, 240);
  transform: scaleY(0.5);
}
.changePassword-24:focus {
  outline: 0px;
}

.changePassword-24 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.changePassword-25 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  color: #fff;
  font: inherit;
  margin: 0px;
  overflow: visible;
  text-transform: none;
  vertical-align: middle;
  display: block;
  width: 100%;
  min-width: 0px;
  padding: 0px;
  line-height: inherit;
  text-align: left;
  background-color: transparent;
  border: 0px;
  resize: none;
}

.changePassword-25:focus {
  outline: 0px;
}

.changePassword-25:placeholder {
  color: rgb(150, 151, 153);
}

.changePassword-25:disabled {
  color: rgb(200, 201, 204);
  cursor: not-allowed;
  opacity: 1;
  -webkit-text-fill-color: rgb(200, 201, 204);
}

.changePassword-25:read-only {
  cursor: default;
}

.changePassword-26:focus {
  outline: 0px;
}

.changePassword-26 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px 8px;
  border: 0px;
  outline: 0px;
  font-size: 1.2rem;
  vertical-align: baseline;
  background: transparent;
  flex-shrink: 0;
  margin-right: -8px;
  line-height: inherit;
  color: var(--boutton-background-color);
}

.changePassword-27:focus {
  outline: 0px;
}

.changePassword-27 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 1.2rem;
  vertical-align: baseline;
  background: transparent;
  position: relative;
  font-style: normal;
  font-variant: normal;
  font-kerning: auto;
  font-optical-sizing: auto;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: inherit;
  font-family: vant-icon;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  display: block;
  color: var(--boutton-background-color);
}

.changePassword-27:before {
  display: inline-block;
  content: "";
}

.changePassword-28 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  color: rgb(255, 255, 255);
  font: inherit;
  margin: 0px;
  overflow: visible;
  text-transform: none;
  position: relative;
  display: inline-block;
  height: 4rem;
  padding: 5px;
  font-size: 0.93333rem;
  line-height: 1.2;
  text-align: center;
  border-radius: 0.53333rem;
  cursor: pointer;
  transition: opacity 0.2s ease 0s;
  appearance: none;
  background-color: var(--boutton-background-color);
  border: none;
  margin-top: 10%;
  letter-spacing: 0.53333rem;
  box-shadow: var(--boutton-background-color) 0px 0px 1.33333rem;
  margin-left: 2rem;
  margin-right: 2rem;
}

.changePassword-28:focus {
  outline: 0px;
}

.changePassword-28:before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  border-style: inherit;
  border-width: inherit;
  border-image: inherit;
  border-color: rgb(0, 0, 0);
  border-radius: inherit;
  transform: translate(-50%, -50%);
  opacity: 0;
  content: " ";
}

.changePassword-29:focus {
  outline: 0px;
}

.changePassword-29 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 100%;
}

.changePassword-29:before {
  content: " ";
}

.changePassword-30:focus {
  outline: 0px;
}

.changePassword-30 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}
