/* Custom scrollbar styles */
.custom-scrollbar::-webkit-scrollbar {
  width: 10px; /* Increased width for better visibility */
  height: 10px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.3); /* Increased opacity for better visibility */
  border-radius: 4px;
  margin: 0; /* Removed margin to ensure track is visible */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.4); /* Increased opacity for better visibility */
  border-radius: 4px;
  border: 1px solid transparent; /* Reduced border width */
  background-clip: padding-box;
  transition: all 0.2s ease;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.7);
}

.custom-scrollbar::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, 0.8);
}

/* For Firefox */
.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.4) rgba(0, 0, 0, 0.1); /* Increased opacity */
}
