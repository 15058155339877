
                *,::before,::after {
                    box-sizing: border-box;
                    background-repeat: no-repeat;
                }

                ::before,::after {
                    -webkit-text-decoration: inherit;
                    text-decoration: inherit;
                    vertical-align: inherit;
                }

                :where(:root) {
                    cursor: default;
                    line-height: 1.5;
                    overflow-wrap: break-word;
                    -moz-tab-size: 4;
                    tab-size: 4;
                    -webkit-tap-highlight-color: transparent;
                    -webkit-text-size-adjust: 100%;
                    -webkit-text-size-adjust: 100%;
                    -moz-text-size-adjust: 100%;
                    -ms-text-size-adjust: 100%;
                    text-size-adjust: 100%;
                }

                :where(body) {
                    margin: 0;
                }

                :where(h1) {
                    font-size: 2em;
                    margin: 0.67em 0;
                }

                :where(dl, ol, ul) :where(dl, ol, ul) {
                    margin: 0;
                }

                :where(hr) {
                    color: inherit;
                    height: 0;
                }

                :where(nav) :where(ol, ul) {
                    list-style-type: none;
                    padding: 0;
                }

                :where(nav li)::before {
                    content: "​";
                    float: left;
                }

                :where(pre) {
                    font-family: monospace,monospace;
                    font-size: 1em;
                    overflow: auto;
                }

                :where(abbr[title]) {
                    -webkit-text-decoration: underline;
                    text-decoration: underline;
                    -webkit-text-decoration: underline dotted;
                    text-decoration: underline dotted;
                }

                :where(b, strong) {
                    font-weight: bolder;
                }

                :where(code, kbd, samp) {
                    font-family: monospace,monospace;
                    font-size: 1em;
                }

                :where(small) {
                    font-size: 80%;
                }

                :where(audio, canvas, iframe, img, svg, video) {
                    vertical-align: middle;
                }

                :where(iframe) {
                    border-style: none;
                }

                :where(svg:not([fill])) {
                    fill: currentColor;
                }

                :where(table) {
                    border-collapse: collapse;
                    border-color: currentColor;
                    text-indent: 0;
                }

                :where(button, input, select) {
                    margin: 0;
                }

                :where(button, [type="button" i], [type="reset" i], [type="submit" i]) {
                    -webkit-appearance: button;
                }

                :where(fieldset) {
                    border: 1px solid #a0a0a0;
                }

                :where(progress) {
                    vertical-align: baseline;
                }

                :where(textarea) {
                    margin: 0;
                    resize: vertical;
                }

                :where([type="search" i]) {
                    -webkit-appearance: textfield;
                    outline-offset: -2px;
                }

                ::-webkit-inner-spin-button,::-webkit-outer-spin-button {
                    height: auto;
                }

                ::-webkit-input-placeholder {
                    color: inherit;
                    opacity: 0.54;
                }

                ::-webkit-search-decoration {
                    -webkit-appearance: none;
                }

                ::-webkit-file-upload-button {
                    -webkit-appearance: button;
                    font: inherit;
                }

                :where(dialog) {
                    background-color: white;
                    border: solid;
                    color: black;
                    height: -moz-fit-content;
                    height: -webkit-fit-content;
                    height: -moz-fit-content;
                    height: fit-content;
                    left: 0;
                    margin: auto;
                    padding: 1em;
                    position: absolute;
                    right: 0;
                    width: -moz-fit-content;
                    width: -webkit-fit-content;
                    width: -moz-fit-content;
                    width: fit-content;
                }

                :where(dialog:not([open])) {
                    display: none;
                }

                :where(details > summary:first-of-type) {
                    display: -webkit-box;
                    display: -webkit-list-item;
                    display: -ms-list-itembox;
                    display: list-item;
                }

                :where([aria-busy="true" i]) {
                    cursor: progress;
                }

                :where([aria-disabled="true" i], [disabled]) {
                    cursor: not-allowed;
                }

                :where([aria-hidden="false" i][hidden]) {
                    display: initial;
                }

                :where([aria-hidden="false" i][hidden]:not(:focus)) {
                    clip: rect(0, 0, 0, 0);
                    position: absolute;
                }

                :where(body) {
                    margin: 0;
                }

                :where(h1) {
                    font-size: 2em;
                    margin: 0.67em 0;
                }

                :where(dl, ol, ul) :where(dl, ol, ul) {
                    margin: 0;
                }

                :where(hr) {
                    color: inherit;
                    height: 0;
                }

                :where(nav) :where(ol, ul) {
                    list-style-type: none;
                    padding: 0;
                }

                :where(nav li)::before {
                    content: "​";
                    float: left;
                }

                :where(pre) {
                    font-family: monospace,monospace;
                    font-size: 1em;
                    overflow: auto;
                }

                :where(abbr[title]) {
                    -webkit-text-decoration: underline;
                    text-decoration: underline;
                    -webkit-text-decoration: underline dotted;
                    text-decoration: underline dotted;
                }

                :where(b, strong) {
                    font-weight: bolder;
                }

                :where(code, kbd, samp) {
                    font-family: monospace,monospace;
                    font-size: 1em;
                }

                :where(small) {
                    font-size: 80%;
                }

                :where(audio, canvas, iframe, img, svg, video) {
                    vertical-align: middle;
                }

                :where(iframe) {
                    border-style: none;
                }

                :where(svg:not([fill])) {
                    fill: currentColor;
                }

                :where(table) {
                    border-collapse: collapse;
                    border-color: currentColor;
                    text-indent: 0;
                }

                :where(button, input, select) {
                    margin: 0;
                }

                :where(button, [type="button" i], [type="reset" i], [type="submit" i]) {
                    -webkit-appearance: button;
                }

                :where(fieldset) {
                    border: 1px solid #a0a0a0;
                }

                :where(progress) {
                    vertical-align: baseline;
                }

                :where(textarea) {
                    margin: 0;
                    resize: vertical;
                }

                :where([type="search" i]) {
                    -webkit-appearance: textfield;
                    outline-offset: -2px;
                }

                ::-webkit-inner-spin-button,::-webkit-outer-spin-button {
                    height: auto;
                }

                ::-webkit-input-placeholder {
                    color: inherit;
                    opacity: 0.54;
                }

                ::-webkit-search-decoration {
                    -webkit-appearance: none;
                }

                ::-webkit-file-upload-button {
                    -webkit-appearance: button;
                    font: inherit;
                }

                :where(dialog) {
                    background-color: white;
                    border: solid;
                    color: black;
                    height: -moz-fit-content;
                    height: -webkit-fit-content;
                    height: -moz-fit-content;
                    height: fit-content;
                    left: 0;
                    margin: auto;
                    padding: 1em;
                    position: absolute;
                    right: 0;
                    width: -moz-fit-content;
                    width: -webkit-fit-content;
                    width: -moz-fit-content;
                    width: fit-content;
                }

                :where(dialog:not([open])) {
                    display: none;
                }

                :where(details > summary:first-of-type) {
                    display: -webkit-box;
                    display: -webkit-list-item;
                    display: -ms-list-itembox;
                    display: list-item;
                }

                :where([aria-busy="true" i]) {
                    cursor: progress;
                }

                :where([aria-disabled="true" i], [disabled]) {
                    cursor: not-allowed;
                }

                :where([aria-hidden="false" i][hidden]) {
                    display: initial;
                }

                :where([aria-hidden="false" i][hidden]:not(:focus)) {
                    clip: rect(0, 0, 0, 0);
                    position: absolute;
                }
            

                * {
                    box-sizing: border-box;
                    -webkit-tap-highlight-color: transparent;
                }

                body {
                    overscroll-behavior-y: none;
                    background-color: var(--colors-backgroundColor);
                    color: var(--colors-text-base);
                    --tw-bg-opacity: 1;
                    background-color: rgb(var(--backgroundColor-white) / var(--tw-bg-opacity));
                }

                body:is([data-theme="dark"] *) {
                    --tw-bg-opacity: 1;
                    background-color: rgb(var(--colors-dark-background) / var(--tw-bg-opacity));
                }

                a {
                    -webkit-text-decoration: none;
                    text-decoration: none;
                    color: inherit;
                }
            

                .css-16igk0y {
                    min-width: 1210px;
                }

                @media screen and (max-width: 1280px) {
                    .css-16igk0y {
                        min-width:auto;
                    }
                }
            

                    .css-ri80gd.slide-up {
                        --tw-translate-y: -100%;
                        -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
                        -moz-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
                        -ms-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
                        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
                    }
                

                                .css-11vadmq {
                                    width: initial;
                                    height: initial;
                                    display: inherit;
                                    -webkit-text-decoration: none;
                                    text-decoration: none;
                                }
                            

                                    .css-17x35y8 {
                                        width: -webkit-fit-content;
                                        width: -moz-fit-content;
                                        width: fit-content;
                                        height: -webkit-fit-content;
                                        height: -moz-fit-content;
                                        height: fit-content;
                                        display: -webkit-inline-box;
                                        display: -webkit-inline-flex;
                                        display: -ms-inline-flexbox;
                                        display: inline-flex;
                                        -webkit-align-items: center;
                                        -webkit-box-align: center;
                                        -ms-flex-align: center;
                                        align-items: center;
                                        -webkit-box-pack: center;
                                        -ms-flex-pack: center;
                                        -webkit-justify-content: center;
                                        justify-content: center;
                                    }
                                

                                        .css-10sv0al {
                                            position: relative;
                                            display: -webkit-inline-box;
                                            display: -webkit-inline-flex;
                                            display: -ms-inline-flexbox;
                                            display: inline-flex;
                                            cursor: pointer;
                                            overflow: hidden;
                                            border-radius: var(--borderRadius-full);
                                            transition-property: color,background-color,border-color,text-decoration-color,fill,stroke;
                                            transition-timing-function: linear;
                                            transition-duration: 300ms;
                                            height: 1.5rem;
                                            width: 3rem;
                                            --tw-bg-opacity: 1;
                                            background-color: rgb(var(--colors-actions-grey-active) / var(--tw-bg-opacity));
                                        }

                                        .css-10sv0al:hover {
                                            --tw-bg-opacity: 1;
                                            background-color: rgb(var(--colors-actions-grey-hover) / var(--tw-bg-opacity));
                                        }

                                        .css-10sv0al:is([data-theme="dark"] *) {
                                            --tw-bg-opacity: 1;
                                            background-color: rgb(var(--colors-dark-actions-grey-active) / var(--tw-bg-opacity));
                                        }

                                        .css-10sv0al:is([data-theme="dark"] *):hover {
                                            --tw-bg-opacity: 1;
                                            background-color: rgb(var(--colors-dark-actions-grey-hover) / var(--tw-bg-opacity));
                                        }
                                    

                                            .css-17yy9o6 {
                                                position: absolute;
                                                display: -webkit-box;
                                                display: -webkit-flex;
                                                display: -ms-flexbox;
                                                display: flex;
                                                -webkit-align-items: center;
                                                -webkit-box-align: center;
                                                -ms-flex-align: center;
                                                align-items: center;
                                                -webkit-box-pack: center;
                                                -ms-flex-pack: center;
                                                -webkit-justify-content: center;
                                                justify-content: center;
                                                font-weight: 600;
                                                transition-property: transform;
                                                transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
                                                transition-duration: 300ms;
                                                height: 1.5rem;
                                                width: 1.5rem;
                                                font-size: var(--fontSize-xs-0);
                                                line-height: var(--fontSize-xs-1-lineHeight);
                                                --tw-text-opacity: 1;
                                                color: rgb(var(--textColor-color-bg) / var(--tw-text-opacity));
                                                left: auto;
                                                right: 2px;
                                            }
                                        

                                            .css-1x3c3gj {
                                                -webkit-transition: all 0.3s ease;
                                                transition: all 0.3s ease;
                                                position: absolute;
                                                left: 0.125rem;
                                                right: auto;
                                                top: 0.125rem;
                                                border-radius: var(--borderRadius-full);
                                                --tw-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),0px 1px 2px rgba(16, 24, 40, 0.06);
                                                --tw-shadow-colored: 0px 1px 3px var(--tw-shadow-color),0px 1px 2px var(--tw-shadow-color);
                                                box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow);
                                                height: 1.25rem;
                                                width: 1.25rem;
                                                --tw-bg-opacity: 1;
                                                background-color: rgb(var(--colors-icon-color-bg) / var(--tw-bg-opacity));
                                            }

                                            .group:active .css-1x3c3gj {
                                                width: 1.5rem;
                                            }
                                        

                                            .css-1vrtf0 {
                                                position: absolute;
                                                display: -webkit-box;
                                                display: -webkit-flex;
                                                display: -ms-flexbox;
                                                display: flex;
                                                -webkit-align-items: center;
                                                -webkit-box-align: center;
                                                -ms-flex-align: center;
                                                align-items: center;
                                                -webkit-box-pack: center;
                                                -ms-flex-pack: center;
                                                -webkit-justify-content: center;
                                                justify-content: center;
                                                font-weight: 600;
                                                transition-property: transform;
                                                transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
                                                transition-duration: 300ms;
                                                height: 1.5rem;
                                                width: 1.5rem;
                                                font-size: var(--fontSize-xs-0);
                                                line-height: var(--fontSize-xs-1-lineHeight);
                                                --tw-text-opacity: 1;
                                                color: rgb(var(--textColor-color-bg) / var(--tw-text-opacity));
                                                left: 2px;
                                                --tw-translate-x: -2rem;
                                                -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
                                                -moz-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
                                                -ms-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
                                                transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
                                            }
                                        

                                                        .css-1wj38mq {
                                                            width: 165px;
                                                            height: 40px;
                                                            position: relative;
                                                            display: -webkit-box;
                                                            display: -webkit-flex;
                                                            display: -ms-flexbox;
                                                            display: flex;
                                                            -webkit-align-items: center;
                                                            -webkit-box-align: center;
                                                            -ms-flex-align: center;
                                                            align-items: center;
                                                            -webkit-box-pack: center;
                                                            -ms-flex-pack: center;
                                                            -webkit-justify-content: center;
                                                            justify-content: center;
                                                            background-color: #000000;
                                                            border-radius: 6px;
                                                            color: white;
                                                            padding: 0px 2px;
                                                            -webkit-transition: 100ms;
                                                            transition: 100ms;
                                                        }

                                                        .css-1wj38mq:hover {
                                                            background-color: #2a262c;
                                                        }
                                                    

                                                        .css-16yni10 {
                                                            width: 165px;
                                                            height: 40px;
                                                            position: relative;
                                                            display: -webkit-box;
                                                            display: -webkit-flex;
                                                            display: -ms-flexbox;
                                                            display: flex;
                                                            -webkit-align-items: center;
                                                            -webkit-box-align: center;
                                                            -ms-flex-align: center;
                                                            align-items: center;
                                                            -webkit-box-pack: center;
                                                            -ms-flex-pack: center;
                                                            -webkit-justify-content: center;
                                                            justify-content: center;
                                                            background-color: #000000;
                                                            border-radius: 6px;
                                                            color: white;
                                                            padding: 6px 8px;
                                                            -webkit-transition: 100ms;
                                                            transition: 100ms;
                                                        }

                                                        .css-16yni10:hover {
                                                            background-color: #2a262c;
                                                        }
                                                    

                .css-1nxlsp8 {
                    position: relative;
                    min-width: 1210px;
                    overflow-x: clip;
                }

                @media screen and (max-width: 1280px) {
                    .css-1nxlsp8 {
                        min-width:auto;
                    }
                }
            

                        .css-122aq4p {
                            position: relative;
                            --tw-bg-opacity: 1;
                            background-color: rgb(var(--colors-background) / var(--tw-bg-opacity));
                        }

                        .css-122aq4p:is([data-theme="dark"] *) {
                            --tw-bg-opacity: 1;
                            background-color: rgb(var(--colors-dark-background) / var(--tw-bg-opacity));
                        }
                    

                            .css-qbe55y {
                                height: 0px;
                                visibility: hidden;
                                overflow: hidden;
                                display: -webkit-box;
                                display: -webkit-flex;
                                display: -ms-flexbox;
                                display: flex;
                                -webkit-flex-direction: row;
                                -ms-flex-direction: row;
                                flex-direction: row;
                                -webkit-align-items: center;
                                -webkit-box-align: center;
                                -ms-flex-align: center;
                                align-items: center;
                                -webkit-box-pack: center;
                                -ms-flex-pack: center;
                                -webkit-justify-content: center;
                                justify-content: center;
                                gap: 0.25rem;
                                font-size: var(--fontSize-sm-0);
                                line-height: var(--fontSize-sm-1-lineHeight);
                                font-weight: 700;
                                --tw-text-opacity: 1;
                                color: rgb(190 44 83 / var(--tw-text-opacity));
                                --tw-bg-opacity: 1;
                                background-color: rgb(253 235 240 / var(--tw-bg-opacity));
                                transition-property: all;
                                transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
                                transition-duration: 300ms;
                            }

                            .css-qbe55y:is([data-theme="dark"] *) {
                                --tw-text-opacity: 1;
                                color: rgb(255 110 136 / var(--tw-text-opacity));
                            }

                            .css-qbe55y:is([data-theme="dark"] *) {
                                --tw-bg-opacity: 1;
                                background-color: rgb(85 13 26 / var(--tw-bg-opacity));
                            }
                        

                                .css-17x35y8 {
                                    width: -webkit-fit-content;
                                    width: -moz-fit-content;
                                    width: fit-content;
                                    height: -webkit-fit-content;
                                    height: -moz-fit-content;
                                    height: fit-content;
                                    display: -webkit-inline-box;
                                    display: -webkit-inline-flex;
                                    display: -ms-inline-flexbox;
                                    display: inline-flex;
                                    -webkit-align-items: center;
                                    -webkit-box-align: center;
                                    -ms-flex-align: center;
                                    align-items: center;
                                    -webkit-box-pack: center;
                                    -ms-flex-pack: center;
                                    -webkit-justify-content: center;
                                    justify-content: center;
                                }
                            

                                .css-t5ynrc {
                                    position: relative;
                                    overflow: hidden;
                                    padding-bottom: 18px;
                                }
                            

                                    .css-d1mjv3 {
                                        position: relative;
                                        z-index: 2;
                                        --tw-bg-opacity: 1;
                                        background-color: rgb(var(--backgroundColor-purple-400) / var(--tw-bg-opacity));
                                        height: 60px;
                                        overflow: hidden;
                                        transition-property: all;
                                        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
                                        transition-duration: 300ms;
                                    }

                                    .css-d1mjv3:is([data-theme="dark"] *) {
                                        --tw-bg-opacity: 1;
                                        background-color: rgb(var(--colors-dark-foreground-purple) / var(--tw-bg-opacity));
                                    }

                                    @media (min-width: 1280px) {
                                        .css-d1mjv3 {
                                            height:4rem;
                                        }
                                    }

                                    .css-d1mjv3:before {
                                        content: "";
                                        position: absolute;
                                        left: 11px;
                                        top: 0px;
                                        height: 114px;
                                        width: 114px;
                                        border-radius: var(--borderRadius-full);
                                        --tw-blur: blur(75px);
                                        -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
                                        filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
                                        opacity: 0.6;
                                        --tw-bg-opacity: 1;
                                        background-color: rgb(var(--backgroundColor-teal-400) / var(--tw-bg-opacity));
                                    }

                                    @media (min-width: 1280px) {
                                        .css-d1mjv3:before {
                                            top:-0.5rem;
                                            left: 25%;
                                        }
                                    }

                                    .css-d1mjv3:after {
                                        content: "";
                                        position: absolute;
                                        right: -120px;
                                        top: 11px;
                                        height: 254px;
                                        width: 254px;
                                        border-radius: var(--borderRadius-full);
                                        --tw-blur: blur(75px);
                                        -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
                                        filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
                                        opacity: 0.6;
                                        --tw-bg-opacity: 1;
                                        background-color: rgb(var(--backgroundColor-teal-400) / var(--tw-bg-opacity));
                                    }

                                    @media (min-width: 1280px) {
                                        .css-d1mjv3:after {
                                            right:15.5%;
                                            top: 0px;
                                        }
                                    }
                                

                                        .css-1yhv5d7 {
                                            position: relative;
                                            height: 100%;
                                            padding-top: 0px!important;
                                            padding-bottom: 0px!important;
                                        }
                                    

                                        .css-13xwwu0 {
                                            width: 90%;
                                            min-width: 1210px;
                                            max-width: 1400px;
                                            box-sizing: border-box;
                                            margin: 0px auto;
                                            padding: 0px 20px;
                                            position: relative;
                                            height: 100%;
                                            padding-top: 0px!important;
                                            padding-bottom: 0px!important;
                                        }

                                        @media screen and (max-width: 1280px) {
                                            .css-13xwwu0 {
                                                min-width:-webkit-fill-available;
                                            }
                                        }
                                    

                                            .css-eyvg7m {
                                                position: absolute;
                                                left: 50%;
                                                z-index: 1;
                                                display: -webkit-box;
                                                display: -webkit-flex;
                                                display: -ms-flexbox;
                                                display: flex;
                                                height: 60px;
                                                width: 100%;
                                                --tw-translate-x: -50%;
                                                -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
                                                -moz-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
                                                -ms-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
                                                transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
                                                -webkit-align-items: center;
                                                -webkit-box-align: center;
                                                -ms-flex-align: center;
                                                align-items: center;
                                                -webkit-box-pack: center;
                                                -ms-flex-pack: center;
                                                -webkit-justify-content: center;
                                                justify-content: center;
                                                padding-top: 0.75rem;
                                                padding-bottom: 0.75rem;
                                            }

                                            @media (min-width: 1280px) {
                                                .css-eyvg7m {
                                                    height:4rem;
                                                    width: auto;
                                                }
                                            }
                                        

                                                .css-1qtjs73 {
                                                    margin-right: 0.75rem;
                                                }

                                                @media (min-width: 1280px) {
                                                    .css-1qtjs73 {
                                                        margin-right:1rem;
                                                    }
                                                }
                                            

                                                .css-zsnie1 {
                                                    display: -webkit-box;
                                                    display: -webkit-flex;
                                                    display: -ms-flexbox;
                                                    display: flex;
                                                    -webkit-flex-direction: column;
                                                    -ms-flex-direction: column;
                                                    flex-direction: column;
                                                    --tw-text-opacity: 1;
                                                    color: rgb(var(--textColor-white) / var(--tw-text-opacity));
                                                }
                                            

                                                    .css-f08wz7 {
                                                        font-size: 12px;
                                                        line-height: 16px;
                                                        letter-spacing: 0px;
                                                        font-weight: 700;
                                                    }

                                                    @media (min-width: 1280px) {
                                                        .css-f08wz7 {
                                                            font-size:16px;
                                                            line-height: 24px;
                                                            letter-spacing: -0.2px;
                                                            font-weight: 700;
                                                        }
                                                    }
                                                

                                                    .css-fqc7p0 {
                                                        font-size: 10px;
                                                        line-height: 14px;
                                                        letter-spacing: 0.1px;
                                                    }

                                                    @media (min-width: 1280px) {
                                                        .css-fqc7p0 {
                                                            font-size:12px;
                                                            line-height: 16px;
                                                            letter-spacing: 0.1px;
                                                            max-width: unset;
                                                        }
                                                    }
                                                

                                                .css-u94wye {
                                                    margin-left: 0.5rem;
                                                    transition-duration: 300ms;
                                                }

                                                @media (min-width: 1280px) {
                                                    .css-u94wye {
                                                        margin-left:1rem;
                                                    }
                                                }
                                            

                                                .css-1en1icc {
                                                    display: -webkit-box;
                                                    display: -webkit-flex;
                                                    display: -ms-flexbox;
                                                    display: flex;
                                                    -webkit-align-items: center;
                                                    -webkit-box-align: center;
                                                    -ms-flex-align: center;
                                                    align-items: center;
                                                    -webkit-box-pack: center;
                                                    -ms-flex-pack: center;
                                                    -webkit-justify-content: center;
                                                    justify-content: center;
                                                    border-radius: 32px;
                                                    font-weight: 700;
                                                    --tw-bg-opacity: 1;
                                                    background-color: rgb(var(--colors-action-primary) / var(--tw-bg-opacity));
                                                    --tw-text-opacity: 1;
                                                    color: rgb(var(--textColor-color-bg) / var(--tw-text-opacity));
                                                    height: 2.5rem;
                                                    gap: 0.25rem;
                                                    padding-left: 0.75rem;
                                                    padding-right: 0.75rem;
                                                    padding-top: 0.625rem;
                                                    padding-bottom: 0.625rem;
                                                    font-size: var(--fontSize-sm-0);
                                                    line-height: var(--fontSize-sm-1-lineHeight);
                                                    margin-left: 0.5rem;
                                                    transition-duration: 300ms;
                                                }

                                                .css-1en1icc:disabled {
                                                    cursor: not-allowed!important;
                                                    --tw-border-opacity: 1!important;
                                                    border-color: rgb(var(--colors-action-disabled) / var(--tw-border-opacity))!important;
                                                    --tw-bg-opacity: 1!important;
                                                    background-color: rgb(var(--colors-action-disabled) / var(--tw-bg-opacity))!important;
                                                    --tw-text-opacity: 1;
                                                    color: rgb(var(--textColor-disabled) / var(--tw-text-opacity));
                                                }

                                                .css-1en1icc:disabled:is([data-theme="dark"] *) {
                                                    --tw-border-opacity: 1!important;
                                                    border-color: rgb(var(--colors-dark-action-disabled) / var(--tw-border-opacity))!important;
                                                    --tw-bg-opacity: 1!important;
                                                    background-color: rgb(var(--colors-dark-action-disabled) / var(--tw-bg-opacity))!important;
                                                    --tw-text-opacity: 1;
                                                    color: rgb(var(--textColor-dark-disabled) / var(--tw-text-opacity));
                                                }

                                                .css-1en1icc:hover {
                                                    --tw-bg-opacity: 1;
                                                    background-color: rgb(var(--backgroundColor-teal-600) / var(--tw-bg-opacity));
                                                }

                                                .css-1en1icc:is([data-theme="dark"] *) {
                                                    --tw-bg-opacity: 1;
                                                    background-color: rgb(var(--colors-dark-action-primary) / var(--tw-bg-opacity));
                                                    --tw-text-opacity: 1;
                                                    color: rgb(var(--textColor-dark-color-bg) / var(--tw-text-opacity));
                                                }

                                                .css-1en1icc:hover:is([data-theme="dark"] *) {
                                                    --tw-bg-opacity: 1;
                                                    background-color: rgb(var(--colors-dark-teal-100) / var(--tw-bg-opacity));
                                                }

                                                @media (min-width: 1280px) {
                                                    .css-1en1icc {
                                                        margin-left:1rem;
                                                    }
                                                }
                                            

                                    .css-1acj50i {
                                        position: absolute;
                                        bottom: 0px;
                                        left: 50%;
                                        display: -webkit-box;
                                        display: -webkit-flex;
                                        display: -ms-flexbox;
                                        display: flex;
                                        height: 42px;
                                        width: 42px;
                                        --tw-translate-x: -50%;
                                        -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
                                        -moz-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
                                        -ms-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
                                        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
                                        -webkit-align-items: flex-end;
                                        -webkit-box-align: flex-end;
                                        -ms-flex-align: flex-end;
                                        align-items: flex-end;
                                        -webkit-box-pack: center;
                                        -ms-flex-pack: center;
                                        -webkit-justify-content: center;
                                        justify-content: center;
                                        border-bottom-right-radius: var(--borderRadius-full);
                                        border-bottom-left-radius: var(--borderRadius-full);
                                        padding-left: 3px;
                                        padding-right: 3px;
                                        padding-bottom: 0.25rem;
                                        --tw-bg-opacity: 1;
                                        background-color: rgb(var(--backgroundColor-purple-400) / var(--tw-bg-opacity));
                                        --tw-text-opacity: 1;
                                        color: rgb(var(--textColor-white) / var(--tw-text-opacity));
                                        cursor: pointer;
                                        z-index: 1;
                                    }

                                    .css-1acj50i:is([data-theme="dark"] *) {
                                        --tw-bg-opacity: 1;
                                        background-color: rgb(var(--colors-dark-foreground-purple) / var(--tw-bg-opacity));
                                    }

                                    .css-1acj50i:is([data-theme="dark"] *) {
                                        --tw-text-opacity: 1;
                                        color: rgb(var(--colors-icon-secondary) / var(--tw-text-opacity));
                                    }

                                    .css-1acj50i:before {
                                        content: " ";
                                        background-image: url("data:image/svg+xml,%3Csvg width='15' height='10' viewBox='0 0 6 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 0L5.23713 4.01749C5.23713 4.01749 4.6684 2.41768 3.51637 1.49606C2.36434 0.574438 0 0 0 0H5Z' fill='%237C5CBC'/%3E%3C/svg%3E");
                                        position: absolute;
                                        left: -0.625rem;
                                        bottom: 10px;
                                        height: 10px;
                                        width: 15px;
                                    }

                                    .css-1acj50i:after {
                                        content: " ";
                                        background-image: url("data:image/svg+xml,%3Csvg width='15' height='10' viewBox='0 0 6 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 0L0.762873 4.01749C0.762873 4.01749 1.3316 2.41768 2.48363 1.49606C3.63566 0.574438 6 0 6 0H1Z' fill='%237C5CBC'/%3E%3C/svg%3E");
                                        position: absolute;
                                        right: -0.625rem;
                                        bottom: 10px;
                                        height: 10px;
                                        width: 15px;
                                    }
                                

                                .css-7lphdr {
                                    width: 100%!important;
                                }
                            

                                .css-c04t15 {
                                    display: -webkit-inline-box;
                                    display: -webkit-inline-flex;
                                    display: -ms-inline-flexbox;
                                    display: inline-flex;
                                    width: auto;
                                    -webkit-flex-direction: column;
                                    -ms-flex-direction: column;
                                    flex-direction: column;
                                    -webkit-align-items: unset;
                                    -webkit-box-align: unset;
                                    -ms-flex-align: unset;
                                    align-items: unset;
                                    -webkit-box-pack: unset;
                                    -ms-flex-pack: unset;
                                    -webkit-justify-content: unset;
                                    justify-content: unset;
                                    gap: 8px;
                                    width: 100%!important;
                                }
                            

                                    .css-65dlnv {
                                        display: -webkit-box;
                                        display: -webkit-flex;
                                        display: -ms-flexbox;
                                        display: flex;
                                        -webkit-flex-direction: row;
                                        -ms-flex-direction: row;
                                        flex-direction: row;
                                        -webkit-align-items: center;
                                        -webkit-box-align: center;
                                        -ms-flex-align: center;
                                        align-items: center;
                                        -webkit-box-pack: justify;
                                        -webkit-justify-content: space-between;
                                        justify-content: space-between;
                                        padding-left: 1rem;
                                        padding-right: 1rem;
                                    }

                                    @media (min-width: 1280px) {
                                        .css-65dlnv {
                                            padding:1rem;
                                        }
                                    }
                                

                                        .css-a0377c {
                                            display: -webkit-box;
                                            display: -webkit-flex;
                                            display: -ms-flexbox;
                                            display: flex;
                                            -webkit-align-items: center;
                                            -webkit-box-align: center;
                                            -ms-flex-align: center;
                                            align-items: center;
                                            gap: 0.75rem;
                                        }
                                    

                                            .css-6wwad8 {
                                                height: 2rem;
                                                width: 2rem;
                                            }

                                            @media (min-width: 1280px) {
                                                .css-6wwad8 {
                                                    height:2.5rem;
                                                    width: 2.5rem;
                                                }
                                            }
                                        

                                            .css-qlgi5i {
                                                display: -webkit-box;
                                                display: -webkit-flex;
                                                display: -ms-flexbox;
                                                display: flex;
                                                -webkit-flex-direction: row;
                                                -ms-flex-direction: row;
                                                flex-direction: row;
                                                -webkit-align-items: center;
                                                -webkit-box-align: center;
                                                -ms-flex-align: center;
                                                align-items: center;
                                                gap: 0.25rem;
                                            }

                                            @media (min-width: 1280px) {
                                                .css-qlgi5i {
                                                    -webkit-flex-direction:column;
                                                    -ms-flex-direction: column;
                                                    flex-direction: column;
                                                    -webkit-align-items: flex-start;
                                                    -webkit-box-align: flex-start;
                                                    -ms-flex-align: flex-start;
                                                    align-items: flex-start;
                                                    gap: 0px;
                                                }
                                            }
                                        

                                                    .css-1r4fdly {
                                                        font-size: 14px;
                                                        line-height: 20px;
                                                        letter-spacing: -0.1px;
                                                        font-weight: 600;
                                                        --tw-text-opacity: 1;
                                                        color: rgb(var(--textColor-bold) / var(--tw-text-opacity));
                                                    }

                                                    .css-1r4fdly:is([data-theme="dark"] *) {
                                                        --tw-text-opacity: 1;
                                                        color: rgb(var(--textColor-dark-bold) / var(--tw-text-opacity));
                                                    }
                                                

                                                    .css-1vts9zp {
                                                        font-size: 12px;
                                                        line-height: 16px;
                                                        letter-spacing: 0px;
                                                        font-weight: 600;
                                                        --tw-text-opacity: 1;
                                                        color: rgb(var(--textColor-additional) / var(--tw-text-opacity));
                                                    }

                                                    .css-1vts9zp:is([data-theme="dark"] *) {
                                                        --tw-text-opacity: 1;
                                                        color: rgb(var(--textColor-dark-additional) / var(--tw-text-opacity));
                                                    }
                                                

                                        :root {
                                            --rsbs-backdrop-bg: rgba(0, 0, 0, 0.25);
                                            --rsbs-max-w: auto;
                                            --rsbs-ml: env(safe-area-inset-left);
                                            --rsbs-mr: env(safe-area-inset-right);
                                            --rsbs-overlay-rounded: 16px;
                                        }

                                        @media (min-width: 600px) {
                                            :root:root {
                                                --rsbs-max-h:70%;
                                                --rsbs-ml: auto;
                                                --rsbs-mr: auto;
                                            }
                                        }

                                        [data-rsbs-overlay] {
                                            border-top-left-radius: var(--rsbs-overlay-rounded);
                                            border-top-right-radius: var(--rsbs-overlay-rounded);
                                            display: -webkit-box;
                                            display: -webkit-flex;
                                            display: -ms-flexbox;
                                            display: flex;
                                            -webkit-flex-direction: column;
                                            -ms-flex-direction: column;
                                            flex-direction: column;
                                            height: var(--rsbs-overlay-h);
                                            -webkit-transform: translate3d(0, var(--rsbs-overlay-translate-y), 0);
                                            -moz-transform: translate3d(0, var(--rsbs-overlay-translate-y), 0);
                                            -ms-transform: translate3d(0, var(--rsbs-overlay-translate-y), 0);
                                            transform: translate3d(0, var(--rsbs-overlay-translate-y), 0);
                                            will-change: height;
                                            --tw-bg-opacity: 1;
                                            background-color: rgb(var(--colors-background) / var(--tw-bg-opacity));
                                        }

                                        [data-rsbs-overlay]:is([data-theme="dark"] *) {
                                            --tw-bg-opacity: 1;
                                            background-color: rgb(var(--colors-dark-background) / var(--tw-bg-opacity));
                                        }

                                        [data-rsbs-overlay]:focus {
                                            outline: none;
                                        }

                                        [data-rsbs-is-blocking="false"] [data-rsbs-overlay] {
                                            box-shadow: 0 -5px 60px 0 rgba(38, 89, 115, 0.11),0 -1px 0 rgba(38, 89, 115, 0.05);
                                        }

                                        [data-rsbs-overlay],[data-rsbs-root]:after {
                                            max-width: var(--rsbs-max-w);
                                            margin-left: var(--rsbs-ml);
                                            margin-right: var(--rsbs-mr);
                                        }

                                        [data-rsbs-overlay],[data-rsbs-backdrop],[data-rsbs-root]:after {
                                            z-index: 40;
                                            overscroll-behavior: none;
                                            touch-action: none;
                                            position: fixed;
                                            right: 0;
                                            bottom: 0;
                                            left: 0;
                                            -webkit-user-select: none;
                                            -moz-user-select: none;
                                            -ms-user-select: none;
                                            user-select: none;
                                            -webkit-tap-highlight-color: transparent;
                                            -webkit-touch-callout: none;
                                            -webkit-transition: opacity 0.3s;
                                            transition: opacity 0.3s;
                                        }

                                        [data-rsbs-backdrop] {
                                            top: -60px;
                                            bottom: -60px;
                                            background-color: var(--rsbs-backdrop-bg);
                                            will-change: opacity;
                                            cursor: pointer;
                                            opacity: 1;
                                        }

                                        [data-rsbs-is-dismissable="false"] [data-rsbs-backdrop] {
                                            cursor: ns-resize;
                                        }

                                        [data-rsbs-root]:after {
                                            content: "";
                                            pointer-events: none;
                                            height: 1px;
                                            transform-origin: bottom;
                                            -webkit-transform: scale3d(1, var(--rsbs-antigap-scale-y), 1);
                                            -moz-transform: scale3d(1, var(--rsbs-antigap-scale-y), 1);
                                            -ms-transform: scale3d(1, var(--rsbs-antigap-scale-y), 1);
                                            transform: scale3d(1, var(--rsbs-antigap-scale-y), 1);
                                            will-change: transform;
                                            --tw-bg-opacity: 1;
                                            background-color: rgb(var(--colors-background) / var(--tw-bg-opacity));
                                        }

                                        [data-rsbs-root]:after:is([data-theme="dark"] *) {
                                            --tw-bg-opacity: 1;
                                            background-color: rgb(var(--colors-dark-background) / var(--tw-bg-opacity));
                                        }

                                        [data-rsbs-footer],[data-rsbs-header] {
                                            -webkit-flex-shrink: 0;
                                            -ms-flex-negative: 0;
                                            flex-shrink: 0;
                                            cursor: ns-resize;
                                            padding: 16px;
                                        }

                                        [data-rsbs-header] {
                                            text-align: center;
                                            -webkit-user-select: none;
                                            -moz-user-select: none;
                                            -ms-user-select: none;
                                            user-select: none;
                                            z-index: 1;
                                            padding-top: calc(20px + env(safe-area-inset-top));
                                            padding-bottom: 10px;
                                        }

                                        [data-rsbs-header]:before {
                                            position: absolute;
                                            content: "";
                                            display: block;
                                            width: 100px;
                                            height: 4px;
                                            top: calc(8px + env(safe-area-inset-top));
                                            left: 50%;
                                            -webkit-transform: translateX(-50%);
                                            -moz-transform: translateX(-50%);
                                            -ms-transform: translateX(-50%);
                                            transform: translateX(-50%);
                                            border-radius: 2px;
                                            background-color: var(--colors-borderColor);
                                        }

                                        @media (min-resolution: 2dppx) {
                                            [data-rsbs-header]:before {
                                                -webkit-transform:translateX(-50%) scaleY(0.75);
                                                -moz-transform: translateX(-50%) scaleY(0.75);
                                                -ms-transform: translateX(-50%) scaleY(0.75);
                                                transform: translateX(-50%) scaleY(0.75);
                                            }
                                        }

                                        [data-rsbs-has-header="false"] [data-rsbs-header] {
                                            box-shadow: none;
                                            padding-top: calc(12px + env(safe-area-inset-top));
                                        }

                                        [data-rsbs-scroll] {
                                            -webkit-flex-shrink: 1;
                                            -ms-flex-negative: 1;
                                            flex-shrink: 1;
                                            -webkit-box-flex: 1;
                                            -webkit-flex-grow: 1;
                                            -ms-flex-positive: 1;
                                            flex-grow: 1;
                                            -webkit-tap-highlight-color: revert;
                                            -webkit-touch-callout: revert;
                                            -webkit-user-select: auto;
                                            -ms-user-select: auto;
                                            -webkit-user-select: auto;
                                            -moz-user-select: auto;
                                            -ms-user-select: auto;
                                            user-select: auto;
                                            overflow: auto;
                                            overscroll-behavior: contain;
                                            -webkit-overflow-scrolling: touch;
                                        }

                                        [data-rsbs-scroll]::-webkit-scrollbar {
                                            width: 4px;
                                        }

                                        [data-rsbs-scroll]::-webkit-scrollbar-thumb {
                                            border-radius: var(--borderRadius);
                                            --tw-bg-opacity: 1;
                                            background-color: rgb(217 217 217 / var(--tw-bg-opacity));
                                        }

                                        [data-rsbs-scroll]:focus {
                                            outline: none;
                                        }

                                        [data-rsbs-has-footer="false"] [data-rsbs-content] {
                                            padding-bottom: env(safe-area-inset-bottom);
                                        }

                                        [data-rsbs-content] {
                                            overflow: hidden;
                                        }

                                        [data-rsbs-footer] {
                                            overflow: hidden;
                                            z-index: 1;
                                            padding-bottom: calc(16px + env(safe-area-inset-bottom));
                                        }

                                        [data-rsbs-is-dismissable="true"] [data-rsbs-header]>*,[data-rsbs-is-dismissable="true"] [data-rsbs-scroll]>*,[data-rsbs-is-dismissable="true"] [data-rsbs-footer]>*,[data-rsbs-is-dismissable="false"][data-rsbs-state="opening"] [data-rsbs-header]>*,[data-rsbs-is-dismissable="false"][data-rsbs-state="closing"] [data-rsbs-header]>*,[data-rsbs-is-dismissable="false"][data-rsbs-state="opening"] [data-rsbs-scroll]>*,[data-rsbs-is-dismissable="false"][data-rsbs-state="closing"] [data-rsbs-scroll]>*,[data-rsbs-is-dismissable="false"][data-rsbs-state="opening"] [data-rsbs-footer]>*,[data-rsbs-is-dismissable="false"][data-rsbs-state="closing"] [data-rsbs-footer]>* {
                                            opacity: 1;
                                            opacity: var(--rsbs-content-opacity, 1);
                                        }

                                        [data-rsbs-is-dismissable="true"] [data-rsbs-backdrop],[data-rsbs-is-dismissable="false"][data-rsbs-state="opening"] [data-rsbs-backdrop],[data-rsbs-is-dismissable="false"][data-rsbs-state="closing"] [data-rsbs-backdrop] {
                                            opacity: 1;
                                            opacity: var(--rsbs-backdrop-opacity, 1);
                                        }

                                        [data-rsbs-state="closed"],[data-rsbs-state="closing"] {
                                            pointer-events: none;
                                        }
                                    

                                    .css-msepic {
                                        display: grid;
                                        grid-template-columns: repeat(2, minmax(0, 1fr));
                                        grid-template-rows: repeat(2, minmax(0, 1fr));
                                        -webkit-align-items: center;
                                        -webkit-box-align: center;
                                        -ms-flex-align: center;
                                        align-items: center;
                                        -webkit-box-pack: justify;
                                        -webkit-justify-content: space-between;
                                        justify-content: space-between;
                                        row-gap: 1rem;
                                    }

                                    @media (min-width: 1280px) {
                                        .css-msepic {
                                            display:-webkit-box;
                                            display: -webkit-flex;
                                            display: -ms-flexbox;
                                            display: flex;
                                        }
                                    }
                                

                                        .css-1bin0ik {
                                            display: -webkit-box;
                                            display: -webkit-flex;
                                            display: -ms-flexbox;
                                            display: flex;
                                            -webkit-flex-direction: column;
                                            -ms-flex-direction: column;
                                            flex-direction: column;
                                            padding-left: 1rem;
                                            padding-right: 1rem;
                                        }

                                        @media (min-width: 1280px) {
                                            .css-1bin0ik {
                                                padding:1rem;
                                            }
                                        }
                                    

                                            .css-1ddp69g {
                                                font-size: 12px;
                                                line-height: 16px;
                                                letter-spacing: 0px;
                                                font-weight: 600;
                                                --tw-text-opacity: 1;
                                                color: rgb(0 0 0);
                                            }

                                            @media (min-width: 1280px) {
                                                .css-1ddp69g {
                                                    font-size:14px;
                                                    line-height: 20px;
                                                    letter-spacing: -0.1px;
                                                    font-weight: 600;
                                                }
                                            }

                                            .css-1ddp69g:is([data-theme="dark"] *) {
                                                --tw-text-opacity: 1;
                                                color: rgb(var(--textColor-dark-additional) / var(--tw-text-opacity));
                                            }
                                        

                                            .css-b5xy2g {
                                                font-family: '__Manrope_cb2832', '__Manrope_Fallback_cb2832';
                                                font-size: var(--fontSize-xs-0);
                                                line-height: 20px;
                                                letter-spacing: -0.1px;
                                                /* font-weight: 650 !important; */
                                                display: -webkit-box;
                                                display: -webkit-flex;
                                                display: -ms-flexbox;
                                                display: flex;
                                                -webkit-align-items: center;
                                                -webkit-box-align: center;
                                                -ms-flex-align: center;
                                                align-items: center;
                                                gap: 1rem;
                                                --tw-text-opacity: 1;
                                                color: rgb(0 0 0);
                                            }

                                            .css-b5xy2g:is([data-theme="dark"] *) {
                                                --tw-text-opacity: 1;
                                                color: rgb(var(--textColor-dark-main) / var(--tw-text-opacity));
                                            }
                                        

                                                .css-8irbms {
                                                    display: -webkit-inline-box;
                                                    display: -webkit-inline-flex;
                                                    display: -ms-inline-flexbox;
                                                    display: inline-flex;
                                                    -webkit-align-items: center;
                                                    -webkit-box-align: center;
                                                    -ms-flex-align: center;
                                                    align-items: center;
                                                }
                                            

                                                    .css-6deuz6 {
                                                        display: -webkit-box;
                                                        display: -webkit-flex;
                                                        display: -ms-flexbox;
                                                        display: flex;
                                                        -webkit-align-items: center;
                                                        -webkit-box-align: center;
                                                        -ms-flex-align: center;
                                                        align-items: center;
                                                        padding-left: 0.5rem;
                                                        padding-right: 0.5rem;
                                                        padding-top: 3px;
                                                        padding-bottom: 3px;
                                                        border-radius: var(--borderRadius-full);
                                                        --tw-bg-opacity: 1;
                                                        background-color: rgb(var(--colors-danger-25) / var(--tw-bg-opacity));
                                                    }

                                                    .css-6deuz6:is([data-theme="dark"] *) {
                                                        background-color: rgb(var(--colors-danger-500) / 0.2);
                                                    }
                                                

                                                        .css-1ys42qh {
                                                            font-size: 12px;
                                                            line-height: 16px;
                                                            letter-spacing: 0px;
                                                            font-weight: 600;
                                                            --tw-text-opacity: 1;
                                                            color: rgb(var(--colors-error) / var(--tw-text-opacity));
                                                        }

                                                        .css-1ys42qh:is([data-theme="dark"] *) {
                                                            --tw-text-opacity: 1;
                                                            color: rgb(var(--colors-dark-error) / var(--tw-text-opacity));
                                                        }
                                                    

                                    .css-2bq6ft {
                                        width: 100%;
                                        border-top-width: 1px;
                                        --tw-border-opacity: 1;
                                        border-top-color: rgb(var(--colors-grey-100) / var(--tw-border-opacity));
                                    }

                                    .css-2bq6ft:is([data-theme="dark"] *) {
                                        --tw-border-opacity: 1;
                                        border-top-color: rgb(var(--colors-dark-grey-100) / var(--tw-border-opacity));
                                    }
                                

                            .css-1int4hs {
                                width: 90%;
                                min-width: 1210px;
                                max-width: 1400px;
                                box-sizing: border-box;
                                margin: 0px auto;
                                padding: 0px 20px;
                            }

                            @media screen and (max-width: 1280px) {
                                .css-1int4hs {
                                    min-width:-webkit-fill-available;
                                }
                            }
                        

                                .css-hiiyqa {
                                    position: relative;
                                    -webkit-flex-direction: column;
                                    -ms-flex-direction: column;
                                    flex-direction: column;
                                    gap: 1rem;
                                    padding-top: 1.25rem;
                                }

                                @media (min-width: 1280px) {
                                    .css-hiiyqa {
                                        display:-webkit-box;
                                        display: -webkit-flex;
                                        display: -ms-flexbox;
                                        display: flex;
                                        padding-bottom: 1.5rem;
                                        padding-top: 0px;
                                    }
                                }
                            

                                    .css-kfdzx3 {
                                        display: none;
                                        gap: 1rem;
                                    }

                                    @media (min-width: 1280px) {
                                        .css-kfdzx3 {
                                            display:-webkit-box;
                                            display: -webkit-flex;
                                            display: -ms-flexbox;
                                            display: flex;
                                        }
                                    }
                                

                                            .css-17kca7y {
                                                display: -webkit-box;
                                                display: -webkit-flex;
                                                display: -ms-flexbox;
                                                display: flex;
                                                -webkit-flex: 0 0 272px;
                                                -ms-flex: 0 0 272px;
                                                flex: 0 0 272px;
                                                -webkit-flex-direction: column;
                                                -ms-flex-direction: column;
                                                flex-direction: column;
                                                gap: 1rem;
                                            }
                                        

                                                    :root {
                                                        --rsbs-backdrop-bg: rgba(0, 0, 0, 0.25);
                                                        --rsbs-max-w: auto;
                                                        --rsbs-ml: env(safe-area-inset-left);
                                                        --rsbs-mr: env(safe-area-inset-right);
                                                        --rsbs-overlay-rounded: 16px;
                                                    }

                                                    @media (min-width: 600px) {
                                                        :root:root {
                                                            --rsbs-max-h:70%;
                                                            --rsbs-ml: auto;
                                                            --rsbs-mr: auto;
                                                        }
                                                    }

                                                    [data-rsbs-overlay] {
                                                        border-top-left-radius: var(--rsbs-overlay-rounded);
                                                        border-top-right-radius: var(--rsbs-overlay-rounded);
                                                        display: -webkit-box;
                                                        display: -webkit-flex;
                                                        display: -ms-flexbox;
                                                        display: flex;
                                                        -webkit-flex-direction: column;
                                                        -ms-flex-direction: column;
                                                        flex-direction: column;
                                                        height: var(--rsbs-overlay-h);
                                                        -webkit-transform: translate3d(0, var(--rsbs-overlay-translate-y), 0);
                                                        -moz-transform: translate3d(0, var(--rsbs-overlay-translate-y), 0);
                                                        -ms-transform: translate3d(0, var(--rsbs-overlay-translate-y), 0);
                                                        transform: translate3d(0, var(--rsbs-overlay-translate-y), 0);
                                                        will-change: height;
                                                        --tw-bg-opacity: 1;
                                                        background-color: rgb(var(--colors-background) / var(--tw-bg-opacity));
                                                    }

                                                    [data-rsbs-overlay]:is([data-theme="dark"] *) {
                                                        --tw-bg-opacity: 1;
                                                        background-color: rgb(var(--colors-dark-background) / var(--tw-bg-opacity));
                                                    }

                                                    [data-rsbs-overlay]:focus {
                                                        outline: none;
                                                    }

                                                    [data-rsbs-is-blocking="false"] [data-rsbs-overlay] {
                                                        box-shadow: 0 -5px 60px 0 rgba(38, 89, 115, 0.11),0 -1px 0 rgba(38, 89, 115, 0.05);
                                                    }

                                                    [data-rsbs-overlay],[data-rsbs-root]:after {
                                                        max-width: var(--rsbs-max-w);
                                                        margin-left: var(--rsbs-ml);
                                                        margin-right: var(--rsbs-mr);
                                                    }

                                                    [data-rsbs-overlay],[data-rsbs-backdrop],[data-rsbs-root]:after {
                                                        z-index: 40;
                                                        overscroll-behavior: none;
                                                        touch-action: none;
                                                        position: fixed;
                                                        right: 0;
                                                        bottom: 0;
                                                        left: 0;
                                                        -webkit-user-select: none;
                                                        -moz-user-select: none;
                                                        -ms-user-select: none;
                                                        user-select: none;
                                                        -webkit-tap-highlight-color: transparent;
                                                        -webkit-touch-callout: none;
                                                        -webkit-transition: opacity 0.3s;
                                                        transition: opacity 0.3s;
                                                    }

                                                    [data-rsbs-backdrop] {
                                                        top: -60px;
                                                        bottom: -60px;
                                                        background-color: var(--rsbs-backdrop-bg);
                                                        will-change: opacity;
                                                        cursor: pointer;
                                                        opacity: 1;
                                                    }

                                                    [data-rsbs-is-dismissable="false"] [data-rsbs-backdrop] {
                                                        cursor: ns-resize;
                                                    }

                                                    [data-rsbs-root]:after {
                                                        content: "";
                                                        pointer-events: none;
                                                        height: 1px;
                                                        transform-origin: bottom;
                                                        -webkit-transform: scale3d(1, var(--rsbs-antigap-scale-y), 1);
                                                        -moz-transform: scale3d(1, var(--rsbs-antigap-scale-y), 1);
                                                        -ms-transform: scale3d(1, var(--rsbs-antigap-scale-y), 1);
                                                        transform: scale3d(1, var(--rsbs-antigap-scale-y), 1);
                                                        will-change: transform;
                                                        --tw-bg-opacity: 1;
                                                        background-color: rgb(var(--colors-background) / var(--tw-bg-opacity));
                                                    }

                                                    [data-rsbs-root]:after:is([data-theme="dark"] *) {
                                                        --tw-bg-opacity: 1;
                                                        background-color: rgb(var(--colors-dark-background) / var(--tw-bg-opacity));
                                                    }

                                                    [data-rsbs-footer],[data-rsbs-header] {
                                                        -webkit-flex-shrink: 0;
                                                        -ms-flex-negative: 0;
                                                        flex-shrink: 0;
                                                        cursor: ns-resize;
                                                        padding: 16px;
                                                    }

                                                    [data-rsbs-header] {
                                                        text-align: center;
                                                        -webkit-user-select: none;
                                                        -moz-user-select: none;
                                                        -ms-user-select: none;
                                                        user-select: none;
                                                        z-index: 1;
                                                        padding-top: calc(20px + env(safe-area-inset-top));
                                                        padding-bottom: 10px;
                                                    }

                                                    [data-rsbs-header]:before {
                                                        position: absolute;
                                                        content: "";
                                                        display: block;
                                                        width: 100px;
                                                        height: 4px;
                                                        top: calc(8px + env(safe-area-inset-top));
                                                        left: 50%;
                                                        -webkit-transform: translateX(-50%);
                                                        -moz-transform: translateX(-50%);
                                                        -ms-transform: translateX(-50%);
                                                        transform: translateX(-50%);
                                                        border-radius: 2px;
                                                        background-color: var(--colors-borderColor);
                                                    }

                                                    @media (min-resolution: 2dppx) {
                                                        [data-rsbs-header]:before {
                                                            -webkit-transform:translateX(-50%) scaleY(0.75);
                                                            -moz-transform: translateX(-50%) scaleY(0.75);
                                                            -ms-transform: translateX(-50%) scaleY(0.75);
                                                            transform: translateX(-50%) scaleY(0.75);
                                                        }
                                                    }

                                                    [data-rsbs-has-header="false"] [data-rsbs-header] {
                                                        box-shadow: none;
                                                        padding-top: calc(12px + env(safe-area-inset-top));
                                                    }

                                                    [data-rsbs-scroll] {
                                                        -webkit-flex-shrink: 1;
                                                        -ms-flex-negative: 1;
                                                        flex-shrink: 1;
                                                        -webkit-box-flex: 1;
                                                        -webkit-flex-grow: 1;
                                                        -ms-flex-positive: 1;
                                                        flex-grow: 1;
                                                        -webkit-tap-highlight-color: revert;
                                                        -webkit-touch-callout: revert;
                                                        -webkit-user-select: auto;
                                                        -ms-user-select: auto;
                                                        -webkit-user-select: auto;
                                                        -moz-user-select: auto;
                                                        -ms-user-select: auto;
                                                        user-select: auto;
                                                        overflow: auto;
                                                        overscroll-behavior: contain;
                                                        -webkit-overflow-scrolling: touch;
                                                    }

                                                    [data-rsbs-scroll]::-webkit-scrollbar {
                                                        width: 4px;
                                                    }

                                                    [data-rsbs-scroll]::-webkit-scrollbar-thumb {
                                                        border-radius: var(--borderRadius);
                                                        --tw-bg-opacity: 1;
                                                        background-color: rgb(217 217 217 / var(--tw-bg-opacity));
                                                    }

                                                    [data-rsbs-scroll]:focus {
                                                        outline: none;
                                                    }

                                                    [data-rsbs-has-footer="false"] [data-rsbs-content] {
                                                        padding-bottom: env(safe-area-inset-bottom);
                                                    }

                                                    [data-rsbs-content] {
                                                        overflow: hidden;
                                                    }

                                                    [data-rsbs-footer] {
                                                        overflow: hidden;
                                                        z-index: 1;
                                                        padding-bottom: calc(16px + env(safe-area-inset-bottom));
                                                    }

                                                    [data-rsbs-is-dismissable="true"] [data-rsbs-header]>*,[data-rsbs-is-dismissable="true"] [data-rsbs-scroll]>*,[data-rsbs-is-dismissable="true"] [data-rsbs-footer]>*,[data-rsbs-is-dismissable="false"][data-rsbs-state="opening"] [data-rsbs-header]>*,[data-rsbs-is-dismissable="false"][data-rsbs-state="closing"] [data-rsbs-header]>*,[data-rsbs-is-dismissable="false"][data-rsbs-state="opening"] [data-rsbs-scroll]>*,[data-rsbs-is-dismissable="false"][data-rsbs-state="closing"] [data-rsbs-scroll]>*,[data-rsbs-is-dismissable="false"][data-rsbs-state="opening"] [data-rsbs-footer]>*,[data-rsbs-is-dismissable="false"][data-rsbs-state="closing"] [data-rsbs-footer]>* {
                                                        opacity: 1;
                                                        opacity: var(--rsbs-content-opacity, 1);
                                                    }

                                                    [data-rsbs-is-dismissable="true"] [data-rsbs-backdrop],[data-rsbs-is-dismissable="false"][data-rsbs-state="opening"] [data-rsbs-backdrop],[data-rsbs-is-dismissable="false"][data-rsbs-state="closing"] [data-rsbs-backdrop] {
                                                        opacity: 1;
                                                        opacity: var(--rsbs-backdrop-opacity, 1);
                                                    }

                                                    [data-rsbs-state="closed"],[data-rsbs-state="closing"] {
                                                        pointer-events: none;
                                                    }
                                                

                                                .css-sda488 {
                                                    display: -webkit-box;
                                                    display: -webkit-flex;
                                                    display: -ms-flexbox;
                                                    display: flex;
                                                    height: 440px;
                                                    width: 100%;
                                                    -webkit-flex-direction: column;
                                                    -ms-flex-direction: column;
                                                    flex-direction: column;
                                                    overflow: hidden;
                                                    border-radius: var(--borderRadius-sm);
                                                    border-width: 1px;
                                                    --tw-border-opacity: 1;
                                                    border-color: rgb(var(--borderColor-main) / var(--tw-border-opacity));
                                                }

                                                .css-sda488 >:not([hidden])~:not([hidden]) {
                                                    --tw-space-y-reverse: 0;
                                                    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
                                                    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
                                                }

                                                .css-sda488:is([data-theme="dark"] *) {
                                                    --tw-border-opacity: 1;
                                                    border-color: rgb(var(--borderColor-dark-main) / var(--tw-border-opacity));
                                                }
                                            

                                                    .css-104x08q {
                                                        padding-left: 1rem;
                                                        padding-right: 1rem;
                                                        padding-top: 1rem;
                                                    }
                                                

                                                        .css-znojfv {
                                                            position: relative;
                                                            display: -webkit-box;
                                                            display: -webkit-flex;
                                                            display: -ms-flexbox;
                                                            display: flex;
                                                            gap: 0.5rem;
                                                            gap: 1.5rem;
                                                            border-bottom-width: 1px;
                                                            --tw-border-opacity: 1;
                                                            border-bottom-color: rgb(var(--colors-grey-100) / var(--tw-border-opacity));
                                                        }

                                                        .css-znojfv:is([data-theme="dark"] *) {
                                                            --tw-border-opacity: 1;
                                                            border-bottom-color: rgb(var(--colors-dark-grey-100) / var(--tw-border-opacity));
                                                        }
                                                    

                                                            .css-1r3mth {
                                                                position: relative;
                                                                cursor: pointer;
                                                                padding-bottom: 0.25rem;
                                                                display: -webkit-box;
                                                                display: -webkit-flex;
                                                                display: -ms-flexbox;
                                                                display: flex;
                                                                -webkit-align-items: center;
                                                                -webkit-box-align: center;
                                                                -ms-flex-align: center;
                                                                align-items: center;
                                                                --tw-text-opacity: 1;
                                                                color: rgb(var(--textColor-main) / var(--tw-text-opacity));
                                                            }

                                                            .css-1r3mth:is([data-theme="dark"] *) {
                                                                --tw-text-opacity: 1;
                                                                color: rgb(var(--textColor-dark-main) / var(--tw-text-opacity));
                                                            }
                                                        

                                                                .css-1tf6jmc {
                                                                    --tw-text-opacity: 1;
                                                                    color: rgb(var(--textColor-main) / var(--tw-text-opacity));
                                                                    font-size: 16px;
                                                                    line-height: 24px;
                                                                    letter-spacing: -0.15px;
                                                                    font-weight: 600;
                                                                }

                                                                .css-1tf6jmc:is([data-theme="dark"] *) {
                                                                    --tw-text-opacity: 1;
                                                                    color: rgb(var(--textColor-dark-main) / var(--tw-text-opacity));
                                                                }
                                                            

                                                                .css-g8f50d {
                                                                    position: absolute;
                                                                    left: 0px;
                                                                    top: 0px;
                                                                    height: 100%;
                                                                    width: 100%;
                                                                }

                                                                .css-g8f50d:after {
                                                                    content: " ";
                                                                    position: absolute;
                                                                    bottom: 0px;
                                                                    left: 0px;
                                                                    height: 0.125rem;
                                                                    width: 100%;
                                                                    --tw-bg-opacity: 1;
                                                                    background-color: rgb(var(--colors-primary) / var(--tw-bg-opacity));
                                                                }
                                                            

                                                                .css-l5snd {
                                                                    --tw-text-opacity: 1;
                                                                    color: rgb(var(--colors-primary) / var(--tw-text-opacity));
                                                                    font-size: 16px;
                                                                    line-height: 24px;
                                                                    letter-spacing: -0.2px;
                                                                    font-weight: 700;
                                                                }

                                                                .css-l5snd:is([data-theme="dark"] *) {
                                                                    --tw-text-opacity: 1;
                                                                    color: rgb(var(--colors-dark-primary) / var(--tw-text-opacity));
                                                                }
                                                            

                                                    .css-1wsy2c8 {
                                                        padding-left: 1rem;
                                                        padding-right: 1rem;
                                                    }
                                                

                                                        .css-9jd3qh {
                                                            padding: 0px;
                                                        }

                                                        .css-9jd3qh input {
                                                            font-size: 12px;
                                                            line-height: 16px;
                                                            letter-spacing: 0.1px;
                                                            z-index: 10;
                                                            padding-left: 3rem;
                                                        }

                                                        .css-9jd3qh .reku-input-prefix {
                                                            position: absolute;
                                                            padding-left: 1rem;
                                                        }
                                                    

                                                        .css-k6h3y7 {
                                                            display: -webkit-box;
                                                            display: -webkit-flex;
                                                            display: -ms-flexbox;
                                                            display: flex;
                                                            -webkit-flex-direction: column;
                                                            -ms-flex-direction: column;
                                                            flex-direction: column;
                                                            gap: 5px;
                                                        }
                                                    

                                                            .css-2t18mx {
                                                                display: -webkit-box;
                                                                display: -webkit-flex;
                                                                display: -ms-flexbox;
                                                                display: flex;
                                                                gap: 0.75rem;
                                                            }
                                                        

                                                                .css-1o7xa6a {
                                                                    display: -webkit-box;
                                                                    display: -webkit-flex;
                                                                    display: -ms-flexbox;
                                                                    display: flex;
                                                                    width: 100%;
                                                                    -webkit-align-items: center;
                                                                    -webkit-box-align: center;
                                                                    -ms-flex-align: center;
                                                                    align-items: center;
                                                                    overflow: hidden;
                                                                    border-radius: var(--borderRadius-full);
                                                                    border-width: 1px;
                                                                    height: 40px;
                                                                    padding: 0.75rem;
                                                                    font-size: 12px;
                                                                    --tw-border-opacity: 1;
                                                                    border-color: rgb(var(--borderColor-main) / var(--tw-border-opacity));
                                                                    -webkit-transition: border 0.1s ease;
                                                                    transition: border 0.1s ease;
                                                                    padding: 0px;
                                                                }

                                                                .css-1o7xa6a:is([data-theme="dark"] *) {
                                                                    --tw-border-opacity: 1;
                                                                    border-color: rgb(var(--borderColor-dark-main) / var(--tw-border-opacity));
                                                                }

                                                                @media (min-width: 1280px) {
                                                                    .css-1o7xa6a:hover {
                                                                        --tw-border-opacity:1;
                                                                        border-color: rgb(var(--borderColor-focus) / var(--tw-border-opacity));
                                                                    }

                                                                    .css-1o7xa6a:is([data-theme="dark"] *):hover {
                                                                        --tw-border-opacity: 1;
                                                                        border-color: rgb(var(--borderColor-dark-focus) / var(--tw-border-opacity));
                                                                    }
                                                                }

                                                                .css-1o7xa6a input {
                                                                    font-size: 12px;
                                                                    line-height: 16px;
                                                                    letter-spacing: 0.1px;
                                                                    z-index: 10;
                                                                    padding-left: 3rem;
                                                                }

                                                                .css-1o7xa6a .reku-input-prefix {
                                                                    position: absolute;
                                                                    padding-left: 1rem;
                                                                }
                                                            

                                                                    .css-1f8i51r {
                                                                        margin-right: 0.25rem;
                                                                        display: -webkit-box;
                                                                        display: -webkit-flex;
                                                                        display: -ms-flexbox;
                                                                        display: flex;
                                                                    }
                                                                

                                                                        .css-ey9gek {
                                                                            --tw-text-opacity: 1;
                                                                            color: rgb(var(--colors-icon-disabled) / var(--tw-text-opacity));
                                                                        }

                                                                        .css-ey9gek:is([data-theme="dark"] *) {
                                                                            --tw-text-opacity: 1;
                                                                            color: rgb(var(--colors-dark-icon-disabled) / var(--tw-text-opacity));
                                                                        }
                                                                    

                                                                    .css-m3amcw {
                                                                        width: 100%;
                                                                        border-style: none;
                                                                        background-color: rgb(var(--backgroundColor-transparent) / 0);
                                                                        --tw-text-opacity: 1;
                                                                        color: rgb(var(--textColor-bold) / var(--tw-text-opacity));
                                                                        outline: 2px solid transparent!important;
                                                                        outline-offset: 2px!important;
                                                                        -webkit-flex: 1;
                                                                        -ms-flex: 1;
                                                                        flex: 1;
                                                                        height: -webkit-fill-available;
                                                                    }

                                                                    .css-m3amcw:is([data-theme="dark"] *) {
                                                                        --tw-text-opacity: 1;
                                                                        color: rgb(var(--textColor-dark-bold) / var(--tw-text-opacity));
                                                                    }

                                                                    .css-m3amcw:hover {
                                                                        --tw-border-opacity: 1;
                                                                        border-color: rgb(var(--colors-primary) / var(--tw-border-opacity));
                                                                    }

                                                                    .css-m3amcw:focus {
                                                                        caret-color: rgb(var(--colors-grey-700) / 1)!important;
                                                                    }

                                                                    .css-m3amcw:is([data-theme="dark"] *):focus {
                                                                        caret-color: rgb(var(--colors-dark-grey-700) / 1)!important;
                                                                    }

                                                                    .css-m3amcw:-webkit-autofill,.css-m3amcw:-webkit-autofill:focus {
                                                                        -webkit-transition: background-color 600000s 0s,color 600000s 0s;
                                                                        transition: background-color 600000s 0s,color 600000s 0s;
                                                                    }

                                                                    .css-m3amcw[data-autocompleted] {
                                                                        background-color: transparent!important;
                                                                    }
                                                                

                                                    .css-13udsys {
                                                        height: 100%;
                                                    }
                                                

                                                        .css-1sbvlkw {
                                                            display: -webkit-box;
                                                            display: -webkit-flex;
                                                            display: -ms-flexbox;
                                                            display: flex;
                                                            height: 100%;
                                                            -webkit-flex-direction: column;
                                                            -ms-flex-direction: column;
                                                            flex-direction: column;
                                                        }
                                                    

                                                            .css-1b4evln {
                                                                font-size: 12px;
                                                                line-height: 16px;
                                                                letter-spacing: 0px;
                                                                font-weight: 600;
                                                                display: -webkit-box;
                                                                display: -webkit-flex;
                                                                display: -ms-flexbox;
                                                                display: flex;
                                                                min-height: 29px;
                                                                -webkit-align-items: center;
                                                                -webkit-box-align: center;
                                                                -ms-flex-align: center;
                                                                align-items: center;
                                                                gap: 0.25rem;
                                                                padding-left: 1rem;
                                                                padding-right: 1rem;
                                                                padding-top: 0.5rem;
                                                                padding-bottom: 0.5rem;
                                                                --tw-text-opacity: 1;
                                                                color: rgb(var(--textColor-main) / var(--tw-text-opacity));
                                                            }

                                                            .css-1b4evln:is([data-theme="dark"] *) {
                                                                --tw-text-opacity: 1;
                                                                color: rgb(var(--textColor-dark-main) / var(--tw-text-opacity));
                                                            }
                                                        

                                                                .css-155p5ll {
                                                                    display: -webkit-box;
                                                                    display: -webkit-flex;
                                                                    display: -ms-flexbox;
                                                                    display: flex;
                                                                    -webkit-align-items: center;
                                                                    -webkit-box-align: center;
                                                                    -ms-flex-align: center;
                                                                    align-items: center;
                                                                    width: 0px;
                                                                    -webkit-flex: 1 1 0px;
                                                                    -ms-flex: 1 1 0px;
                                                                    flex: 1 1 0px;
                                                                    -webkit-box-pack: start;
                                                                    -ms-flex-pack: start;
                                                                    -webkit-justify-content: flex-start;
                                                                    justify-content: flex-start;
                                                                }
                                                            

                                                                    .css-12okdp4 {
                                                                        display: -webkit-box;
                                                                        display: -webkit-flex;
                                                                        display: -ms-flexbox;
                                                                        display: flex;
                                                                        -webkit-align-items: center;
                                                                        -webkit-box-align: center;
                                                                        -ms-flex-align: center;
                                                                        align-items: center;
                                                                        gap: 0.125rem;
                                                                        padding-top: 0.125rem;
                                                                        padding-bottom: 0.125rem;
                                                                    }
                                                                

                                                                .css-evkm8q {
                                                                    display: -webkit-box;
                                                                    display: -webkit-flex;
                                                                    display: -ms-flexbox;
                                                                    display: flex;
                                                                    -webkit-align-items: center;
                                                                    -webkit-box-align: center;
                                                                    -ms-flex-align: center;
                                                                    align-items: center;
                                                                    width: 0px;
                                                                    -webkit-flex: 3 1 0px;
                                                                    -ms-flex: 3 1 0px;
                                                                    flex: 3 1 0px;
                                                                    -webkit-box-pack: start;
                                                                    -ms-flex-pack: start;
                                                                    -webkit-justify-content: flex-start;
                                                                    justify-content: flex-start;
                                                                }
                                                            

                                                                    .css-1miek3y {
                                                                        display: -webkit-box;
                                                                        display: -webkit-flex;
                                                                        display: -ms-flexbox;
                                                                        display: flex;
                                                                        -webkit-align-items: center;
                                                                        -webkit-box-align: center;
                                                                        -ms-flex-align: center;
                                                                        align-items: center;
                                                                        gap: 0.125rem;
                                                                        padding-top: 0.125rem;
                                                                        padding-bottom: 0.125rem;
                                                                        cursor: pointer;
                                                                        padding-left: 0.25rem;
                                                                        padding-right: 0.25rem;
                                                                    }
                                                                

                                                                .css-pz1rk8 {
                                                                    display: -webkit-box;
                                                                    display: -webkit-flex;
                                                                    display: -ms-flexbox;
                                                                    display: flex;
                                                                    -webkit-align-items: center;
                                                                    -webkit-box-align: center;
                                                                    -ms-flex-align: center;
                                                                    align-items: center;
                                                                    width: 0px;
                                                                    -webkit-flex: 3 1 0px;
                                                                    -ms-flex: 3 1 0px;
                                                                    flex: 3 1 0px;
                                                                    text-align: right;
                                                                    -webkit-box-pack: end;
                                                                    -ms-flex-pack: end;
                                                                    -webkit-justify-content: flex-end;
                                                                    justify-content: flex-end;
                                                                }
                                                            

                                                                .css-gmuyey {
                                                                    display: -webkit-box;
                                                                    display: -webkit-flex;
                                                                    display: -ms-flexbox;
                                                                    display: flex;
                                                                    -webkit-align-items: center;
                                                                    -webkit-box-align: center;
                                                                    -ms-flex-align: center;
                                                                    align-items: center;
                                                                    width: 0px;
                                                                    -webkit-flex: 2 1 0px;
                                                                    -ms-flex: 2 1 0px;
                                                                    flex: 2 1 0px;
                                                                    text-align: right;
                                                                    -webkit-box-pack: end;
                                                                    -ms-flex-pack: end;
                                                                    -webkit-justify-content: flex-end;
                                                                    justify-content: flex-end;
                                                                }
                                                            

                                                                    .css-1knqwy6 {
                                                                        scrollbar-width: auto;
                                                                        scrollbar-color: var(--scrollbar-thumb, initial) var(--scrollbar-track, initial);
                                                                        --scrollbar-thumb: rgb(var(--colors-background-strong) / 1);
                                                                        --scrollbar-track: rgb(var(--colors-transparent) / 0);
                                                                        --scrollbar-width: 5px;
                                                                        --scrollbar-height: 6px;
                                                                    }

                                                                    .css-1knqwy6::-webkit-scrollbar-track {
                                                                        background-color: var(--scrollbar-track);
                                                                        border-radius: var(--scrollbar-track-radius);
                                                                    }

                                                                    .css-1knqwy6::-webkit-scrollbar-track:hover {
                                                                        background-color: var(--scrollbar-track-hover, var(--scrollbar-track));
                                                                    }

                                                                    .css-1knqwy6::-webkit-scrollbar-track:active {
                                                                        background-color: var(--scrollbar-track-active, var(--scrollbar-track-hover, var(--scrollbar-track)));
                                                                    }

                                                                    .css-1knqwy6::-webkit-scrollbar-thumb {
                                                                        background-color: var(--scrollbar-thumb);
                                                                        border-radius: var(--scrollbar-thumb-radius);
                                                                    }

                                                                    .css-1knqwy6::-webkit-scrollbar-thumb:hover {
                                                                        background-color: var(--scrollbar-thumb-hover, var(--scrollbar-thumb));
                                                                    }

                                                                    .css-1knqwy6::-webkit-scrollbar-thumb:active {
                                                                        background-color: var(--scrollbar-thumb-active, var(--scrollbar-thumb-hover, var(--scrollbar-thumb)));
                                                                    }

                                                                    .css-1knqwy6::-webkit-scrollbar-corner {
                                                                        background-color: var(--scrollbar-corner);
                                                                        border-radius: var(--scrollbar-corner-radius);
                                                                    }

                                                                    .css-1knqwy6::-webkit-scrollbar-corner:hover {
                                                                        background-color: var(--scrollbar-corner-hover, var(--scrollbar-corner));
                                                                    }

                                                                    .css-1knqwy6::-webkit-scrollbar-corner:active {
                                                                        background-color: var(--scrollbar-corner-active, var(--scrollbar-corner-hover, var(--scrollbar-corner)));
                                                                    }

                                                                    .css-1knqwy6::-webkit-scrollbar {
                                                                        display: block;
                                                                        width: var(--scrollbar-width, 16px);
                                                                        height: var(--scrollbar-height, 16px);
                                                                    }

                                                                    .css-1knqwy6:is([data-theme="dark"] *) {
                                                                        --scrollbar-thumb: rgb(var(--colors-dark-background-strong) / 1);
                                                                    }
                                                                

                                                                                .css-p74clm {
                                                                                    box-sizing: border-box;
                                                                                    display: -webkit-box;
                                                                                    display: -webkit-flex;
                                                                                    display: -ms-flexbox;
                                                                                    display: flex;
                                                                                    height: 100%;
                                                                                    width: 100%;
                                                                                    -webkit-align-items: center;
                                                                                    -webkit-box-align: center;
                                                                                    -ms-flex-align: center;
                                                                                    align-items: center;
                                                                                    gap: 0.25rem;
                                                                                    padding-left: 1rem;
                                                                                    padding-right: 1rem;
                                                                                    padding-right: 0.75rem!important;
                                                                                }
                                                                            

                                                                                        .css-oif990 {
                                                                                            display: -webkit-box;
                                                                                            display: -webkit-flex;
                                                                                            display: -ms-flexbox;
                                                                                            display: flex;
                                                                                            -webkit-flex-direction: row;
                                                                                            -ms-flex-direction: row;
                                                                                            flex-direction: row;
                                                                                            -webkit-align-items: center;
                                                                                            -webkit-box-align: center;
                                                                                            -ms-flex-align: center;
                                                                                            align-items: center;
                                                                                            gap: 0.5rem;
                                                                                        }
                                                                                    

                                                                                                .css-1bvbq5j {
                                                                                                    --tw-text-opacity: 1;
                                                                                                    color: rgb(var(--colors-primary) / var(--tw-text-opacity));
                                                                                                }

                                                                                                .css-1bvbq5j:is([data-theme="dark"] *) {
                                                                                                    --tw-text-opacity: 1;
                                                                                                    color: rgb(var(--colors-dark-primary) / var(--tw-text-opacity));
                                                                                                }
                                                                                            

                                                                                            .css-1laswiy {
                                                                                                gap: 0.25rem;
                                                                                            }
                                                                                        

                                                                                            .css-h8y124 {
                                                                                                display: -webkit-inline-box;
                                                                                                display: -webkit-inline-flex;
                                                                                                display: -ms-inline-flexbox;
                                                                                                display: inline-flex;
                                                                                                width: auto;
                                                                                                -webkit-flex-direction: row;
                                                                                                -ms-flex-direction: row;
                                                                                                flex-direction: row;
                                                                                                -webkit-align-items: center;
                                                                                                -webkit-box-align: center;
                                                                                                -ms-flex-align: center;
                                                                                                align-items: center;
                                                                                                -webkit-box-pack: unset;
                                                                                                -ms-flex-pack: unset;
                                                                                                -webkit-justify-content: unset;
                                                                                                justify-content: unset;
                                                                                                gap: 8px;
                                                                                                gap: 0.25rem;
                                                                                            }
                                                                                        

                                                                                                .css-1xunwgy {
                                                                                                    -webkit-align-items: flex-start;
                                                                                                    -webkit-box-align: flex-start;
                                                                                                    -ms-flex-align: flex-start;
                                                                                                    align-items: flex-start;
                                                                                                    gap: 0px;
                                                                                                }
                                                                                            

                                                                                                .css-lcjqpa {
                                                                                                    display: -webkit-inline-box;
                                                                                                    display: -webkit-inline-flex;
                                                                                                    display: -ms-inline-flexbox;
                                                                                                    display: inline-flex;
                                                                                                    width: auto;
                                                                                                    -webkit-flex-direction: column;
                                                                                                    -ms-flex-direction: column;
                                                                                                    flex-direction: column;
                                                                                                    -webkit-align-items: unset;
                                                                                                    -webkit-box-align: unset;
                                                                                                    -ms-flex-align: unset;
                                                                                                    align-items: unset;
                                                                                                    -webkit-box-pack: unset;
                                                                                                    -ms-flex-pack: unset;
                                                                                                    -webkit-justify-content: unset;
                                                                                                    justify-content: unset;
                                                                                                    gap: 8px;
                                                                                                    -webkit-align-items: flex-start;
                                                                                                    -webkit-box-align: flex-start;
                                                                                                    -ms-flex-align: flex-start;
                                                                                                    align-items: flex-start;
                                                                                                    gap: 0px;
                                                                                                }
                                                                                            

                                                                                                    .css-102mtiz {
                                                                                                        display: -webkit-box;
                                                                                                        display: -webkit-flex;
                                                                                                        display: -ms-flexbox;
                                                                                                        display: flex;
                                                                                                        width: 100%;
                                                                                                        -webkit-flex-direction: row;
                                                                                                        -ms-flex-direction: row;
                                                                                                        flex-direction: row;
                                                                                                        -webkit-align-items: center;
                                                                                                        -webkit-box-align: center;
                                                                                                        -ms-flex-align: center;
                                                                                                        align-items: center;
                                                                                                        gap: 0.25rem;
                                                                                                    }
                                                                                                

                                                                                                        .css-eq7f8j {
                                                                                                            display: -webkit-box;
                                                                                                            display: -webkit-flex;
                                                                                                            display: -ms-flexbox;
                                                                                                            display: flex;
                                                                                                            -webkit-align-items: flex-end;
                                                                                                            -webkit-box-align: flex-end;
                                                                                                            -ms-flex-align: flex-end;
                                                                                                            align-items: flex-end;
                                                                                                        }
                                                                                                    

                                                                                                            .css-18s3p6a {
                                                                                                                font-size: 12px;
                                                                                                                line-height: 16px;
                                                                                                                letter-spacing: 0px;
                                                                                                                font-weight: 600;
                                                                                                                --tw-text-opacity: 1;
                                                                                                                color: rgb(var(--textColor-main) / var(--tw-text-opacity));
                                                                                                            }

                                                                                                            .css-18s3p6a:is([data-theme="dark"] *) {
                                                                                                                --tw-text-opacity: 1;
                                                                                                                color: rgb(var(--textColor-dark-main) / var(--tw-text-opacity));
                                                                                                            }
                                                                                                        

                                                                                                            .css-149rphx {
                                                                                                                font-size: 10px;
                                                                                                                line-height: 14px;
                                                                                                                letter-spacing: 0.1px;
                                                                                                                --tw-text-opacity: 1;
                                                                                                                color: rgb(var(--textColor-additional) / var(--tw-text-opacity));
                                                                                                            }

                                                                                                            .css-149rphx:is([data-theme="dark"] *) {
                                                                                                                --tw-text-opacity: 1;
                                                                                                                color: rgb(var(--textColor-dark-additional) / var(--tw-text-opacity));
                                                                                                            }
                                                                                                        

                                                                                        .css-qs3r0n {
                                                                                            font-weight: 500!important;
                                                                                        }
                                                                                    

                                                                                        .css-bnlq06 {
                                                                                            font-size: 12px;
                                                                                            line-height: 16px;
                                                                                            letter-spacing: 0px;
                                                                                            font-weight: 600;
                                                                                            --tw-text-opacity: 1;
                                                                                            color: rgb(var(--textColor-main) / var(--tw-text-opacity));
                                                                                            font-weight: 500!important;
                                                                                        }

                                                                                        .css-bnlq06:is([data-theme="dark"] *) {
                                                                                            --tw-text-opacity: 1;
                                                                                            color: rgb(var(--textColor-dark-main) / var(--tw-text-opacity));
                                                                                        }
                                                                                    

                                                                                                .css-v1r0gb {
                                                                                                    --tw-text-opacity: 1;
                                                                                                    color: rgb(var(--colors-icon-contrast) / var(--tw-text-opacity));
                                                                                                }

                                                                                                .css-v1r0gb:is([data-theme="dark"] *) {
                                                                                                    --tw-text-opacity: 1;
                                                                                                    color: rgb(var(--colors-dark-icon-contrast) / var(--tw-text-opacity));
                                                                                                }
                                                                                            

                                                                                                        .css-11y7q0g {
                                                                                                            display: -webkit-box;
                                                                                                            display: -webkit-flex;
                                                                                                            display: -ms-flexbox;
                                                                                                            display: flex;
                                                                                                            width: -webkit-fit-content;
                                                                                                            width: -moz-fit-content;
                                                                                                            width: fit-content;
                                                                                                            -webkit-align-items: center;
                                                                                                            -webkit-box-align: center;
                                                                                                            -ms-flex-align: center;
                                                                                                            align-items: center;
                                                                                                            -webkit-box-pack: center;
                                                                                                            -ms-flex-pack: center;
                                                                                                            -webkit-justify-content: center;
                                                                                                            justify-content: center;
                                                                                                            border-radius: var(--borderRadius-2xl);
                                                                                                            font-weight: 600;
                                                                                                            --tw-bg-opacity: 1;
                                                                                                            background-color: rgb(var(--colors-foreground-green) / var(--tw-bg-opacity));
                                                                                                            --tw-text-opacity: 1;
                                                                                                            color: rgb(var(--colors-success) / var(--tw-text-opacity));
                                                                                                            gap: 0.125rem;
                                                                                                            padding-left: 0.5rem;
                                                                                                            padding-right: 0.5rem;
                                                                                                            padding-top: 3px;
                                                                                                            padding-bottom: 3px;
                                                                                                            font-size: 10px;
                                                                                                            line-height: 14px;
                                                                                                        }

                                                                                                        .css-11y7q0g:is([data-theme="dark"] *) {
                                                                                                            --tw-bg-opacity: 1;
                                                                                                            background-color: rgb(var(--colors-dark-foreground-green) / var(--tw-bg-opacity));
                                                                                                            --tw-text-opacity: 1;
                                                                                                            color: rgb(var(--colors-dark-success) / var(--tw-text-opacity));
                                                                                                        }
                                                                                                    

                                                                                                .css-13h4vs3 {
                                                                                                    font-size: 12px;
                                                                                                    line-height: 16px;
                                                                                                    letter-spacing: 0px;
                                                                                                    font-weight: 600;
                                                                                                    --tw-text-opacity: 1;
                                                                                                    color: rgb(var(--colors-success) / var(--tw-text-opacity));
                                                                                                }

                                                                                                .css-13h4vs3:is([data-theme="dark"] *) {
                                                                                                    --tw-text-opacity: 1;
                                                                                                    color: rgb(var(--colors-dark-success) / var(--tw-text-opacity));
                                                                                                }
                                                                                            

                                                .css-18k01p5 {
                                                    display: -webkit-box;
                                                    display: -webkit-flex;
                                                    display: -ms-flexbox;
                                                    display: flex;
                                                    -webkit-flex-direction: column;
                                                    -ms-flex-direction: column;
                                                    flex-direction: column;
                                                    gap: 0.5rem;
                                                    overflow: hidden;
                                                    border-radius: var(--borderRadius-sm);
                                                }

                                                @media (min-width: 1280px) {
                                                    .css-18k01p5 {
                                                        height:437px;
                                                        width: 272px;
                                                        border-width: 1px;
                                                        --tw-border-opacity: 1;
                                                        border-color: rgb(var(--borderColor-main) / var(--tw-border-opacity));
                                                    }

                                                    .css-18k01p5:is([data-theme="dark"] *) {
                                                        --tw-border-opacity: 1;
                                                        border-color: rgb(var(--borderColor-dark-main) / var(--tw-border-opacity));
                                                    }
                                                }
                                            

                                                    .css-77o69c {
                                                        display: none;
                                                        -webkit-flex-direction: column;
                                                        -ms-flex-direction: column;
                                                        flex-direction: column;
                                                        padding-left: 1rem;
                                                        padding-right: 1rem;
                                                        padding-top: 0.75rem;
                                                    }

                                                    @media (min-width: 1280px) {
                                                        .css-77o69c {
                                                            display:-webkit-box;
                                                            display: -webkit-flex;
                                                            display: -ms-flexbox;
                                                            display: flex;
                                                        }
                                                    }
                                                

                                                            .css-a5b0yn {
                                                                display: -webkit-box;
                                                                display: -webkit-flex;
                                                                display: -ms-flexbox;
                                                                display: flex;
                                                                -webkit-align-items: center;
                                                                -webkit-box-align: center;
                                                                -ms-flex-align: center;
                                                                align-items: center;
                                                                width: 0px;
                                                                -webkit-flex: 1 1 0px;
                                                                -ms-flex: 1 1 0px;
                                                                flex: 1 1 0px;
                                                                text-align: left;
                                                                -webkit-box-pack: start;
                                                                -ms-flex-pack: start;
                                                                -webkit-justify-content: flex-start;
                                                                justify-content: flex-start;
                                                            }
                                                        

                                                            .css-1kht0wz {
                                                                display: -webkit-box;
                                                                display: -webkit-flex;
                                                                display: -ms-flexbox;
                                                                display: flex;
                                                                -webkit-align-items: center;
                                                                -webkit-box-align: center;
                                                                -ms-flex-align: center;
                                                                align-items: center;
                                                                -webkit-box-pack: center;
                                                                -ms-flex-pack: center;
                                                                -webkit-justify-content: center;
                                                                justify-content: center;
                                                                -webkit-flex-direction: column;
                                                                -ms-flex-direction: column;
                                                                flex-direction: column;
                                                                padding: 50px 20px;
                                                            }
                                                        

                                                                .css-1azakc {
                                                                    text-align: center;
                                                                }
                                                            

                                        .css-zb6k0m {
                                            display: -webkit-box;
                                            display: -webkit-flex;
                                            display: -ms-flexbox;
                                            display: flex;
                                            width: calc(100% - 576px);
                                            -webkit-flex-direction: column;
                                            -ms-flex-direction: column;
                                            flex-direction: column;
                                            gap: 1rem;
                                        }
                                    

                                            .css-4tbftr {
                                                display: -webkit-box;
                                                display: -webkit-flex;
                                                display: -ms-flexbox;
                                                display: flex;
                                                -webkit-flex-direction: column;
                                                -ms-flex-direction: column;
                                                flex-direction: column;
                                                gap: 1.5rem;
                                            }

                                            @media (max-width: 1300px) {
                                                .css-4tbftr {
                                                    gap:0.5rem;
                                                }
                                            }

                                            @media (max-width: 1279px) {
                                                .css-4tbftr {
                                                    width:100vw;
                                                }
                                            }

                                            @media (min-width: 1280px) {
                                                .css-4tbftr {
                                                    height:fit-content;
                                                }
                                            }
                                        

                                                .css-1773uaj {
                                                    display: -webkit-box;
                                                    display: -webkit-flex;
                                                    display: -ms-flexbox;
                                                    display: flex;
                                                    height: 100%;
                                                    -webkit-flex-direction: column;
                                                    -ms-flex-direction: column;
                                                    flex-direction: column;
                                                    border-radius: var(--borderRadius-sm);
                                                    --tw-border-opacity: 1;
                                                    border-color: rgb(var(--borderColor-main) / var(--tw-border-opacity));
                                                    --tw-bg-opacity: 1;
                                                    background-color: rgb(var(--colors-background) / var(--tw-bg-opacity));
                                                }

                                                .css-1773uaj:is([data-theme="dark"] *) {
                                                    --tw-border-opacity: 1;
                                                    border-color: rgb(var(--borderColor-dark-main) / var(--tw-border-opacity));
                                                    --tw-bg-opacity: 1;
                                                    background-color: rgb(var(--colors-dark-background) / var(--tw-bg-opacity));
                                                }

                                                @media (min-width: 1280px) {
                                                    .css-1773uaj {
                                                        border-width:1px;
                                                    }
                                                }
                                            

                                                    .css-1eoy87d {
                                                        display: -webkit-box;
                                                        display: -webkit-flex;
                                                        display: -ms-flexbox;
                                                        display: flex;
                                                        -webkit-box-pack: justify;
                                                        -webkit-justify-content: space-between;
                                                        justify-content: space-between;
                                                    }
                                                

                                                        .css-u1lagd {
                                                            display: -webkit-box;
                                                            display: -webkit-flex;
                                                            display: -ms-flexbox;
                                                            display: flex;
                                                            height: -webkit-fit-content;
                                                            height: -moz-fit-content;
                                                            height: fit-content;
                                                            gap: 0.5rem;
                                                            padding: 1rem;
                                                            padding-bottom: 0.5rem;
                                                        }
                                                    

                                                                .css-8k1832 {
                                                                    position: relative;
                                                                    display: -webkit-box;
                                                                    display: -webkit-flex;
                                                                    display: -ms-flexbox;
                                                                    display: flex;
                                                                }
                                                            

                                                                    .css-w3f7al {
                                                                        height: 22px;
                                                                    }
                                                                

                                                                    .css-anmro7 {
                                                                        display: -webkit-inline-box;
                                                                        display: -webkit-inline-flex;
                                                                        display: -ms-inline-flexbox;
                                                                        display: inline-flex;
                                                                        cursor: pointer;
                                                                        -webkit-align-items: center;
                                                                        -webkit-box-align: center;
                                                                        -ms-flex-align: center;
                                                                        align-items: center;
                                                                        -webkit-box-pack: center;
                                                                        -ms-flex-pack: center;
                                                                        -webkit-justify-content: center;
                                                                        justify-content: center;
                                                                        gap: 2px;
                                                                        border-radius: var(--borderRadius-full);
                                                                        border-width: 1px;
                                                                        --tw-border-opacity: 1;
                                                                        border-color: rgb(var(--borderColor-main) / var(--tw-border-opacity));
                                                                        --tw-bg-opacity: 1;
                                                                        background-color: rgb(var(--colors-background) / var(--tw-bg-opacity));
                                                                        --tw-text-opacity: 1;
                                                                        color: rgb(var(--textColor-main) / var(--tw-text-opacity));
                                                                        --tw-border-opacity: 1;
                                                                        border-color: rgb(var(--borderColor-focus) / var(--tw-border-opacity));
                                                                        --tw-bg-opacity: 1;
                                                                        background-color: rgb(var(--colors-foreground-teal) / var(--tw-bg-opacity));
                                                                        --tw-text-opacity: 1;
                                                                        color: rgb(var(--colors-primary) / var(--tw-text-opacity));
                                                                        padding-left: 0.375rem;
                                                                        padding-right: 0.375rem;
                                                                        padding-top: 0.25rem;
                                                                        padding-bottom: 0.25rem;
                                                                        font-size: 10px;
                                                                        line-height: 14px;
                                                                        letter-spacing: 0.15px;
                                                                        font-weight: 700;
                                                                        height: 22px;
                                                                    }

                                                                    .css-anmro7:is([data-theme="dark"] *) {
                                                                        --tw-border-opacity: 1;
                                                                        border-color: rgb(var(--borderColor-dark-main) / var(--tw-border-opacity));
                                                                        --tw-bg-opacity: 1;
                                                                        background-color: rgb(var(--colors-dark-background) / var(--tw-bg-opacity));
                                                                        --tw-text-opacity: 1;
                                                                        color: rgb(var(--textColor-dark-main) / var(--tw-text-opacity));
                                                                    }

                                                                    .css-anmro7:is([data-theme="dark"] *) {
                                                                        --tw-border-opacity: 1;
                                                                        border-color: rgb(var(--borderColor-dark-focus) / var(--tw-border-opacity));
                                                                        --tw-bg-opacity: 1;
                                                                        background-color: rgb(var(--colors-dark-foreground-teal) / var(--tw-bg-opacity));
                                                                        --tw-text-opacity: 1;
                                                                        color: rgb(var(--colors-dark-primary) / var(--tw-text-opacity));
                                                                    }
                                                                

                                                                        .css-1f59i4k {
                                                                            transition-property: all;
                                                                            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
                                                                            transition-duration: 300ms;
                                                                        }
                                                                    

                                                            .css-t1mw5p {
                                                                display: -webkit-inline-box;
                                                                display: -webkit-inline-flex;
                                                                display: -ms-inline-flexbox;
                                                                display: inline-flex;
                                                                cursor: pointer;
                                                                -webkit-align-items: center;
                                                                -webkit-box-align: center;
                                                                -ms-flex-align: center;
                                                                align-items: center;
                                                                -webkit-box-pack: center;
                                                                -ms-flex-pack: center;
                                                                -webkit-justify-content: center;
                                                                justify-content: center;
                                                                gap: 2px;
                                                                border-radius: var(--borderRadius-full);
                                                                border-width: 1px;
                                                                --tw-border-opacity: 1;
                                                                border-color: rgb(var(--borderColor-main) / var(--tw-border-opacity));
                                                                --tw-bg-opacity: 1;
                                                                background-color: rgb(var(--colors-background) / var(--tw-bg-opacity));
                                                                --tw-text-opacity: 1;
                                                                color: rgb(var(--textColor-main) / var(--tw-text-opacity));
                                                                padding-left: 0.375rem;
                                                                padding-right: 0.375rem;
                                                                padding-top: 0.25rem;
                                                                padding-bottom: 0.25rem;
                                                                font-size: 10px;
                                                                line-height: 14px;
                                                                letter-spacing: 0.1px;
                                                                height: 22px;
                                                            }

                                                            .css-t1mw5p:is([data-theme="dark"] *) {
                                                                --tw-border-opacity: 1;
                                                                border-color: rgb(var(--borderColor-dark-main) / var(--tw-border-opacity));
                                                                --tw-bg-opacity: 1;
                                                                background-color: rgb(var(--colors-dark-background) / var(--tw-bg-opacity));
                                                                --tw-text-opacity: 1;
                                                                color: rgb(var(--textColor-dark-main) / var(--tw-text-opacity));
                                                            }
                                                        

                                                                    .css-zjik7 {
                                                                        display: -webkit-box;
                                                                        display: -webkit-flex;
                                                                        display: -ms-flexbox;
                                                                        display: flex;
                                                                    }
                                                                

                                                            .css-135xkt0 {
                                                                position: relative;
                                                                display: -webkit-box;
                                                                display: -webkit-flex;
                                                                display: -ms-flexbox;
                                                                display: flex;
                                                                gap: 0.5rem;
                                                            }
                                                        

                                                                .css-1ravq6y {
                                                                    position: relative;
                                                                    cursor: pointer;
                                                                    padding-bottom: 0.25rem;
                                                                    display: -webkit-box;
                                                                    display: -webkit-flex;
                                                                    display: -ms-flexbox;
                                                                    display: flex;
                                                                    -webkit-align-items: center;
                                                                    -webkit-box-align: center;
                                                                    -ms-flex-align: center;
                                                                    align-items: center;
                                                                    --tw-text-opacity: 1;
                                                                    color: rgb(var(--textColor-main) / var(--tw-text-opacity));
                                                                    border-radius: var(--borderRadius-sm);
                                                                    --tw-bg-opacity: 1;
                                                                    background-color: rgb(var(--colors-background-subtle) / var(--tw-bg-opacity));
                                                                    padding-left: 0.75rem;
                                                                    padding-right: 0.75rem;
                                                                    padding-top: 6px;
                                                                    padding-bottom: 6px;
                                                                    --tw-bg-opacity: 1;
                                                                    background-color: rgb(var(--colors-foreground-teal) / var(--tw-bg-opacity));
                                                                }

                                                                .css-1ravq6y:is([data-theme="dark"] *) {
                                                                    --tw-text-opacity: 1;
                                                                    color: rgb(var(--textColor-dark-main) / var(--tw-text-opacity));
                                                                }

                                                                .css-1ravq6y:is([data-theme="dark"] *) {
                                                                    --tw-bg-opacity: 1;
                                                                    background-color: rgb(var(--colors-dark-background-subtle) / var(--tw-bg-opacity));
                                                                }

                                                                .css-1ravq6y:is([data-theme="dark"] *) {
                                                                    --tw-bg-opacity: 1;
                                                                    background-color: rgb(var(--colors-dark-foreground-teal) / var(--tw-bg-opacity));
                                                                }
                                                            

                                                                    .css-1cpuyhm {
                                                                        --tw-text-opacity: 1;
                                                                        color: rgb(var(--colors-primary) / var(--tw-text-opacity));
                                                                        font-size: 12px;
                                                                        line-height: 16px;
                                                                        letter-spacing: 0px;
                                                                        font-weight: 700;
                                                                    }

                                                                    .css-1cpuyhm:is([data-theme="dark"] *) {
                                                                        --tw-text-opacity: 1;
                                                                        color: rgb(var(--colors-dark-primary) / var(--tw-text-opacity));
                                                                    }
                                                                

                                                                .css-1bbssn6 {
                                                                    position: relative;
                                                                    cursor: pointer;
                                                                    padding-bottom: 0.25rem;
                                                                    display: -webkit-box;
                                                                    display: -webkit-flex;
                                                                    display: -ms-flexbox;
                                                                    display: flex;
                                                                    -webkit-align-items: center;
                                                                    -webkit-box-align: center;
                                                                    -ms-flex-align: center;
                                                                    align-items: center;
                                                                    --tw-text-opacity: 1;
                                                                    color: rgb(var(--textColor-main) / var(--tw-text-opacity));
                                                                    border-radius: var(--borderRadius-sm);
                                                                    --tw-bg-opacity: 1;
                                                                    background-color: rgb(var(--colors-background-subtle) / var(--tw-bg-opacity));
                                                                    padding-left: 0.75rem;
                                                                    padding-right: 0.75rem;
                                                                    padding-top: 6px;
                                                                    padding-bottom: 6px;
                                                                }

                                                                .css-1bbssn6:is([data-theme="dark"] *) {
                                                                    --tw-text-opacity: 1;
                                                                    color: rgb(var(--textColor-dark-main) / var(--tw-text-opacity));
                                                                }

                                                                .css-1bbssn6:is([data-theme="dark"] *) {
                                                                    --tw-bg-opacity: 1;
                                                                    background-color: rgb(var(--colors-dark-background-subtle) / var(--tw-bg-opacity));
                                                                }
                                                            

                                                                    .css-vqd29y {
                                                                        --tw-text-opacity: 1;
                                                                        color: rgb(var(--textColor-main) / var(--tw-text-opacity));
                                                                        font-size: 12px;
                                                                        line-height: 16px;
                                                                        letter-spacing: 0.1px;
                                                                    }

                                                                    .css-vqd29y:is([data-theme="dark"] *) {
                                                                        --tw-text-opacity: 1;
                                                                        color: rgb(var(--textColor-dark-main) / var(--tw-text-opacity));
                                                                    }
                                                                

                                                    .css-p0vbyo {
                                                        position: relative;
                                                        height: 100%;
                                                        width: 100vw;
                                                    }

                                                    @media (min-width: 1280px) {
                                                        .css-p0vbyo {
                                                            width:100%;
                                                        }
                                                    }
                                                

                                                        .css-kxnywx {
                                                            position: absolute;
                                                            z-index: 3;
                                                            display: -webkit-box;
                                                            display: -webkit-flex;
                                                            display: -ms-flexbox;
                                                            display: flex;
                                                            height: 100%;
                                                            width: 100%;
                                                            -webkit-align-items: center;
                                                            -webkit-box-align: center;
                                                            -ms-flex-align: center;
                                                            align-items: center;
                                                            -webkit-box-pack: center;
                                                            -ms-flex-pack: center;
                                                            -webkit-justify-content: center;
                                                            justify-content: center;
                                                            background-color: rgba(255,255,255,0.1);
                                                        }

                                                        .css-kxnywx:is([data-theme="dark"] *) {
                                                            background-color: rgba(0,0,0,0.1);
                                                        }
                                                    

                                                            .css-eh6h94 {
                                                                -webkit-animation: animation-ibxp9g 1s linear infinite;
                                                                animation: animation-ibxp9g 1s linear infinite;
                                                            }

                                                            @-webkit-keyframes animation-ibxp9g {
                                                                0% {
                                                                    -webkit-transform: rotate(0deg);
                                                                    -moz-transform: rotate(0deg);
                                                                    -ms-transform: rotate(0deg);
                                                                    transform: rotate(0deg);
                                                                }

                                                                100% {
                                                                    -webkit-transform: rotate(360deg);
                                                                    -moz-transform: rotate(360deg);
                                                                    -ms-transform: rotate(360deg);
                                                                    transform: rotate(360deg);
                                                                }
                                                            }

                                                            @keyframes animation-ibxp9g {
                                                                0% {
                                                                    -webkit-transform: rotate(0deg);
                                                                    -moz-transform: rotate(0deg);
                                                                    -ms-transform: rotate(0deg);
                                                                    transform: rotate(0deg);
                                                                }

                                                                100% {
                                                                    -webkit-transform: rotate(360deg);
                                                                    -moz-transform: rotate(360deg);
                                                                    -ms-transform: rotate(360deg);
                                                                    transform: rotate(360deg);
                                                                }
                                                            }
                                                        

                                            .css-922caj {
                                                display: -webkit-box;
                                                display: -webkit-flex;
                                                display: -ms-flexbox;
                                                display: flex;
                                                min-height: 437px;
                                                -webkit-flex-direction: column;
                                                -ms-flex-direction: column;
                                                flex-direction: column;
                                                border-radius: var(--borderRadius-sm);
                                                border-width: 1px;
                                                --tw-border-opacity: 1;
                                                border-color: rgb(var(--borderColor-main) / var(--tw-border-opacity));
                                            }

                                            .css-922caj:is([data-theme="dark"] *) {
                                                --tw-border-opacity: 1;
                                                border-color: rgb(var(--borderColor-dark-main) / var(--tw-border-opacity));
                                            }
                                        

                                                .css-1og4gyw {
                                                    height: 100%;
                                                }
                                            

                                                    .css-1iou4xv {
                                                        display: grid;
                                                        height: 100%;
                                                        grid-template-columns: repeat(2, minmax(0, 1fr));
                                                    }
                                                

                                                        .css-t1qslb {
                                                            padding: 1rem;
                                                            border-right-width: 1px;
                                                            --tw-border-opacity: 1;
                                                            border-color: rgb(var(--borderColor-main) / var(--tw-border-opacity));
                                                        }

                                                        .css-t1qslb:is([data-theme="dark"] *) {
                                                            --tw-border-opacity: 1;
                                                            border-color: rgb(var(--borderColor-dark-main) / var(--tw-border-opacity));
                                                        }

                                                        .css-t1qslb:last-of-type {
                                                            border-width: 0px;
                                                        }
                                                    

                                                                .css-ylpfdz {
                                                                    margin-bottom: 1rem;
                                                                    display: -webkit-box;
                                                                    display: -webkit-flex;
                                                                    display: -ms-flexbox;
                                                                    display: flex;
                                                                    height: 2.25rem;
                                                                    -webkit-align-items: center;
                                                                    -webkit-box-align: center;
                                                                    -ms-flex-align: center;
                                                                    align-items: center;
                                                                    -webkit-box-pack: justify;
                                                                    -webkit-justify-content: space-between;
                                                                    justify-content: space-between;
                                                                }
                                                            

                                                                        .css-72ehta {
                                                                            display: -webkit-box;
                                                                            display: -webkit-flex;
                                                                            display: -ms-flexbox;
                                                                            display: flex;
                                                                            width: 100%;
                                                                            -webkit-align-items: center;
                                                                            -webkit-box-align: center;
                                                                            -ms-flex-align: center;
                                                                            align-items: center;
                                                                            overflow: hidden;
                                                                            border-radius: var(--borderRadius-full);
                                                                            border-width: 1px;
                                                                            height: 48px;
                                                                            padding-left: 1.5rem;
                                                                            padding-right: 1.5rem;
                                                                            padding-top: 0.875rem;
                                                                            padding-bottom: 0.875rem;
                                                                            font-size: var(--fontSize-sm-0);
                                                                            line-height: var(--fontSize-sm-1-lineHeight);
                                                                            --tw-border-opacity: 1;
                                                                            border-color: rgb(var(--borderColor-main) / var(--tw-border-opacity));
                                                                            -webkit-transition: border 0.1s ease;
                                                                            transition: border 0.1s ease;
                                                                        }

                                                                        .css-72ehta:is([data-theme="dark"] *) {
                                                                            --tw-border-opacity: 1;
                                                                            border-color: rgb(var(--borderColor-dark-main) / var(--tw-border-opacity));
                                                                        }

                                                                        @media (min-width: 1280px) {
                                                                            .css-72ehta:hover {
                                                                                --tw-border-opacity:1;
                                                                                border-color: rgb(var(--borderColor-focus) / var(--tw-border-opacity));
                                                                            }

                                                                            .css-72ehta:is([data-theme="dark"] *):hover {
                                                                                --tw-border-opacity: 1;
                                                                                border-color: rgb(var(--borderColor-dark-focus) / var(--tw-border-opacity));
                                                                            }
                                                                        }
                                                                    

                                                                                .css-1om4f4s {
                                                                                    display: -webkit-box;
                                                                                    display: -webkit-flex;
                                                                                    display: -ms-flexbox;
                                                                                    display: flex;
                                                                                    -webkit-align-items: center;
                                                                                    -webkit-box-align: center;
                                                                                    -ms-flex-align: center;
                                                                                    align-items: center;
                                                                                    -webkit-box-pack: center;
                                                                                    -ms-flex-pack: center;
                                                                                    -webkit-justify-content: center;
                                                                                    justify-content: center;
                                                                                    border-style: none!important;
                                                                                    cursor: pointer;
                                                                                }
                                                                            

                                                                                    .css-91x1hi {
                                                                                        height: 1.5rem;
                                                                                        width: 1.5rem;
                                                                                        --tw-text-opacity: 1;
                                                                                        color: rgb(var(--colors-icon-contrast) / var(--tw-text-opacity));
                                                                                    }

                                                                                    .css-91x1hi:is([data-theme="dark"] *) {
                                                                                        --tw-text-opacity: 1;
                                                                                        color: rgb(var(--colors-dark-icon-contrast) / var(--tw-text-opacity));
                                                                                    }
                                                                                

                                                                            .css-1i4yu01 {
                                                                                margin-left: 0.25rem;
                                                                                display: -webkit-box;
                                                                                display: -webkit-flex;
                                                                                display: -ms-flexbox;
                                                                                display: flex;
                                                                            }
                                                                        

                                                                .css-8dk3vg {
                                                                    margin-bottom: 1rem;
                                                                }
                                                            

                                                                    .css-ns07pm {
                                                                        display: grid;
                                                                        grid-template-columns: repeat(4, minmax(0, 1fr));
                                                                        gap: 0.5rem;
                                                                    }
                                                                

                                                                        .css-fltzqv {
                                                                            display: -webkit-inline-box;
                                                                            display: -webkit-inline-flex;
                                                                            display: -ms-inline-flexbox;
                                                                            display: inline-flex;
                                                                            cursor: pointer;
                                                                            -webkit-align-items: center;
                                                                            -webkit-box-align: center;
                                                                            -ms-flex-align: center;
                                                                            align-items: center;
                                                                            -webkit-box-pack: center;
                                                                            -ms-flex-pack: center;
                                                                            -webkit-justify-content: center;
                                                                            justify-content: center;
                                                                            gap: 2px;
                                                                            border-radius: var(--borderRadius-full);
                                                                            border-width: 1px;
                                                                            --tw-border-opacity: 1;
                                                                            border-color: rgb(var(--borderColor-main) / var(--tw-border-opacity));
                                                                            --tw-bg-opacity: 1;
                                                                            background-color: rgb(var(--colors-background) / var(--tw-bg-opacity));
                                                                            --tw-text-opacity: 1;
                                                                            color: rgb(var(--textColor-main) / var(--tw-text-opacity));
                                                                            padding-left: 1rem;
                                                                            padding-right: 1rem;
                                                                            padding-top: 0.375rem;
                                                                            padding-bottom: 0.375rem;
                                                                            font-size: 16px;
                                                                            line-height: 24px;
                                                                            letter-spacing: 0.15px;
                                                                        }

                                                                        .css-fltzqv:is([data-theme="dark"] *) {
                                                                            --tw-border-opacity: 1;
                                                                            border-color: rgb(var(--borderColor-dark-main) / var(--tw-border-opacity));
                                                                            --tw-bg-opacity: 1;
                                                                            background-color: rgb(var(--colors-dark-background) / var(--tw-bg-opacity));
                                                                            --tw-text-opacity: 1;
                                                                            color: rgb(var(--textColor-dark-main) / var(--tw-text-opacity));
                                                                        }
                                                                    

                                                .css-70w0ro {
                                                    height: 100%;
                                                    display: none;
                                                }
                                            

                                                                .css-wc8glg input::-webkit-input-placeholder {
                                                                    font-weight: 500;
                                                                    --tw-text-opacity: 1!important;
                                                                    color: rgb(var(--textColor-additional) / var(--tw-text-opacity))!important;
                                                                }

                                                                .css-wc8glg input::-moz-placeholder {
                                                                    font-weight: 500;
                                                                    --tw-text-opacity: 1!important;
                                                                    color: rgb(var(--textColor-additional) / var(--tw-text-opacity))!important;
                                                                }

                                                                .css-wc8glg input:-ms-input-placeholder {
                                                                    font-weight: 500;
                                                                    --tw-text-opacity: 1!important;
                                                                    color: rgb(var(--textColor-additional) / var(--tw-text-opacity))!important;
                                                                }

                                                                .css-wc8glg input::placeholder {
                                                                    font-weight: 500;
                                                                    --tw-text-opacity: 1!important;
                                                                    color: rgb(var(--textColor-additional) / var(--tw-text-opacity))!important;
                                                                }

                                                                .css-wc8glg input::-webkit-input-placeholder:is([data-theme="dark"] *) {
                                                                    --tw-text-opacity: 1!important;
                                                                    color: rgb(var(--textColor-dark-additional) / var(--tw-text-opacity))!important;
                                                                }

                                                                .css-wc8glg input::-moz-placeholder:is([data-theme="dark"] *) {
                                                                    --tw-text-opacity: 1!important;
                                                                    color: rgb(var(--textColor-dark-additional) / var(--tw-text-opacity))!important;
                                                                }

                                                                .css-wc8glg input:-ms-input-placeholder:is([data-theme="dark"] *) {
                                                                    --tw-text-opacity: 1!important;
                                                                    color: rgb(var(--textColor-dark-additional) / var(--tw-text-opacity))!important;
                                                                }

                                                                .css-wc8glg input::placeholder:is([data-theme="dark"] *) {
                                                                    --tw-text-opacity: 1!important;
                                                                    color: rgb(var(--textColor-dark-additional) / var(--tw-text-opacity))!important;
                                                                }
                                                            

                                                                        .css-vly04d {
                                                                            display: -webkit-box;
                                                                            display: -webkit-flex;
                                                                            display: -ms-flexbox;
                                                                            display: flex;
                                                                            width: 100%;
                                                                            -webkit-align-items: center;
                                                                            -webkit-box-align: center;
                                                                            -ms-flex-align: center;
                                                                            align-items: center;
                                                                            overflow: hidden;
                                                                            border-radius: var(--borderRadius-full);
                                                                            border-width: 1px;
                                                                            height: 48px;
                                                                            padding-left: 1.5rem;
                                                                            padding-right: 1.5rem;
                                                                            padding-top: 0.875rem;
                                                                            padding-bottom: 0.875rem;
                                                                            font-size: var(--fontSize-sm-0);
                                                                            line-height: var(--fontSize-sm-1-lineHeight);
                                                                            --tw-border-opacity: 1;
                                                                            border-color: rgb(var(--borderColor-main) / var(--tw-border-opacity));
                                                                            --tw-border-opacity: 1!important;
                                                                            border-color: rgb(var(--borderColor-main) / var(--tw-border-opacity))!important;
                                                                            --tw-bg-opacity: 1;
                                                                            background-color: rgb(var(--colors-background-subtle) / var(--tw-bg-opacity));
                                                                            -webkit-transition: border 0.1s ease;
                                                                            transition: border 0.1s ease;
                                                                        }

                                                                        .css-vly04d:is([data-theme="dark"] *) {
                                                                            --tw-border-opacity: 1;
                                                                            border-color: rgb(var(--borderColor-dark-main) / var(--tw-border-opacity));
                                                                        }

                                                                        .css-vly04d:is([data-theme="dark"] *) {
                                                                            --tw-border-opacity: 1!important;
                                                                            border-color: rgb(var(--borderColor-dark-main) / var(--tw-border-opacity))!important;
                                                                            --tw-bg-opacity: 1;
                                                                            background-color: rgb(var(--colors-dark-background-subtle) / var(--tw-bg-opacity));
                                                                        }

                                                                        .css-vly04d input::-webkit-input-placeholder {
                                                                            font-weight: 500;
                                                                            --tw-text-opacity: 1!important;
                                                                            color: rgb(var(--textColor-additional) / var(--tw-text-opacity))!important;
                                                                        }

                                                                        .css-vly04d input::-moz-placeholder {
                                                                            font-weight: 500;
                                                                            --tw-text-opacity: 1!important;
                                                                            color: rgb(var(--textColor-additional) / var(--tw-text-opacity))!important;
                                                                        }

                                                                        .css-vly04d input:-ms-input-placeholder {
                                                                            font-weight: 500;
                                                                            --tw-text-opacity: 1!important;
                                                                            color: rgb(var(--textColor-additional) / var(--tw-text-opacity))!important;
                                                                        }

                                                                        .css-vly04d input::placeholder {
                                                                            font-weight: 500;
                                                                            --tw-text-opacity: 1!important;
                                                                            color: rgb(var(--textColor-additional) / var(--tw-text-opacity))!important;
                                                                        }

                                                                        .css-vly04d input::-webkit-input-placeholder:is([data-theme="dark"] *) {
                                                                            --tw-text-opacity: 1!important;
                                                                            color: rgb(var(--textColor-dark-additional) / var(--tw-text-opacity))!important;
                                                                        }

                                                                        .css-vly04d input::-moz-placeholder:is([data-theme="dark"] *) {
                                                                            --tw-text-opacity: 1!important;
                                                                            color: rgb(var(--textColor-dark-additional) / var(--tw-text-opacity))!important;
                                                                        }

                                                                        .css-vly04d input:-ms-input-placeholder:is([data-theme="dark"] *) {
                                                                            --tw-text-opacity: 1!important;
                                                                            color: rgb(var(--textColor-dark-additional) / var(--tw-text-opacity))!important;
                                                                        }

                                                                        .css-vly04d input::placeholder:is([data-theme="dark"] *) {
                                                                            --tw-text-opacity: 1!important;
                                                                            color: rgb(var(--textColor-dark-additional) / var(--tw-text-opacity))!important;
                                                                        }
                                                                    

                                                                            .css-1ogsgf2 {
                                                                                width: 100%;
                                                                                border-style: none;
                                                                                background-color: rgb(var(--backgroundColor-transparent) / 0);
                                                                                --tw-text-opacity: 1;
                                                                                color: rgb(var(--textColor-bold) / var(--tw-text-opacity));
                                                                                outline: 2px solid transparent!important;
                                                                                outline-offset: 2px!important;
                                                                                -webkit-flex: 1;
                                                                                -ms-flex: 1;
                                                                                flex: 1;
                                                                                height: -webkit-fill-available;
                                                                                cursor: not-allowed;
                                                                            }

                                                                            .css-1ogsgf2:is([data-theme="dark"] *) {
                                                                                --tw-text-opacity: 1;
                                                                                color: rgb(var(--textColor-dark-bold) / var(--tw-text-opacity));
                                                                            }

                                                                            .css-1ogsgf2:focus {
                                                                                caret-color: rgb(var(--colors-grey-700) / 1)!important;
                                                                            }

                                                                            .css-1ogsgf2:is([data-theme="dark"] *):focus {
                                                                                caret-color: rgb(var(--colors-dark-grey-700) / 1)!important;
                                                                            }

                                                                            .css-1ogsgf2:-webkit-autofill,.css-1ogsgf2:-webkit-autofill:focus {
                                                                                -webkit-transition: background-color 600000s 0s,color 600000s 0s;
                                                                                transition: background-color 600000s 0s,color 600000s 0s;
                                                                            }

                                                                            .css-1ogsgf2[data-autocompleted] {
                                                                                background-color: transparent!important;
                                                                            }
                                                                        

                                            .css-tnqays {
                                                display: -webkit-box;
                                                display: -webkit-flex;
                                                display: -ms-flexbox;
                                                display: flex;
                                                -webkit-flex: 0 0 272px;
                                                -ms-flex: 0 0 272px;
                                                flex: 0 0 272px;
                                                -webkit-flex-direction: column;
                                                -ms-flex-direction: column;
                                                flex-direction: column;
                                                gap: 1rem;
                                                -webkit-flex: 0 0 280px;
                                                -ms-flex: 0 0 280px;
                                                flex: 0 0 280px;
                                            }
                                        

                                                .css-16aleg1 {
                                                    display: -webkit-box;
                                                    display: -webkit-flex;
                                                    display: -ms-flexbox;
                                                    display: flex;
                                                    height: 981px;
                                                    width: 280px;
                                                    -webkit-flex-direction: column;
                                                    -ms-flex-direction: column;
                                                    flex-direction: column;
                                                    overflow: hidden;
                                                    border-radius: var(--borderRadius-sm);
                                                    border-width: 1px;
                                                    --tw-border-opacity: 1;
                                                    border-color: rgb(var(--borderColor-main) / var(--tw-border-opacity));
                                                }

                                                .css-16aleg1:is([data-theme="dark"] *) {
                                                    --tw-border-opacity: 1;
                                                    border-color: rgb(var(--borderColor-dark-main) / var(--tw-border-opacity));
                                                }
                                            

                                                    .orderbook-ask-row-tooltip:hover .orderbook-ask-row {
                                                        --tw-bg-opacity: 1!important;
                                                        background-color: rgb(var(--colors-background-subtle) / var(--tw-bg-opacity))!important;
                                                    }

                                                    .orderbook-ask-row-tooltip:hover .orderbook-ask-row:is([data-theme="dark"] *) {
                                                        --tw-bg-opacity: 1!important;
                                                        background-color: rgb(var(--colors-dark-background-subtle) / var(--tw-bg-opacity))!important;
                                                    }

                                                    .orderbook-ask-row-tooltip:hover .orderbook-ask-row:after {
                                                        content: " ";
                                                        position: absolute;
                                                        left: 0px;
                                                        top: 0px;
                                                        height: 1px;
                                                        width: 100%;
                                                        border-top-width: 1px;
                                                        border-style: dashed;
                                                        --tw-border-opacity: 1;
                                                        border-top-color: rgb(var(--borderColor-main) / var(--tw-border-opacity));
                                                    }

                                                    .orderbook-ask-row-tooltip:hover .orderbook-ask-row:after:is([data-theme="dark"] *) {
                                                        --tw-border-opacity: 1;
                                                        border-top-color: rgb(var(--borderColor-dark-main) / var(--tw-border-opacity));
                                                    }

                                                    .orderbook-ask-row-tooltip:hover~.orderbook-ask-row-tooltip .orderbook-ask-row {
                                                        --tw-bg-opacity: 1!important;
                                                        background-color: rgb(var(--colors-background-subtle) / var(--tw-bg-opacity))!important;
                                                    }

                                                    .orderbook-ask-row-tooltip:hover~.orderbook-ask-row-tooltip .orderbook-ask-row:is([data-theme="dark"] *) {
                                                        --tw-bg-opacity: 1!important;
                                                        background-color: rgb(var(--colors-dark-background-subtle) / var(--tw-bg-opacity))!important;
                                                    }

                                                    .orderbook-bids .orderbook-bids-list:hover .orderbook-bid-row-tooltip .orderbook-bid-row {
                                                        --tw-bg-opacity: 1!important;
                                                        background-color: rgb(var(--colors-background-subtle) / var(--tw-bg-opacity))!important;
                                                    }

                                                    .orderbook-bids .orderbook-bids-list:hover .orderbook-bid-row-tooltip .orderbook-bid-row:is([data-theme="dark"] *) {
                                                        --tw-bg-opacity: 1!important;
                                                        background-color: rgb(var(--colors-dark-background-subtle) / var(--tw-bg-opacity))!important;
                                                    }

                                                    .orderbook-bids .orderbook-bids-list:hover .orderbook-bid-row-tooltip:hover .orderbook-bid-row:after {
                                                        content: " ";
                                                        position: absolute;
                                                        bottom: 0px;
                                                        left: 0px;
                                                        height: 1px;
                                                        width: 100%;
                                                        border-bottom-width: 1px;
                                                        border-style: dashed;
                                                        --tw-border-opacity: 1;
                                                        border-bottom-color: rgb(var(--borderColor-main) / var(--tw-border-opacity));
                                                    }

                                                    .orderbook-bids .orderbook-bids-list:hover .orderbook-bid-row-tooltip:hover .orderbook-bid-row:after:is([data-theme="dark"] *) {
                                                        --tw-border-opacity: 1;
                                                        border-bottom-color: rgb(var(--borderColor-dark-main) / var(--tw-border-opacity));
                                                    }

                                                    .orderbook-bids .orderbook-bids-list:hover .orderbook-bid-row-tooltip:hover~.orderbook-bid-row-tooltip .orderbook-bid-row {
                                                        background-color: rgb(var(--backgroundColor-transparent) / 0)!important;
                                                    }

                                                    .orderbook-bids .orderbook-bids-list:hover .orderbook-bid-row-tooltip:hover~.orderbook-bid-row-tooltip .orderbook-bid-row:is([data-theme="dark"] *) {
                                                        background-color: rgb(var(--backgroundColor-transparent) / 0)!important;
                                                    }
                                                

                                                    .css-17erzux {
                                                        display: -webkit-box;
                                                        display: -webkit-flex;
                                                        display: -ms-flexbox;
                                                        display: flex;
                                                        -webkit-flex-direction: column;
                                                        -ms-flex-direction: column;
                                                        flex-direction: column;
                                                        gap: 0.625rem;
                                                        padding-left: 1rem;
                                                        padding-right: 1rem;
                                                        padding-top: 0.75rem;
                                                    }
                                                

                                                                    .css-qqykjf {
                                                                        margin-right: 0.25rem;
                                                                    }
                                                                

                                                    .css-maocum {
                                                        display: block;
                                                        height: 100%;
                                                        overflow: hidden;
                                                        font-size: var(--fontSize-xs-0);
                                                        line-height: var(--fontSize-xs-1-lineHeight);
                                                        font-variant-numeric: tabular-nums;
                                                    }
                                                

                                                        .css-1l2a9lh {
                                                            display: block;
                                                            width: 100%;
                                                            padding-left: 1rem;
                                                            padding-right: 1rem;
                                                            padding-top: 0.5rem;
                                                            padding-bottom: 0.5rem;
                                                        }
                                                    

                                                            .css-fokbco {
                                                                display: grid;
                                                                grid-template-columns: repeat(3, minmax(0, 1fr));
                                                            }
                                                        

                                                                .css-1ctqff3 {
                                                                    font-size: 12px;
                                                                    line-height: 16px;
                                                                    letter-spacing: 0px;
                                                                    font-weight: 600;
                                                                    text-align: right;
                                                                }

                                                                .css-1ctqff3:first-child {
                                                                    text-align: left;
                                                                }

                                                                .css-1ctqff3:nth-child(even) {
                                                                    padding-right: 0.5rem;
                                                                }
                                                            

                                                        .css-jhcwwb {
                                                            display: -webkit-box;
                                                            display: -webkit-flex;
                                                            display: -ms-flexbox;
                                                            display: flex;
                                                            -webkit-flex-direction: column;
                                                            -ms-flex-direction: column;
                                                            flex-direction: column;
                                                        }

                                                        .css-jhcwwb .rcs-custom-scroll .rcs-custom-scrollbar {
                                                            right: 0.125rem!important;
                                                            width: 5px!important;
                                                        }

                                                        .css-jhcwwb .rcs-custom-scroll .rcs-inner-handle {
                                                            --tw-bg-opacity: 1!important;
                                                            background-color: rgb(var(--colors-background-strong) / var(--tw-bg-opacity))!important;
                                                        }

                                                        .css-jhcwwb .rcs-custom-scroll .rcs-inner-handle:is([data-theme="dark"] *) {
                                                            --tw-bg-opacity: 1!important;
                                                            background-color: rgb(var(--colors-dark-background-strong) / var(--tw-bg-opacity))!important;
                                                        }
                                                    

                                                            .css-ysgtjv {
                                                                position: relative;
                                                                display: -webkit-box;
                                                                display: -webkit-flex;
                                                                display: -ms-flexbox;
                                                                display: flex;
                                                                cursor: pointer;
                                                                padding-left: 0.75rem;
                                                                padding-right: 0.75rem;
                                                            }

                                                            .css-ysgtjv:hover {
                                                                --tw-bg-opacity: 1;
                                                                background-color: rgb(var(--colors-background-subtle) / var(--tw-bg-opacity));
                                                            }

                                                            .css-ysgtjv:is([data-theme="dark"] *):hover {
                                                                --tw-bg-opacity: 1;
                                                                background-color: rgb(var(--colors-dark-background-subtle) / var(--tw-bg-opacity));
                                                            }
                                                        

                                                                .css-1c0mf0r {
                                                                    font-size: 12px;
                                                                    line-height: 16px;
                                                                    letter-spacing: 0.1px;
                                                                    font-weight: 600;
                                                                    --tw-text-opacity: 1;
                                                                    color: rgb(var(--colors-error) / var(--tw-text-opacity));
                                                                }

                                                                .css-1c0mf0r:is([data-theme="dark"] *) {
                                                                    --tw-text-opacity: 1;
                                                                    color: rgb(var(--colors-dark-error) / var(--tw-text-opacity));
                                                                }
                                                            

                                                                .css-vrz952 {
                                                                    font-size: 12px;
                                                                    line-height: 16px;
                                                                    letter-spacing: 0.1px;
                                                                    font-weight: 400;
                                                                    position: relative;
                                                                    -webkit-flex: 1 1 0%;
                                                                    -ms-flex: 1 1 0%;
                                                                    flex: 1 1 0%;
                                                                    padding-left: 0.25rem;
                                                                    padding-right: 0.25rem;
                                                                    padding-top: 0.125rem;
                                                                    padding-bottom: 0.125rem;
                                                                    text-align: right;
                                                                    --tw-text-opacity: 1;
                                                                    color: rgb(var(--textColor-main) / var(--tw-text-opacity));
                                                                    font-size: 12px;
                                                                    line-height: 16px;
                                                                    letter-spacing: 0.1px;
                                                                    font-weight: 600;
                                                                    --tw-text-opacity: 1;
                                                                    color: rgb(var(--colors-error) / var(--tw-text-opacity));
                                                                }

                                                                .css-vrz952:first-child {
                                                                    text-align: left;
                                                                }

                                                                .css-vrz952:nth-child(even) {
                                                                    margin-left: -8px!important;
                                                                }

                                                                .css-vrz952:is([data-theme="dark"] *) {
                                                                    --tw-text-opacity: 1;
                                                                    color: rgb(var(--textColor-dark-main) / var(--tw-text-opacity));
                                                                }

                                                                .css-vrz952:is([data-theme="dark"] *) {
                                                                    --tw-text-opacity: 1;
                                                                    color: rgb(var(--colors-dark-error) / var(--tw-text-opacity));
                                                                }
                                                            

                                                                .css-qwkitr {
                                                                    font-size: 12px;
                                                                    line-height: 16px;
                                                                    letter-spacing: 0.1px;
                                                                    font-weight: 400;
                                                                    position: relative;
                                                                    -webkit-flex: 1 1 0%;
                                                                    -ms-flex: 1 1 0%;
                                                                    flex: 1 1 0%;
                                                                    padding-left: 0.25rem;
                                                                    padding-right: 0.25rem;
                                                                    padding-top: 0.125rem;
                                                                    padding-bottom: 0.125rem;
                                                                    text-align: right;
                                                                    --tw-text-opacity: 1;
                                                                    color: rgb(var(--textColor-main) / var(--tw-text-opacity));
                                                                }

                                                                .css-qwkitr:first-child {
                                                                    text-align: left;
                                                                }

                                                                .css-qwkitr:nth-child(even) {
                                                                    margin-left: -8px!important;
                                                                }

                                                                .css-qwkitr:is([data-theme="dark"] *) {
                                                                    --tw-text-opacity: 1;
                                                                    color: rgb(var(--textColor-dark-main) / var(--tw-text-opacity));
                                                                }
                                                            

                                                                .css-ltpitc {
                                                                    font-size: 12px;
                                                                    line-height: 16px;
                                                                    letter-spacing: 0.1px;
                                                                    font-weight: 600;
                                                                    --tw-text-opacity: 1;
                                                                    color: rgb(var(--colors-success) / var(--tw-text-opacity));
                                                                }

                                                                .css-ltpitc:is([data-theme="dark"] *) {
                                                                    --tw-text-opacity: 1;
                                                                    color: rgb(var(--colors-dark-success) / var(--tw-text-opacity));
                                                                }
                                                            

                                                                .css-erz19k {
                                                                    font-size: 12px;
                                                                    line-height: 16px;
                                                                    letter-spacing: 0.1px;
                                                                    font-weight: 400;
                                                                    position: relative;
                                                                    -webkit-flex: 1 1 0%;
                                                                    -ms-flex: 1 1 0%;
                                                                    flex: 1 1 0%;
                                                                    padding-left: 0.25rem;
                                                                    padding-right: 0.25rem;
                                                                    padding-top: 0.125rem;
                                                                    padding-bottom: 0.125rem;
                                                                    text-align: right;
                                                                    --tw-text-opacity: 1;
                                                                    color: rgb(var(--textColor-main) / var(--tw-text-opacity));
                                                                    font-size: 12px;
                                                                    line-height: 16px;
                                                                    letter-spacing: 0.1px;
                                                                    font-weight: 600;
                                                                    --tw-text-opacity: 1;
                                                                    color: rgb(var(--colors-success) / var(--tw-text-opacity));
                                                                }

                                                                .css-erz19k:first-child {
                                                                    text-align: left;
                                                                }

                                                                .css-erz19k:nth-child(even) {
                                                                    margin-left: -8px!important;
                                                                }

                                                                .css-erz19k:is([data-theme="dark"] *) {
                                                                    --tw-text-opacity: 1;
                                                                    color: rgb(var(--textColor-dark-main) / var(--tw-text-opacity));
                                                                }

                                                                .css-erz19k:is([data-theme="dark"] *) {
                                                                    --tw-text-opacity: 1;
                                                                    color: rgb(var(--colors-dark-success) / var(--tw-text-opacity));
                                                                }
                                                            

                                        .css-1ilfm2b {
                                            display: -webkit-box;
                                            display: -webkit-flex;
                                            display: -ms-flexbox;
                                            display: flex;
                                            height: 419px;
                                            -webkit-flex-direction: column;
                                            -ms-flex-direction: column;
                                            flex-direction: column;
                                            gap: 0.5rem;
                                            overflow: hidden;
                                            border-radius: var(--borderRadius-sm);
                                            border-width: 1px;
                                            --tw-border-opacity: 1;
                                            border-color: rgb(var(--borderColor-main) / var(--tw-border-opacity));
                                            padding: 1.5rem;
                                        }

                                        .css-1ilfm2b:is([data-theme="dark"] *) {
                                            --tw-border-opacity: 1;
                                            border-color: rgb(var(--borderColor-dark-main) / var(--tw-border-opacity));
                                        }
                                    

                                            .css-bjn8wh {
                                                position: relative;
                                            }
                                        

                                            .css-rtlyos {
                                                width: undefinedpx;
                                                min-width: 100%;
                                            }

                                            .css-rtlyos .rcs-custom-scroll .rcs-custom-scrollbar {
                                                width: 0.25rem!important;
                                                right: 0.25rem!important;
                                            }
                                        

                                                        .css-5noo4i {
                                                            width: 100%;
                                                            min-width: 100%;
                                                            border-collapse: collapse;
                                                        }

                                                        .css-5noo4i * {
                                                            font-size: 16px;
                                                        }
                                                    

                                                            .css-fh6o0h .ez0l7ad8:hover {
                                                                background-color: rgb(var(--backgroundColor-transparent) / 0)!important;
                                                            }

                                                            .css-fh6o0h .ez0l7ad8:is([data-theme="dark"] *):hover {
                                                                background-color: rgb(var(--backgroundColor-transparent) / 0)!important;
                                                            }
                                                        

                                                                .css-ythp4h {
                                                                    display: table;
                                                                    width: 100%;
                                                                    table-layout: fixed;
                                                                    border-bottom-width: 1px;
                                                                    --tw-border-opacity: 1;
                                                                    border-bottom-color: rgb(var(--borderColor-main) / var(--tw-border-opacity));
                                                                    width: 100%;
                                                                }

                                                                .css-ythp4h:is([data-theme="dark"] *) {
                                                                    --tw-border-opacity: 1;
                                                                    border-bottom-color: rgb(var(--borderColor-dark-main) / var(--tw-border-opacity));
                                                                }

                                                                .css-ythp4h:hover {
                                                                    --tw-bg-opacity: 1;
                                                                    background-color: rgb(var(--colors-primary-25) / var(--tw-bg-opacity));
                                                                }

                                                                .css-ythp4h:is([data-theme="dark"] *):hover {
                                                                    --tw-bg-opacity: 1;
                                                                    background-color: rgb(var(--colors-dark-background-subtle) / var(--tw-bg-opacity));
                                                                }

                                                                .css-ythp4h:last-child {
                                                                    border-color: transparent;
                                                                }
                                                            

                                                                    .css-4o3mdc {
                                                                        max-width: 0px;
                                                                        text-align: left;
                                                                        font-weight: inherit;
                                                                        -webkit-user-select: none;
                                                                        -moz-user-select: none;
                                                                        -ms-user-select: none;
                                                                        user-select: none;
                                                                        padding: 10px 15px;
                                                                    }
                                                                

                                                            .css-4zleql {
                                                                display: block;
                                                            }
                                                        

                                                                .css-1dspo2s {
                                                                    display: table;
                                                                    width: 100%;
                                                                    table-layout: fixed;
                                                                    border-bottom-width: 1px;
                                                                    --tw-border-opacity: 1;
                                                                    border-bottom-color: rgb(var(--borderColor-main) / var(--tw-border-opacity));
                                                                    width: 100%;
                                                                }

                                                                .css-1dspo2s:is([data-theme="dark"] *) {
                                                                    --tw-border-opacity: 1;
                                                                    border-bottom-color: rgb(var(--borderColor-dark-main) / var(--tw-border-opacity));
                                                                }

                                                                .css-1dspo2s:last-child {
                                                                    border-color: transparent;
                                                                }
                                                            

                                                                                    .css-11vadmq {
                                                                                        width: initial;
                                                                                        height: initial;
                                                                                        display: inherit;
                                                                                        -webkit-text-decoration: none;
                                                                                        text-decoration: none;
                                                                                    }
                                                                                

                                        .css-18jlely {
                                            display: -webkit-box;
                                            display: -webkit-flex;
                                            display: -ms-flexbox;
                                            display: flex;
                                            -webkit-flex-direction: column;
                                            -ms-flex-direction: column;
                                            flex-direction: column;
                                            gap: 1rem;
                                            border-radius: var(--borderRadius-sm);
                                            border-width: 1px;
                                            --tw-border-opacity: 1;
                                            border-color: rgb(var(--borderColor-main) / var(--tw-border-opacity));
                                            padding: 1rem;
                                        }

                                        .css-18jlely:is([data-theme="dark"] *) {
                                            --tw-border-opacity: 1;
                                            border-color: rgb(var(--borderColor-dark-main) / var(--tw-border-opacity));
                                        }
                                    

                                            .css-saccvp {
                                                font-size: 14px;
                                                line-height: 20px;
                                                letter-spacing: -0.1px;
                                                font-weight: 600;
                                                --tw-text-opacity: 1;
                                                color: rgb(var(--textColor-bold) / var(--tw-text-opacity));
                                            }

                                            @media (min-width: 1280px) {
                                                .css-saccvp {
                                                    font-size:18px;
                                                    line-height: 28px;
                                                    letter-spacing: -0.4px;
                                                    font-weight: 600;
                                                }
                                            }

                                            .css-saccvp:is([data-theme="dark"] *) {
                                                --tw-text-opacity: 1;
                                                color: rgb(var(--textColor-dark-bold) / var(--tw-text-opacity));
                                            }
                                        

                                            .css-zu69x5 {
                                                display: grid;
                                                grid-auto-flow: column;
                                                -webkit-column-gap: 93px;
                                                column-gap: 93px;
                                                row-gap: 0.75rem;
                                                grid-template-rows: repeat(5, minmax(0, 1fr));
                                            }

                                            @media screen and (min-width: 1280px) {
                                                .css-zu69x5 {
                                                    grid-template-rows:repeat(2, minmax(0, 1fr));
                                                }
                                            }
                                        

                                                .css-bcffy2 {
                                                    display: -webkit-box;
                                                    display: -webkit-flex;
                                                    display: -ms-flexbox;
                                                    display: flex;
                                                    -webkit-align-items: center;
                                                    -webkit-box-align: center;
                                                    -ms-flex-align: center;
                                                    align-items: center;
                                                    -webkit-box-pack: justify;
                                                    -webkit-justify-content: space-between;
                                                    justify-content: space-between;
                                                }
                                            

                                                    .css-4h70xj {
                                                        display: -webkit-inline-box;
                                                        display: -webkit-inline-flex;
                                                        display: -ms-inline-flexbox;
                                                        display: inline-flex;
                                                        width: auto;
                                                        -webkit-flex-direction: row;
                                                        -ms-flex-direction: row;
                                                        flex-direction: row;
                                                        -webkit-align-items: unset;
                                                        -webkit-box-align: unset;
                                                        -ms-flex-align: unset;
                                                        align-items: unset;
                                                        -webkit-box-pack: unset;
                                                        -ms-flex-pack: unset;
                                                        -webkit-justify-content: unset;
                                                        justify-content: unset;
                                                        gap: 8px;
                                                    }
                                                

                                                        .css-13as11q {
                                                            font-size: 14px;
                                                            line-height: 20px;
                                                            letter-spacing: 0.15px;
                                                            --tw-text-opacity: 1;
                                                            color: rgb(var(--textColor-additional) / var(--tw-text-opacity));
                                                        }

                                                        .css-13as11q:is([data-theme="dark"] *) {
                                                            --tw-text-opacity: 1;
                                                            color: rgb(var(--textColor-dark-main) / var(--tw-text-opacity));
                                                        }
                                                    

                                                        .css-p23r4u {
                                                            border-radius: var(--borderRadius-xs);
                                                            --tw-bg-opacity: 1;
                                                            background-color: rgb(var(--colors-background) / var(--tw-bg-opacity));
                                                            padding: 0.25rem;
                                                            --tw-shadow: var(--boxShadow-lg);
                                                            --tw-shadow-colored: var(--boxShadow-lg);
                                                            box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow);
                                                        }

                                                        .css-p23r4u:is([data-theme="dark"] *) {
                                                            --tw-bg-opacity: 1;
                                                            background-color: rgb(10 10 10 / var(--tw-bg-opacity));
                                                            --tw-shadow: var(--boxShadow-dark-lg);
                                                            --tw-shadow-colored: var(--boxShadow-dark-lg);
                                                            box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow);
                                                        }

                                                        .css-p23r4u .tippy-arrow {
                                                            --tw-text-opacity: 1;
                                                            color: rgb(var(--textColor-white) / var(--tw-text-opacity));
                                                        }

                                                        .css-p23r4u .tippy-arrow:is([data-theme="dark"] *) {
                                                            --tw-text-opacity: 1;
                                                            color: rgb(10 10 10 / var(--tw-text-opacity));
                                                        }
                                                    

                                                        .css-1tjylrs {
                                                            width: -webkit-fit-content;
                                                            width: -moz-fit-content;
                                                            width: fit-content;
                                                        }
                                                    

                                                    .css-1d7tnnj {
                                                        font-size: 14px;
                                                        line-height: 20px;
                                                        letter-spacing: -0.1px;
                                                        font-weight: 600;
                                                        --tw-text-opacity: 1;
                                                        color: rgb(var(--textColor-main) / var(--tw-text-opacity));
                                                    }

                                                    .css-1d7tnnj:is([data-theme="dark"] *) {
                                                        --tw-text-opacity: 1;
                                                        color: rgb(var(--textColor-dark-bold) / var(--tw-text-opacity));
                                                    }
                                                

                                    .css-9j45b5 {
                                        display: -webkit-box;
                                        display: -webkit-flex;
                                        display: -ms-flexbox;
                                        display: flex;
                                        height: -webkit-fit-content;
                                        height: -moz-fit-content;
                                        height: fit-content;
                                        -webkit-flex-direction: column;
                                        -ms-flex-direction: column;
                                        flex-direction: column;
                                        gap: 0.5rem;
                                        border-radius: var(--borderRadius-sm);
                                        border-width: 1px;
                                        border-style: solid;
                                        --tw-border-opacity: 1;
                                        border-color: rgb(var(--borderColor-main) / var(--tw-border-opacity));
                                        padding: 1rem;
                                    }

                                    .css-9j45b5:is([data-theme="dark"] *) {
                                        --tw-border-opacity: 1;
                                        border-color: rgb(var(--borderColor-dark-main) / var(--tw-border-opacity));
                                    }
                                

                                    .css-101pssg {
                                        display: -webkit-box;
                                        display: -webkit-flex;
                                        display: -ms-flexbox;
                                        display: flex;
                                        width: 100%;
                                        -webkit-flex-direction: column;
                                        -ms-flex-direction: column;
                                        flex-direction: column;
                                        gap: 18px;
                                        border-radius: var(--borderRadius-sm);
                                        border-width: 1px;
                                        --tw-border-opacity: 1;
                                        border-color: rgb(var(--borderColor-main) / var(--tw-border-opacity));
                                        padding: 1rem;
                                    }

                                    .css-101pssg:is([data-theme="dark"] *) {
                                        --tw-border-opacity: 1;
                                        border-color: rgb(var(--borderColor-dark-main) / var(--tw-border-opacity));
                                    }

                                    @media (min-width: 1280px) {
                                        .css-101pssg {
                                            gap:1rem;
                                        }
                                    }
                                

                                        .css-xxjrq6 {
                                            display: -webkit-box;
                                            display: -webkit-flex;
                                            display: -ms-flexbox;
                                            display: flex;
                                            -webkit-flex-direction: column;
                                            -ms-flex-direction: column;
                                            flex-direction: column;
                                            gap: 0.75rem;
                                        }

                                        @media (min-width: 1280px) {
                                            .css-xxjrq6 {
                                                -webkit-flex-direction:row;
                                                -ms-flex-direction: row;
                                                flex-direction: row;
                                                gap: 1rem;
                                            }
                                        }
                                    

                                            .css-1p02r7c {
                                                overflow: hidden;
                                                border-radius: var(--borderRadius-sm);
                                            }

                                            @media (min-width: 1280px) {
                                                .css-1p02r7c {
                                                    max-height:168px;
                                                    min-height: 168px;
                                                    min-width: 336px;
                                                    max-width: 336px;
                                                }
                                            }
                                        

                                            .css-1ey60ym {
                                                width: initial;
                                                height: initial;
                                                display: inherit;
                                                -webkit-text-decoration: none;
                                                text-decoration: none;
                                                overflow: hidden;
                                                border-radius: var(--borderRadius-sm);
                                            }

                                            @media (min-width: 1280px) {
                                                .css-1ey60ym {
                                                    max-height:168px;
                                                    min-height: 168px;
                                                    min-width: 336px;
                                                    max-width: 336px;
                                                }
                                            }
                                        

                                            .css-1xwougt {
                                                display: -webkit-box;
                                                display: -webkit-flex;
                                                display: -ms-flexbox;
                                                display: flex;
                                                width: 100%;
                                                -webkit-flex-direction: column;
                                                -ms-flex-direction: column;
                                                flex-direction: column;
                                                -webkit-box-pack: justify;
                                                -webkit-justify-content: space-between;
                                                justify-content: space-between;
                                                gap: 0.5rem;
                                            }
                                        

                                                .css-11j2tcs {
                                                    font-size: 14px;
                                                    line-height: 20px;
                                                    letter-spacing: -0.1px;
                                                    font-weight: 600;
                                                    overflow: hidden;
                                                    display: inline-block;
                                                    -webkit-box-orient: vertical;
                                                    -webkit-line-clamp: 2;
                                                    max-height: 56px;
                                                    --tw-text-opacity: 1;
                                                    color: rgb(var(--textColor-main) / var(--tw-text-opacity));
                                                }

                                                @media (min-width: 1280px) {
                                                    .css-11j2tcs {
                                                        font-size:20px;
                                                        line-height: 28px;
                                                        letter-spacing: -0.15px;
                                                        font-weight: 700;
                                                    }
                                                }

                                                .css-11j2tcs:is([data-theme="dark"] *) {
                                                    --tw-text-opacity: 1;
                                                    color: rgb(var(--textColor-dark-main) / var(--tw-text-opacity));
                                                }
                                            

                                                .css-1c3lzug {
                                                    font-size: 14px;
                                                    line-height: 20px;
                                                    letter-spacing: 0.15px;
                                                    overflow: hidden;
                                                    display: -webkit-box;
                                                    -webkit-box-orient: vertical;
                                                    -webkit-line-clamp: 3;
                                                    max-height: 72px;
                                                    width: 100%;
                                                    --tw-text-opacity: 1;
                                                    color: rgb(var(--textColor-additional) / var(--tw-text-opacity));
                                                }

                                                @media (min-width: 1280px) {
                                                    .css-1c3lzug {
                                                        font-size:16px;
                                                        line-height: 24px;
                                                        letter-spacing: 0.15px;
                                                    }
                                                }

                                                .css-1c3lzug:is([data-theme="dark"] *) {
                                                    --tw-text-opacity: 1;
                                                    color: rgb(var(--textColor-dark-additional) / var(--tw-text-opacity));
                                                }
                                            

                                                .css-hqhfob {
                                                    font-size: 12px;
                                                    line-height: 16px;
                                                    letter-spacing: 0.1px;
                                                    display: -webkit-box;
                                                    display: -webkit-flex;
                                                    display: -ms-flexbox;
                                                    display: flex;
                                                    gap: 0.75rem;
                                                    --tw-text-opacity: 1;
                                                    color: rgb(var(--textColor-additional) / var(--tw-text-opacity));
                                                }

                                                @media (min-width: 1280px) {
                                                    .css-hqhfob {
                                                        font-size:14px;
                                                        line-height: 20px;
                                                        letter-spacing: 0.15px;
                                                    }
                                                }

                                                .css-hqhfob:is([data-theme="dark"] *) {
                                                    --tw-text-opacity: 1;
                                                    color: rgb(var(--textColor-dark-additional) / var(--tw-text-opacity));
                                                }
                                            

                                                    .css-ay1kw3 {
                                                        display: inline-block;
                                                        border-right-width: 1px;
                                                        --tw-border-opacity: 1;
                                                        border-color: rgb(var(--borderColor-main) / var(--tw-border-opacity));
                                                        padding-right: 0.75rem;
                                                    }

                                                    .css-ay1kw3:is([data-theme="dark"] *) {
                                                        --tw-border-opacity: 1;
                                                        border-color: rgb(var(--borderColor-dark-main) / var(--tw-border-opacity));
                                                    }
                                                

                                                    .css-xzu2qo {
                                                        display: -webkit-inline-box;
                                                        display: -webkit-inline-flex;
                                                        display: -ms-inline-flexbox;
                                                        display: inline-flex;
                                                        -webkit-align-items: center;
                                                        -webkit-box-align: center;
                                                        -ms-flex-align: center;
                                                        align-items: center;
                                                        gap: 0.5rem;
                                                    }
                                                

                .css-16igk0y {
                    min-width: 1210px;
                }

                @media screen and (max-width: 1280px) {
                    .css-16igk0y {
                        min-width:auto;
                    }
                }
            

                                    .css-17x35y8 {
                                        width: -webkit-fit-content;
                                        width: -moz-fit-content;
                                        width: fit-content;
                                        height: -webkit-fit-content;
                                        height: -moz-fit-content;
                                        height: fit-content;
                                        display: -webkit-inline-box;
                                        display: -webkit-inline-flex;
                                        display: -ms-inline-flexbox;
                                        display: inline-flex;
                                        -webkit-align-items: center;
                                        -webkit-box-align: center;
                                        -ms-flex-align: center;
                                        align-items: center;
                                        -webkit-box-pack: center;
                                        -ms-flex-pack: center;
                                        -webkit-justify-content: center;
                                        justify-content: center;
                                    }
                                

                                        .css-11vadmq {
                                            width: initial;
                                            height: initial;
                                            display: inherit;
                                            -webkit-text-decoration: none;
                                            text-decoration: none;
                                        }

                                    .title-css-info {
                                        font-weight: 700;
                                        font-family: 'Roboto', Arial, sans-serif;
                                    }

                                    .title-css-info-5x {
                                        font-weight: 500;
                                        font-family: 'Roboto', Arial, sans-serif;
                                        font-size: var(--fontSize-sm-0);
                                        line-height: var(--fontSize-sm-1-lineHeight);
                                    }