.chatcenter-1 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  transition: all 0.25s ease 0s;
  padding: 0.9375rem;
  pointer-events: initial;
  opacity: 1;
}

.chatcenter-1:after {
  border-color: rgb(30, 39, 48) !important;
}

.chatcenter-2 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}

.chatcenter-2:after {
  border-color: rgb(30, 39, 48) !important;
}

.chatcenter-3 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  font-size: 1.0625rem;
  font-weight: 700;
  /*color: rgb(238, 238, 238);*/
}

.chatcenter-3:after {
  border-color: rgb(30, 39, 48) !important;
}

.chatcenter-4 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  align-items: center;
  margin-top: 0.9375rem;
  padding: 0.875rem;
  border-radius: 0.4375rem;
  background: gainsboro;
}

.chatcenter-4:active {
  opacity: 0.7;
}

.chatcenter-4:after {
  border-color: rgb(30, 39, 48) !important;
}

.chatcenter-5 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  overflow: hidden;
  position: relative;
  scrollbar-width: none;
  font-family: sans-serif;
  width: 1.5625rem;
  height: 1.5625rem;
  margin-right: 0.625rem;
}

.chatcenter-5:after {
  border-color: rgb(30, 39, 48) !important;
}

.chatcenter-6 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.chatcenter-7 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}

.chatcenter-8 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  font-family: sans-serif;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

}

.chatcenter-9 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  font-family: sans-serif;
  font-size: 0.875rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1 1 0%;
  /*color: rgb(238, 238, 238);*/
}

.chatcenter-9:after {
  border-color: rgb(30, 39, 48) !important;
}

.chatcenter-10 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}

.chatcenter-11 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: iconfont2 !important;
  font-style: normal;
  margin-left: auto;
  font-size: 0.875rem;
  font-weight: 700;
  color: rgb(187, 187, 187);
}

.chatcenter-11:after {
  border-color: rgb(30, 39, 48) !important;
}

.chatcenter-11:before {
  content: "";
}

.chatcenter-12 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  align-items: center;
  margin-top: 0.9375rem;
  padding: 0.875rem;
  border-radius: 0.4375rem;
  background: rgb(12, 18, 24);
}

.chatcenter-12:active {
  opacity: 0.7;
}

.chatcenter-12:after {
  border-color: rgb(30, 39, 48) !important;
}

.chatcenter-13 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  overflow: hidden;
  position: relative;
  scrollbar-width: none;
  font-family: sans-serif;
  width: 1.5625rem;
  height: 1.5625rem;
  margin-right: 0.625rem;
}

.chatcenter-13:after {
  border-color: rgb(30, 39, 48) !important;
}

.chatcenter-14 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.chatcenter-15 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}

.chatcenter-16 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  font-family: sans-serif;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

}

.chatcenter-17 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  font-family: sans-serif;
  font-size: 0.875rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1 1 0%;
  color: rgb(238, 238, 238);
}

.chatcenter-17:after {
  border-color: rgb(30, 39, 48) !important;
}

.chatcenter-18 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}

.chatcenter-19 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: iconfont2 !important;
  font-style: normal;
  margin-left: auto;
  font-size: 0.875rem;
  font-weight: 700;
  color: rgb(187, 187, 187);
}

.chatcenter-19:after {
  border-color: rgb(30, 39, 48) !important;
}

.chatcenter-19:before {
  content: "";
}