.fwxy-1:focus {
  outline: 0px;
}

.fwxy-1:after {
  display: table;
  clear: both;
  content: "";
}

.fwxy-1 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  width: 100%;
  /* color: #fff; */
  overflow: scroll;
}

.fwxy-2 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  font-size: 1.75rem;
  line-height: 6rem;
  letter-spacing: -0.01562em;
  font-weight: 300;
  margin: 0px 0.66667rem 0.53333rem;
  padding: 0px;
  border: 0px;
  outline: 0px;
  vertical-align: baseline;
  background: transparent;
  /* color: #fff; */
  text-align: left;
}

.fwxy-3 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0.66667rem;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 0.93333rem;
  vertical-align: baseline;
  background: transparent;
  text-indent: 1.54667rem;
  text-align: left;
  line-height: 1.3rem;
}

.fwxy-4 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0.66667rem;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 0.93333rem;
  vertical-align: baseline;
  background: transparent;
  text-indent: 1.54667rem;
  text-align: left;
  line-height: 1.3rem;
}

.fwxy-5 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  white-space: pre-line;
}

.fwxy-6 {
  box-sizing: inherit;
  -webkit-tap-highlight-color: transparent;
}

.fwxy-7 {
  box-sizing: inherit;
  -webkit-tap-highlight-color: transparent;
}

.fwxy-8 {
  box-sizing: inherit;
  -webkit-tap-highlight-color: transparent;
}

.fwxy-9 {
  box-sizing: inherit;
  -webkit-tap-highlight-color: transparent;
}

.fwxy-10 {
  box-sizing: inherit;
  -webkit-tap-highlight-color: transparent;
}

.fwxy-11 {
  box-sizing: inherit;
  -webkit-tap-highlight-color: transparent;
}

.fwxy-12 {
  box-sizing: inherit;
  -webkit-tap-highlight-color: transparent;
}

.fwxy-13 {
  box-sizing: inherit;
  -webkit-tap-highlight-color: transparent;
}

.fwxy-14 {
  box-sizing: inherit;
  -webkit-tap-highlight-color: transparent;
}

.fwxy-15 {
  box-sizing: inherit;
  -webkit-tap-highlight-color: transparent;
}

.fwxy-16 {
  box-sizing: inherit;
  -webkit-tap-highlight-color: transparent;
}

.fwxy-17 {
  box-sizing: inherit;
  -webkit-tap-highlight-color: transparent;
}

.fwxy-18 {
  box-sizing: inherit;
  -webkit-tap-highlight-color: transparent;
}

.fwxy-19 {
  box-sizing: inherit;
  -webkit-tap-highlight-color: transparent;
}

.fwxy-20 {
  box-sizing: inherit;
  -webkit-tap-highlight-color: transparent;
}

.fwxy-21 {
  box-sizing: inherit;
  -webkit-tap-highlight-color: transparent;
}

.fwxy-22 {
  box-sizing: inherit;
  -webkit-tap-highlight-color: transparent;
}

.fwxy-23 {
  box-sizing: inherit;
  -webkit-tap-highlight-color: transparent;
}

.fwxy-24 {
  box-sizing: inherit;
  -webkit-tap-highlight-color: transparent;
}


