.getProperty-1 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  transition: all 0.25s ease 0s;
  padding: 0.3125rem 0.9375rem 0.9375rem;
  pointer-events: initial;
  opacity: 1;
}

.getProperty-1:after {
  border-color: rgb(30, 39, 48) !important;
}

.getProperty-2 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  overflow: hidden;
  position: relative;
  scrollbar-width: none;
  font-family: sans-serif;
  width: 100%;
  border-radius: 0.625rem;
  height: 154px;
}

.getProperty-2:after {
  border-color: rgb(30, 39, 48) !important;
}

.getProperty-3 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.getProperty-4 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  position: absolute;
  inset: 0px;
  overflow: hidden;
  display: block;
  z-index: -1;
  visibility: hidden;
  animation: 1ms ease 0s 1 normal none running once-show;
  font-family: sans-serif;
}

.getProperty-5 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  position: absolute;
  inset: 0px;
  overflow: hidden;
}

.getProperty-6 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100000px;
  height: 100000px;
}

.getProperty-7 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  position: absolute;
  inset: 0px;
  overflow: hidden;
}

.getProperty-8 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 200%;
  height: 200%;
}

.getProperty-9 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  font-family: sans-serif;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.getProperty-10 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  border-radius: 0.625rem;
  padding: 1.5625rem 1.09375rem 1.875rem;
  margin-top: 0.15625rem;
  background: rgb(27, 35, 43);
}

.getProperty-10:after {
  border-color: rgb(30, 39, 48) !important;
}

.getProperty-11 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  font-size: 0.8125rem;
  line-height: 1.8;
  color: rgba(255, 255, 255, 0.8);
}

.getProperty-11:after {
  border-color: rgb(30, 39, 48) !important;
}

.getProperty-12 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  font-size: 0.8125rem;
  line-height: 1.8;
  color: rgba(255, 255, 255, 0.8);
}

.getProperty-12:after {
  border-color: rgb(30, 39, 48) !important;
}

.getProperty-13 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  font-size: 0.8125rem;
  line-height: 1.8;
  color: rgba(255, 255, 255, 0.8);
}

.getProperty-13:after {
  border-color: rgb(30, 39, 48) !important;
}

.getProperty-14 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  font-size: 0.8125rem;
  line-height: 1.8;
  color: rgba(255, 255, 255, 0.8);
}

.getProperty-14:after {
  border-color: rgb(30, 39, 48) !important;
}

.getProperty-15 {
  margin-left: auto;
  margin-right: auto;
  padding-left: 14px;
  padding-right: 14px;
  text-align: center;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  background-color: rgb(248, 248, 248);
  cursor: pointer;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  overflow: hidden;
  position: relative;
  border: 0px;
  color: rgb(255, 255, 255);
  outline: 0px;
  padding: 0px;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
  border-radius: 3.125rem;
  background: rgb(240, 193, 99);
  height: 2.4375rem;
  font-size: 0.875rem;
  margin-top: 1.875rem;
}

.getProperty-15:after {
  width: 200%;
  top: 0px;
  left: 0px;
  transform: scale(0.5);
  transform-origin: 0px 0px;
  display: none;
  content: "";
  position: absolute;
  inset: 0px;
  border: 0px;
  pointer-events: none;
  border-color: rgb(30, 39, 48) !important;
}

@media (prefers-color-scheme: dark) {
  .getProperty-15 {
    color: rgb(214, 214, 214);
    background-color: rgb(52, 52, 52);
  }
}