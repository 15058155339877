.moremenus-1 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  transition: all 0.25s ease 0s;
  padding: 0.9375rem 0;
  pointer-events: initial;
  opacity: 1;
}

.moremenus-1:after {
  border-color: rgb(30, 39, 48) !important;
}

.moremenus-2 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  width: 100vw;
  height: 46px;
}

.moremenus-2:after {
  border-color: rgb(30, 39, 48) !important;
}

.moremenus-3 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  margin-bottom: 1.5625rem;
}

.moremenus-3:after {
  border-color: rgb(30, 39, 48) !important;
}

.moremenus-4 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  white-space: pre-line;
  scrollbar-width: none;
  font-family: sans-serif;
  padding: 0px 1.25rem;
  font-size: 1rem;
  font-weight: 700;
  color: rgb(255, 255, 255);
}

.moremenus-4:after {
  border-color: rgb(30, 39, 48) !important;
}

.moremenus-5 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}

.moremenus-6 {
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  align-items: center;
  margin: 0.9375rem 0px;
}

.moremenus-6:after {
  border-color: rgb(30, 39, 48) !important;
}

.moremenus-7 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 25%;
}

.moremenus-7:active {
  opacity: 0.7;
}

.moremenus-7:after {
  border-color: rgb(30, 39, 48) !important;
}

.moremenus-8 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  overflow: hidden;
  position: relative;
  scrollbar-width: none;
  font-family: sans-serif;
  width: 2.1875rem;
  height: 2.1875rem;
  margin-bottom: 0.3125rem;
}

.moremenus-8:after {
  border-color: rgb(30, 39, 48) !important;
}


.moremenus-10 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}

.moremenus-11 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  font-family: sans-serif;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

}

.moremenus-12 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  white-space: pre-line;
  scrollbar-width: none;
  font-family: sans-serif;
  font-size: 0.8125rem;
  text-align: center;
  display: block;
  min-height: 2.3125rem;
  color: rgb(205, 206, 207);
}

.moremenus-12:after {
  border-color: rgb(30, 39, 48) !important;
}

.moremenus-13 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}

.moremenus-14 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 25%;
}

.moremenus-14:active {
  opacity: 0.7;
}

.moremenus-14:after {
  border-color: rgb(30, 39, 48) !important;
}

.moremenus-15 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  overflow: hidden;
  position: relative;
  scrollbar-width: none;
  font-family: sans-serif;
  width: 2.1875rem;
  height: 2.1875rem;
  margin-bottom: 0.3125rem;
}

.moremenus-15:after {
  border-color: rgb(30, 39, 48) !important;
}


.moremenus-17 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}

.moremenus-18 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  font-family: sans-serif;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

}

.moremenus-19 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  white-space: pre-line;
  scrollbar-width: none;
  font-family: sans-serif;
  font-size: 0.8125rem;
  text-align: center;
  display: block;
  min-height: 2.3125rem;
  color: rgb(205, 206, 207);
}

.moremenus-19:after {
  border-color: rgb(30, 39, 48) !important;
}

.moremenus-20 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}

.moremenus-21 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 25%;
}

.moremenus-21:active {
  opacity: 0.7;
}

.moremenus-21:after {
  border-color: rgb(30, 39, 48) !important;
}

.moremenus-22 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  overflow: hidden;
  position: relative;
  scrollbar-width: none;
  font-family: sans-serif;
  width: 2.1875rem;
  height: 2.1875rem;
  margin-bottom: 0.3125rem;
}

.moremenus-22:after {
  border-color: rgb(30, 39, 48) !important;
}



.moremenus-24 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}

.moremenus-25 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  font-family: sans-serif;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

}

.moremenus-26 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  white-space: pre-line;
  scrollbar-width: none;
  font-family: sans-serif;
  font-size: 0.8125rem;
  text-align: center;
  display: block;
  min-height: 2.3125rem;
  color: rgb(205, 206, 207);
}

.moremenus-26:after {
  border-color: rgb(30, 39, 48) !important;
}

.moremenus-27 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}

.moremenus-28 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  margin-bottom: 1.5625rem;
}

.moremenus-28:after {
  border-color: rgb(30, 39, 48) !important;
}

.moremenus-29 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  white-space: pre-line;
  scrollbar-width: none;
  font-family: sans-serif;
  padding: 0px 1.25rem;
  font-size: 1rem;
  font-weight: 700;
  color: rgb(255, 255, 255);
}

.moremenus-29:after {
  border-color: rgb(30, 39, 48) !important;
}

.moremenus-30 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}

.moremenus-31 {
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  align-items: center;
  margin: 0.9375rem 0px;
}

.moremenus-31:after {
  border-color: rgb(30, 39, 48) !important;
}

.moremenus-32 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 25%;
}

.moremenus-32:active {
  opacity: 0.7;
}

.moremenus-32:after {
  border-color: rgb(30, 39, 48) !important;
}

.moremenus-33 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  overflow: hidden;
  position: relative;
  scrollbar-width: none;
  font-family: sans-serif;
  width: 2.1875rem;
  height: 2.1875rem;
  margin-bottom: 0.3125rem;
}

.moremenus-33:after {
  border-color: rgb(30, 39, 48) !important;
}

.moremenus-35 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}

.moremenus-36 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  font-family: sans-serif;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

}

.moremenus-37 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  white-space: pre-line;
  scrollbar-width: none;
  font-family: sans-serif;
  font-size: 0.8125rem;
  text-align: center;
  display: block;
  min-height: 2.3125rem;
  color: rgb(205, 206, 207);
}

.moremenus-37:after {
  border-color: rgb(30, 39, 48) !important;
}

.moremenus-38 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}

.moremenus-39 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 25%;
}

.moremenus-39:active {
  opacity: 0.7;
}

.moremenus-39:after {
  border-color: rgb(30, 39, 48) !important;
}

.moremenus-40 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  overflow: hidden;
  position: relative;
  scrollbar-width: none;
  font-family: sans-serif;
  width: 2.1875rem;
  height: 2.1875rem;
  margin-bottom: 0.3125rem;
}

.moremenus-40:after {
  border-color: rgb(30, 39, 48) !important;
}


.moremenus-42 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}

.moremenus-43 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  font-family: sans-serif;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

}

.moremenus-44 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  white-space: pre-line;
  scrollbar-width: none;
  font-family: sans-serif;
  font-size: 0.8125rem;
  text-align: center;
  display: block;
  min-height: 2.3125rem;
  color: rgb(205, 206, 207);
}

.moremenus-44:after {
  border-color: rgb(30, 39, 48) !important;
}

.moremenus-45 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}

.moremenus-46 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 25%;
}

.moremenus-46:active {
  opacity: 0.7;
}

.moremenus-46:after {
  border-color: rgb(30, 39, 48) !important;
}

.moremenus-47 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  overflow: hidden;
  position: relative;
  scrollbar-width: none;
  font-family: sans-serif;
  width: 2.1875rem;
  height: 2.1875rem;
  margin-bottom: 0.3125rem;
}

.moremenus-47:after {
  border-color: rgb(30, 39, 48) !important;
}


.moremenus-49 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}

.moremenus-50 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  font-family: sans-serif;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

}

.moremenus-51 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  white-space: pre-line;
  scrollbar-width: none;
  font-family: sans-serif;
  font-size: 0.8125rem;
  text-align: center;
  display: block;
  min-height: 2.3125rem;
  color: rgb(205, 206, 207);
}

.moremenus-51:after {
  border-color: rgb(30, 39, 48) !important;
}

.moremenus-52 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}

.moremenus-53 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  margin-bottom: 1.5625rem;
}

.moremenus-53:after {
  border-color: rgb(30, 39, 48) !important;
}

.moremenus-54 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  white-space: pre-line;
  scrollbar-width: none;
  font-family: sans-serif;
  padding: 0px 1.25rem;
  font-size: 1rem;
  font-weight: 700;
  color: rgb(255, 255, 255);
}

.moremenus-54:after {
  border-color: rgb(30, 39, 48) !important;
}

.moremenus-55 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}

.moremenus-56 {
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  align-items: center;
  margin: 0.9375rem 0px;
}

.moremenus-56:after {
  border-color: rgb(30, 39, 48) !important;
}

.moremenus-57 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 25%;
}

.moremenus-57:active {
  opacity: 0.7;
}

.moremenus-57:after {
  border-color: rgb(30, 39, 48) !important;
}

.moremenus-58 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  overflow: hidden;
  position: relative;
  scrollbar-width: none;
  font-family: sans-serif;
  width: 2.1875rem;
  height: 2.1875rem;
  margin-bottom: 0.3125rem;
}

.moremenus-58:after {
  border-color: rgb(30, 39, 48) !important;
}

.moremenus-60 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}

.moremenus-61 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  font-family: sans-serif;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

}

.moremenus-62 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  white-space: pre-line;
  scrollbar-width: none;
  font-family: sans-serif;
  font-size: 0.8125rem;
  text-align: center;
  display: block;
  min-height: 2.3125rem;
  color: rgb(205, 206, 207);
}

.moremenus-62:after {
  border-color: rgb(30, 39, 48) !important;
}

.moremenus-63 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}

.moremenus-64 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 25%;
}

.moremenus-64:active {
  opacity: 0.7;
}

.moremenus-64:after {
  border-color: rgb(30, 39, 48) !important;
}

.moremenus-65 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  overflow: hidden;
  position: relative;
  scrollbar-width: none;
  font-family: sans-serif;
  width: 2.1875rem;
  height: 2.1875rem;
  margin-bottom: 0.3125rem;
}

.moremenus-65:after {
  border-color: rgb(30, 39, 48) !important;
}

.moremenus-67 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}

.moremenus-68 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  font-family: sans-serif;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

}

.moremenus-69 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  white-space: pre-line;
  scrollbar-width: none;
  font-family: sans-serif;
  font-size: 0.8125rem;
  text-align: center;
  display: block;
  min-height: 2.3125rem;
  color: rgb(205, 206, 207);
}

.moremenus-69:after {
  border-color: rgb(30, 39, 48) !important;
}

.moremenus-70 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}

.moremenus-71 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 25%;
}

.moremenus-71:active {
  opacity: 0.7;
}

.moremenus-71:after {
  border-color: rgb(30, 39, 48) !important;
}

.moremenus-72 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  overflow: hidden;
  position: relative;
  scrollbar-width: none;
  font-family: sans-serif;
  width: 2.1875rem;
  height: 2.1875rem;
  margin-bottom: 0.3125rem;
}

.moremenus-72:after {
  border-color: rgb(30, 39, 48) !important;
}


.moremenus-74 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}

.moremenus-75 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  font-family: sans-serif;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

}

.moremenus-76 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  white-space: pre-line;
  scrollbar-width: none;
  font-family: sans-serif;
  font-size: 0.8125rem;
  text-align: center;
  display: block;
  min-height: 2.3125rem;
  color: rgb(205, 206, 207);
}

.moremenus-76:after {
  border-color: rgb(30, 39, 48) !important;
}

.moremenus-77 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}