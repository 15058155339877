.propertypop-1 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  z-index: 999;
  position: fixed;
  right: 0px;
  bottom: 0px;
  transition: all 0.25s ease 0s;
  overflow: hidden;
  background: gainsboro;
  top: 0px;
  left: 0px;
  width: 75vw;
  visibility: visible;
  transform: translate(0px);
}

.propertypop-1:after {
  
}

.propertypop-2 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  width: 100%;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  height: auto;
  min-height: 100vh;
  max-height: 100vh;
}

.propertypop-2:after {
  
}

.propertypop-3 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  position: relative;
  width: 100%;
  height: 100%;
  max-height: inherit;
}

.propertypop-4 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  position: relative;
  width: 100%;
  height: 100%;
  max-height: inherit;
  overflow: hidden auto;
}

.propertypop-5 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  width: 100%;
  height: 100%;
}

.propertypop-6 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  padding: 1.25rem 0.9375rem;
  position: relative;
}

.propertypop-6:after {
  
}

.propertypop-7 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  white-space: pre-line;
  scrollbar-width: none;
  font-family: sans-serif;
  display: block;
  font-size: 1.0625rem;
  font-weight: 700;
  /* color: rgb(255, 255, 255); */
}

.propertypop-7:after {
  
}

.propertypop-8 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}

.propertypop-9 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  margin-top: 1.25rem;
}

.propertypop-9:after {
  
}

.propertypop-10 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  padding: 0.625rem;
  position: relative;
  margin-top: 0.625rem;
  border-radius: 0.3125rem;
  transition: border 0.25s ease 0s;
  border: 1px solid var(--boutton-background-color);
  /* background: rgb(24, 26, 29); */
  background: #FFFACD;
  
}

.propertypop-11 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  font-size: 0.9375rem;
  display: block;
  line-height: 1;
  margin-bottom: 0.25rem;
  /* color: rgb(255, 255, 255); */
}

.propertypop-11:after {
  
}

.propertypop-12 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  white-space: pre-line;
  scrollbar-width: none;
  font-family: sans-serif;
  font-size: 0.75rem;
  /* color: rgba(255, 255, 255, 0.4); */
}

.propertypop-12:after {
  
}

.propertypop-13 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}

.propertypop-14 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  padding: 0.625rem;
  position: relative;
  margin-top: 0.625rem;
  border-radius: 0.3125rem;
  transition: border 0.25s ease 0s;
  border: 1px solid rgb(24, 26, 29);
  background: #FFFACD;
}

.propertypop-14:after {
  
}

.propertypop-15 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  font-size: 0.9375rem;
  display: block;
  line-height: 1;
  margin-bottom: 0.25rem;
  /* color: rgb(255, 255, 255); */
}

.propertypop-15:after {
  
}

.propertypop-16 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  white-space: pre-line;
  scrollbar-width: none;
  font-family: sans-serif;
  font-size: 0.75rem;
  /* color: rgba(255, 255, 255, 0.4); */
}

.propertypop-16:after {
  
}

.propertypop-17 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}
