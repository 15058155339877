
.css-16igk0y {
  min-width: 1210px;
}

@media screen and (max-width: 1280px) {
  .css-16igk0y {
    min-width: auto;
  }
}

.css-ri80gd.slide-up {
  --tw-translate-y: -100%;
  -webkit-transform: translate(
      var(--tw-translate-x),
      var(--tw-translate-y)
  )
  rotate(var(--tw-rotate)) skewX(var(--tw-skew-x))
  skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
  scaleY(var(--tw-scale-y));
  -moz-transform: translate(
      var(--tw-translate-x),
      var(--tw-translate-y)
  )
  rotate(var(--tw-rotate)) skewX(var(--tw-skew-x))
  skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
  scaleY(var(--tw-scale-y));
  -ms-transform: translate(
      var(--tw-translate-x),
      var(--tw-translate-y)
  )
  rotate(var(--tw-rotate)) skewX(var(--tw-skew-x))
  skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
  scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
  rotate(var(--tw-rotate)) skewX(var(--tw-skew-x))
  skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
  scaleY(var(--tw-scale-y));
}

.css-11vadmq {
  width: initial;
  height: initial;
  display: inherit;
  -webkit-text-decoration: none;
  text-decoration: none;
}

.css-17x35y8 {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.css-10sv0al {
  position: relative;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  cursor: pointer;
  overflow: hidden;
  border-radius: var(--borderRadius-full);
  transition-property: color, background-color, border-color,
  text-decoration-color, fill, stroke;
  transition-timing-function: linear;
  transition-duration: 300ms;
  height: 1.5rem;
  width: 3rem;
  --tw-bg-opacity: 1;
  background-color: rgb(
      var(--colors-actions-grey-active) / var(--tw-bg-opacity)
  );
}

.css-10sv0al:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(
      var(--colors-actions-grey-hover) / var(--tw-bg-opacity)
  );
}

.css-10sv0al:is([data-theme="dark"] *) {
  --tw-bg-opacity: 1;
  background-color: rgb(
      var(--colors-dark-actions-grey-active) /
                          var(--tw-bg-opacity)
  );
}

.css-10sv0al:is([data-theme="dark"] *):hover {
  --tw-bg-opacity: 1;
  background-color: rgb(
      var(--colors-dark-actions-grey-hover) /
                          var(--tw-bg-opacity)
  );
}

.css-17yy9o6 {
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  font-weight: 600;
  transition-property: transform;
  transition-timing-function: cubic-bezier(
      0.4,
      0,
      0.2,
      1
  );
  transition-duration: 300ms;
  height: 1.5rem;
  width: 1.5rem;
  font-size: var(--fontSize-xs-0);
  line-height: var(--fontSize-xs-1-lineHeight);
  --tw-text-opacity: 1;
  color: rgb(
      var(--textColor-color-bg) / var(--tw-text-opacity)
  );
  left: auto;
  right: 2px;
}

.css-1x3c3gj {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: absolute;
  left: 0.125rem;
  right: auto;
  top: 0.125rem;
  border-radius: var(--borderRadius-full);
  --tw-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
  0px 1px 2px rgba(16, 24, 40, 0.06);
  --tw-shadow-colored: 0px 1px 3px var(--tw-shadow-color),
  0px 1px 2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
  var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  height: 1.25rem;
  width: 1.25rem;
  --tw-bg-opacity: 1;
  background-color: rgb(
      var(--colors-icon-color-bg) / var(--tw-bg-opacity)
  );
}

.group:active .css-1x3c3gj {
  width: 1.5rem;
}

.css-1vrtf0 {
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  font-weight: 600;
  transition-property: transform;
  transition-timing-function: cubic-bezier(
      0.4,
      0,
      0.2,
      1
  );
  transition-duration: 300ms;
  height: 1.5rem;
  width: 1.5rem;
  font-size: var(--fontSize-xs-0);
  line-height: var(--fontSize-xs-1-lineHeight);
  --tw-text-opacity: 1;
  color: rgb(
      var(--textColor-color-bg) / var(--tw-text-opacity)
  );
  left: 2px;
  --tw-translate-x: -2rem;
  -webkit-transform: translate(
      var(--tw-translate-x),
      var(--tw-translate-y)
  )
  rotate(var(--tw-rotate)) skewX(var(--tw-skew-x))
  skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
  scaleY(var(--tw-scale-y));
  -moz-transform: translate(
      var(--tw-translate-x),
      var(--tw-translate-y)
  )
  rotate(var(--tw-rotate)) skewX(var(--tw-skew-x))
  skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
  scaleY(var(--tw-scale-y));
  -ms-transform: translate(
      var(--tw-translate-x),
      var(--tw-translate-y)
  )
  rotate(var(--tw-rotate)) skewX(var(--tw-skew-x))
  skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
  scaleY(var(--tw-scale-y));
  transform: translate(
      var(--tw-translate-x),
      var(--tw-translate-y)
  )
  rotate(var(--tw-rotate)) skewX(var(--tw-skew-x))
  skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
  scaleY(var(--tw-scale-y));
}

.css-1wj38mq {
  width: 165px;
  height: 40px;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  background-color: #000000;
  border-radius: 6px;
  color: white;
  padding: 0px 2px;
  -webkit-transition: 100ms;
  transition: 100ms;
}

.css-1wj38mq:hover {
  background-color: #2a262c;
}

.css-16yni10 {
  width: 165px;
  height: 40px;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  background-color: #000000;
  border-radius: 6px;
  color: white;
  padding: 6px 8px;
  -webkit-transition: 100ms;
  transition: 100ms;
}

.css-16yni10:hover {
  background-color: #2a262c;
}

.css-1nxlsp8 {
  position: relative;
  min-width: 1210px;
  overflow-x: clip;
}

@media screen and (max-width: 1280px) {
  .css-1nxlsp8 {
    min-width: auto;
  }
}

.css-1m5jxu3 {
  --tw-bg-opacity: 1;
  background-color: rgb(
      var(--colors-background-neutral-main) / var(--tw-bg-opacity)
  );
  padding-top: 0px !important;
}

.css-1m5jxu3:is([data-theme="dark"] *) {
  --tw-bg-opacity: 1;
  background-color: rgb(
      var(--colors-dark-background-neutral-main) / var(--tw-bg-opacity)
  );
}

.css-1yh78r4 {
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 2.5rem;
  padding-top: 2rem;
}

@media (min-width: 1280px) {
  .css-1yh78r4 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-bottom: 2rem;
    padding-top: 0px;
  }
}

.css-11hb6xp {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 1rem;
  padding-top: 2rem;
}

.css-1m5jxu3 {
  --tw-bg-opacity: 1;
  background-color: rgb(
      var(--colors-background-neutral-main) / var(--tw-bg-opacity)
  );
  padding-top: 0px !important;
}

.css-1m5jxu3:is([data-theme="dark"] *) {
  --tw-bg-opacity: 1;
  background-color: rgb(
      var(--colors-dark-background-neutral-main) / var(--tw-bg-opacity)
  );
}

.css-11vadmq {
  width: initial;
  height: initial;
  display: inherit;
  -webkit-text-decoration: none;
  text-decoration: none;
}


.css-17x35y8 {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

[data-rsbs-overlay] {
  z-index: 2147483640 !important;
}

.css-1jj9cgs {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-items: center;
  gap: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (min-width: 1280px) {
  .css-1jj9cgs {
    display: grid;
    gap: 2rem;
    padding-left: 0px;
    padding-right: 0px;
  }
}

.css-7mhkks {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: auto;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: unset;
  -ms-flex-pack: unset;
  -webkit-justify-content: unset;
  justify-content: unset;
  gap: 8px;
}

.css-1k3u3dw {
  font-size: var(--fontSize-sm-0);
  line-height: var(--fontSize-sm-1-lineHeight);
  font-weight: 600;
  --tw-text-opacity: 1;
  color: rgb(
      var(--colors-text-neutral-subtle) /
                            var(--tw-text-opacity)
  );
}

.css-1k3u3dw:is([data-theme="dark"] *) {
  --tw-text-opacity: 1;
  color: rgb(
      var(--colors-dark-text-neutral-subtle) /
                            var(--tw-text-opacity)
  );
}

@media (min-width: 1280px) {
  .css-1k3u3dw {
    font-size: var(--fontSize-md-0);
    line-height: var(--fontSize-md-1);
  }
}

.css-151naro {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: auto;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  gap: 8px;
}

.css-r4hobi {
  font-size: var(--fontSize-heading-lg-0);
  line-height: var(--fontSize-heading-lg-1-lineHeight);
  font-weight: var(--fontSize-heading-lg-1-fontWeight);
  --tw-text-opacity: 1;
  color: rgb(
      var(--colors-text-neutral-strong) /
                              var(--tw-text-opacity)
  );
}

.css-r4hobi:is([data-theme="dark"] *) {
  --tw-text-opacity: 1;
  color: rgb(
      var(--colors-dark-text-neutral-strong) /
                              var(--tw-text-opacity)
  );
}

@media (min-width: 1280px) {
  .css-r4hobi {
    font-size: var(--fontSize-heading-xl-0);
    line-height: var(
        --fontSize-heading-xl-1-lineHeight
    );
    font-weight: var(
        --fontSize-heading-xl-1-fontWeight
    );
  }
}

.css-1wvvet6 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 2rem;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  gap: 2px;
  font-size: var(--fontSize-sm-0);
  line-height: var(
      --fontSize-sm-1-lineHeight
  );
}

.css-cjr3lq {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 0.5rem;
  gap: 1.5rem;
  border-bottom-width: 1px;
  border-bottom-color: rgb(
      var(--colors-outline-neutral-subtle) / 0.05
  );
}

.css-cjr3lq:is([data-theme="dark"] *) {
  border-bottom-color: rgb(
      var(--colors-dark-outline-neutral-subtle) / 0.05
  );
}

.css-4k5h5 {
  position: relative;
  cursor: pointer;
  padding-bottom: 0.25rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  --tw-text-opacity: 1;
  color: rgb(
      var(--textColor-main) / var(--tw-text-opacity)
  );
}

.css-4k5h5:is([data-theme="dark"] *) {
  --tw-text-opacity: 1;
  color: rgb(
      var(--textColor-dark-main) /
                                  var(--tw-text-opacity)
  );
}

.css-yt7azb {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
}

.css-yt7azb:after {
  content: " ";
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 1px;
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(
      var(--colors-primary) / var(--tw-bg-opacity)
  );
}

.css-12kojux {
  --tw-text-opacity: 1;
  color: rgb(
      var(--colors-text-color-teal) /
                                    var(--tw-text-opacity)
  );
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.1px;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgb(
      var(--colors-text-color-teal) /
                                    var(--tw-text-opacity)
  );
}

.css-12kojux:is([data-theme="dark"] *) {
  --tw-text-opacity: 1;
  color: rgb(
      var(--colors-dark-text-color-teal) /
                                    var(--tw-text-opacity)
  );
}

@media (min-width: 1280px) {
  .css-12kojux {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.2px;
    font-weight: 700;
  }
}

.css-12kojux:is([data-theme="dark"] *) {
  --tw-text-opacity: 1;
  color: rgb(
      var(--colors-dark-text-color-teal) /
                                    var(--tw-text-opacity)
  );
}

.css-kja941 {
  --tw-text-opacity: 1;
  color: rgb(
      var(--colors-text-neutral-subtle) /
                                    var(--tw-text-opacity)
  );
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.1px;
  font-weight: 600;
}

.css-kja941:is([data-theme="dark"] *) {
  --tw-text-opacity: 1;
  color: rgb(
      var(--colors-dark-text-neutral-subtle) /
                                    var(--tw-text-opacity)
  );
}

@media (min-width: 1280px) {
  .css-kja941 {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.15px;
    font-weight: 600;
  }
}

.css-rtlyos {
  width: undefinedpx;
  min-width: 100%;
}

.css-rtlyos
.rcs-custom-scroll
.rcs-custom-scrollbar {
  width: 0.25rem !important;
  right: 0.25rem !important;
}

.css-na86c1 {
  width: 100%;
  min-width: 100%;
  border-collapse: collapse;
}

.css-6a8odd .eqlqprq7 {
  border-bottom-color: rgb(
      var(--colors-outline-neutral-main) / 0.1
  );
}

.css-6a8odd .eqlqprq7:hover {
  background-color: rgb(
      var(--backgroundColor-transparent) / 0
  ) !important;
}

.css-6a8odd
.eqlqprq7:is(
                                        [data-theme="dark"] *
                                      ):hover {
  background-color: rgb(
      var(--backgroundColor-transparent) / 0
  ) !important;
}

.css-6a8odd
.eqlqprq7:is([data-theme="dark"] *) {
  border-bottom-color: rgb(
      var(--colors-dark-outline-neutral-main) /
                                          0.1
  );
}

.css-ebtifn {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-bottom-width: 1px;
  border-bottom-color: rgb(
      var(--colors-outline-neutral-main) /
                                            0.1
  );
  width: 100%;
}

.css-ebtifn:is([data-theme="dark"] *) {
  border-bottom-color: rgb(
      var(
          --colors-dark-outline-neutral-main
      ) / 0.1
  );
}

.css-ebtifn:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(
      var(
          --colors-background-neutral-strong
      ) / var(--tw-bg-opacity)
  );
}

.css-ebtifn:is(
                                          [data-theme="dark"] *
                                        ):hover {
  --tw-bg-opacity: 1;
  background-color: rgb(
      var(
          --colors-dark-background-neutral-strong
      ) / var(--tw-bg-opacity)
  );
}

.css-ebtifn:last-of-type {
  border-color: rgb(
      var(--colors-transparent) / 0
  );
}

.css-ebtifn:last-of-type:is(
                                          [data-theme="dark"] *
                                        ) {
  border-color: rgb(
      var(--colors-transparent) / 0
  );
}

.css-j1ef3z {
  max-width: 0px;
  text-align: left;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px 15px;
}

.css-j1ef3z:first-of-type {
  padding-left: 0px;
}

.css-j1ef3z:last-of-type {
  padding-right: 0px;
}

.css-1m6jekg {
  max-width: 0px;
  text-align: right;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 10px 15px;
}

.css-1m6jekg:first-of-type {
  padding-left: 0px;
}

.css-1m6jekg:last-of-type {
  padding-right: 0px;
}

.css-4zleql {
  display: block;
}

.css-15ftb7t {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: left;
  padding: 10px 15px;
}

.css-15ftb7t:first-of-type {
  padding-left: 0px;
}

.css-15ftb7t:last-of-type {
  padding-right: 0px;
}

.css-1w24zp7 {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: right;
  padding: 10px 15px;
}

.css-1w24zp7:first-of-type {
  padding-left: 0px;
}

.css-1w24zp7:last-of-type {
  padding-right: 0px;
}

.css-16igk0y {
  min-width: 1210px;
}

@media screen and (max-width: 1280px) {
  .css-16igk0y {
    min-width: auto;
  }
}

.css-17x35y8 {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.css-11vadmq {
  width: initial;
  height: initial;
  display: inherit;
  -webkit-text-decoration: none;
  text-decoration: none;
}
                  