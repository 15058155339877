.lockupKJInfo-1 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 16px 16px 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  width: 100vw;
}

.lockupKJInfo-2 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  margin-top: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.lockupKJInfo-3 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  border-style: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  width: 39px;
  height: 39px;
  margin-right: 11px;
}

.lockupKJInfo-4 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  display: flex;
  flex-direction: column;
  color: rgb(51, 51, 51);
  flex: 1 1 0%;
  align-items: flex-start;
}

.lockupKJInfo-5 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lockupKJInfo-5:first-child {
  font-size: 13px;
  font-weight: 400;
}

.lockupKJInfo-5:last-child {
  font-size: 16px;
  font-weight: 500;
}

.lockupKJInfo-6 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lockupKJInfo-6:first-child {
  font-size: 13px;
  font-weight: 400;
}

.lockupKJInfo-6:last-child {
  font-size: 16px;
  font-weight: 500;
}

.lockupKJInfo-7 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  display: flex;
  flex-direction: column;
  color: rgb(51, 51, 51);
}

.lockupKJInfo-8 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lockupKJInfo-8:first-child {
  font-size: 13px;
  font-weight: 400;
}

.lockupKJInfo-8:last-child {
  font-size: 16px;
  font-weight: 500;
}

.lockupKJInfo-9 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lockupKJInfo-9:first-child {
  font-size: 13px;
  font-weight: 400;
}

.lockupKJInfo-9:last-child {
  font-size: 16px;
  font-weight: 500;
}

.lockupKJInfo-10 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  margin-top: 26px;
  position: relative;
}

.lockupKJInfo-11 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 14px 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  top: 0px;
  left: 14px;
  width: calc(100% - 28px);
  position: absolute;
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.lockupKJInfo-12 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  display: flex;
  flex-direction: column;
  color: rgb(51, 51, 51);
}

.lockupKJInfo-13 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lockupKJInfo-13:first-child {
  font-size: 16px;
  font-weight: 500;
}

.lockupKJInfo-13:last-child {
  margin-top: 6px;
  font-size: 12px;
  font-weight: 400;
}

.lockupKJInfo-14 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lockupKJInfo-14:first-child {
  font-size: 16px;
  font-weight: 500;
}

.lockupKJInfo-14:last-child {
  margin-top: 6px;
  font-size: 12px;
  font-weight: 400;
}

.lockupKJInfo-15 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  display: flex;
  flex-direction: column;
  color: rgb(51, 51, 51);
}

.lockupKJInfo-16 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lockupKJInfo-16:first-child {
  font-size: 16px;
  font-weight: 500;
}

.lockupKJInfo-16:last-child {
  margin-top: 6px;
  font-size: 12px;
  font-weight: 400;
}

.lockupKJInfo-17 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lockupKJInfo-17:first-child {
  font-size: 16px;
  font-weight: 500;
}

.lockupKJInfo-17:last-child {
  margin-top: 6px;
  font-size: 12px;
  font-weight: 400;
}

.lockupKJInfo-18 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  height: 41px;
}

.lockupKJInfo-19 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 54px 0px 19px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: rgba(255, 255, 255, 0.55);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.lockupKJInfo-20 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  display: flex;
  flex-direction: column;
}

.lockupKJInfo-21 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lockupKJInfo-21:first-child {
  color: rgb(119, 119, 119);
  font-size: 14px;
  font-weight: 400;
}

.lockupKJInfo-21:last-child {
  color: rgb(54, 54, 54);
  margin-top: 15px;
  font-size: 16px;
  font-weight: 500;
}

.lockupKJInfo-22 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lockupKJInfo-22:first-child {
  color: rgb(119, 119, 119);
  font-size: 14px;
  font-weight: 400;
}

.lockupKJInfo-22:last-child {
  color: rgb(54, 54, 54);
  margin-top: 15px;
  font-size: 16px;
  font-weight: 500;
}

.lockupKJInfo-23 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  display: flex;
  flex-direction: column;
}

.lockupKJInfo-24 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lockupKJInfo-24:first-child {
  color: rgb(119, 119, 119);
  font-size: 14px;
  font-weight: 400;
}

.lockupKJInfo-24:last-child {
  color: rgb(54, 54, 54);
  margin-top: 15px;
  font-size: 16px;
  font-weight: 500;
}

.lockupKJInfo-25 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lockupKJInfo-25:first-child {
  color: rgb(119, 119, 119);
  font-size: 14px;
  font-weight: 400;
}

.lockupKJInfo-25:last-child {
  color: rgb(54, 54, 54);
  margin-top: 15px;
  font-size: 16px;
  font-weight: 500;
}

.lockupKJInfo-26 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  display: flex;
  flex-direction: column;
}

.lockupKJInfo-27 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lockupKJInfo-27:first-child {
  color: rgb(119, 119, 119);
  font-size: 14px;
  font-weight: 400;
}

.lockupKJInfo-27:last-child {
  color: rgb(54, 54, 54);
  margin-top: 15px;
  font-size: 16px;
  font-weight: 500;
}

.lockupKJInfo-28 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lockupKJInfo-28:first-child {
  color: rgb(119, 119, 119);
  font-size: 14px;
  font-weight: 400;
}

.lockupKJInfo-28:last-child {
  color: rgb(54, 54, 54);
  margin-top: 15px;
  font-size: 16px;
  font-weight: 500;
}

.lockupKJInfo-29 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 16px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: rgba(255, 255, 255, 0.55);
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.lockupKJInfo-30 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  display: flex;
  flex-direction: column;
}

.lockupKJInfo-31 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lockupKJInfo-31:first-child {
  color: rgb(119, 119, 119);
  font-size: 14px;
  font-weight: 400;
}

.lockupKJInfo-31:last-child {
  color: rgb(54, 54, 54);
  margin-top: 15px;
  font-size: 16px;
  font-weight: 500;
}

.lockupKJInfo-32 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lockupKJInfo-32:first-child {
  color: rgb(119, 119, 119);
  font-size: 14px;
  font-weight: 400;
}

.lockupKJInfo-32:last-child {
  color: rgb(54, 54, 54);
  margin-top: 15px;
  font-size: 16px;
  font-weight: 500;
}

.lockupKJInfo-33 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  display: flex;
  flex-direction: column;
}

.lockupKJInfo-34 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lockupKJInfo-34:first-child {
  color: rgb(119, 119, 119);
  font-size: 14px;
  font-weight: 400;
}

.lockupKJInfo-34:last-child {
  color: rgb(54, 54, 54);
  margin-top: 15px;
  font-size: 16px;
  font-weight: 500;
}

.lockupKJInfo-35 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lockupKJInfo-35:first-child {
  color: rgb(119, 119, 119);
  font-size: 14px;
  font-weight: 400;
}

.lockupKJInfo-35:last-child {
  color: rgb(54, 54, 54);
  margin-top: 15px;
  font-size: 16px;
  font-weight: 500;
}

.lockupKJInfo-36 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 16px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: rgba(255, 255, 255, 0.55);
  margin-top: 10px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.lockupKJInfo-37 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lockupKJInfo-37:first-child {
  color: rgb(51, 51, 51);
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 9px;
}

.lockupKJInfo-37:last-child {
  width: 100%;
  height: 38px;
  background: rgb(255, 255, 255);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 8px;
}

.lockupKJInfo-38 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lockupKJInfo-39 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lockupKJInfo-39:first-child {
  color: rgb(51, 51, 51);
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 9px;
}

.lockupKJInfo-39:last-child {
  width: 100%;
  height: 38px;
  background: rgb(255, 255, 255);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 8px;
}

.lockupKJInfo-40 {
  box-sizing: inherit;
  -webkit-tap-highlight-color: transparent;
  color: rgb(51, 51, 51);
  font: inherit;
  margin: 0px;
  overflow: visible;
  text-transform: none;
  vertical-align: middle;
  flex: 2 1 0%;
  border: none;
  font-size: 15px;
  font-weight: 400;
}

.lockupKJInfo-40:focus {
  outline: 0px;
}

.lockupKJInfo-41 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  flex: 1 1 0%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.lockupKJInfo-41:first-child {
  color: rgb(51, 51, 51);
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 9px;
}

.lockupKJInfo-41:last-child {
  width: 100%;
  height: 38px;
  background: rgb(255, 255, 255);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 8px;
}

.lockupKJInfo-42 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lockupKJInfo-42:first-child {
  color: rgb(119, 119, 119);
  font-size: 12px;
  font-weight: 400;
}

.lockupKJInfo-42:last-child {
  color: rgb(54, 54, 54);
  font-size: 15px;
  font-weight: 600;
}

.lockupKJInfo-43 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lockupKJInfo-43:first-child {
  color: rgb(119, 119, 119);
  font-size: 12px;
  font-weight: 400;
}

.lockupKJInfo-43:last-child {
  color: rgb(54, 54, 54);
  font-size: 15px;
  font-weight: 600;
}

.lockupKJInfo-44 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 16px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: rgba(255, 255, 255, 0.55);
  margin-top: 10px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.lockupKJInfo-45 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lockupKJInfo-45:first-child {
  color: rgb(51, 51, 51);
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 9px;
}

.lockupKJInfo-45:last-child {
  color: rgb(51, 51, 51);
  font-size: 12px;
  font-weight: 400;
  text-align: left;
}

.lockupKJInfo-46 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lockupKJInfo-46:first-child {
  color: rgb(51, 51, 51);
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 9px;
}

.lockupKJInfo-46:last-child {
  color: rgb(51, 51, 51);
  font-size: 12px;
  font-weight: 400;
  text-align: left;
}

.lockupKJInfo-47 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  color: rgb(255, 255, 255);
  font: inherit;
  margin: 0px;
  overflow: visible;
  text-transform: none;
  position: relative;
  display: inline-block;
  height: 44px;
  padding: 0px 15px;
  font-size: 14px;
  line-height: 1.2;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  transition: opacity 0.2s ease 0s;
  appearance: none;
  background-color: rgb(7, 193, 96);
  border: none;
  width: 100%;
  background: #5ecc9d!important;
  margin-top: 25px;
  margin-bottom: 25px;
}

.lockupKJInfo-48:focus {
  outline: 0px;
}

.lockupKJInfo-48 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 100%;
}

.lockupKJInfo-48:before {
  content: " ";
}

.lockupKJInfo-49:focus {
  outline: 0px;
}

.lockupKJInfo-49 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}
