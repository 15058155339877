.dataempty-1 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 40vh;
}

.dataempty-1:after {
  border-color: rgb(30, 39, 48) !important;
}

.dataempty-2 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  overflow: hidden;
  position: relative;
  scrollbar-width: none;
  font-family: sans-serif;
  width: 9.375rem;
  height: 9.375rem;
  margin-bottom: 0.9375rem;
}

.dataempty-2:after {
  border-color: rgb(30, 39, 48) !important;
}

.dataempty-4 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}

.dataempty-5 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  font-family: sans-serif;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.dataempty-6 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  font-size: 0.8125rem;
  color: rgb(153, 153, 153);
  text-align: center;
  line-height: 1.5;
}

.dataempty-6:after {
  border-color: rgb(30, 39, 48) !important;
}