.homePopCenter-1 {
  padding: 20px;
  padding-top: 66px;
  background-color: var(--them-background);
  font-family: Arial, sans-serif;
}

.profile-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.profile-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.profile-info {
  margin-left: 15px;
}

.profile-name {
  font-size: 18px;
  font-weight: bold;
}

.profile-id {
  font-size: 14px;
  color: #888;
  margin-top: 5px;
}

.vip-icon {
  width: 3.06rem;
  height: 1.09rem;
  margin-left: 5px;
}

.auth-section {
  position: relative;
  margin-top: 20px;
  padding: 10px;
  background-image: url("/public/home/ide-bg.png");
  background-size: cover;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.auth-info {
  display: flex;
  flex-direction: column;
}

.auth-title {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
}

.auth-status {
  font-size: 14px;
  color: #b0f4e6;
  margin-top: 5px;
}

.auth-arrow {
  width: 1.94rem;
  height: 1.94rem;
  border-radius: 100%;
  border: 0.09rem solid #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  > span {
    font-size: 16px;
    color: white;
  }
}

.menu-list {
  margin-top: 30px;
  margin-left: 2rem;
}

.menu-item {
  font-size: 16px;
  color: var(--them-color-black-text);
  height: 3rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.menu-item:hover {
  background-color: #f9f9f9;
}

.menu-item-badge {
  right: -1.6rem;
  width: 1.6rem;
  height: 1.6rem;
}
.menu-item-badge .adm-badge-content {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid white;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
