.tradechangebar-1 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  width: 100%;
  height: 100%;
}

.tradechangebar-2 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 0.9375rem;
}

.tradechangebar-2:after {
  border-color: rgb(30, 39, 48) !important;
}

.tradechangebar-3 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-content: center;
  height: 42px;
  position: relative;
  z-index: 1;
  text-align: center;
  transition: all 0.2s ease 0s;
  padding: 0px;
  margin-right: 1.25rem;
  font-size: 0.875rem;
  width: auto !important;
}

.tradechangebar-3:after {
  border-color: rgb(30, 39, 48) !important;
}

.tradechangebar-4 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  font-family: sans-serif;
  white-space: nowrap;
  color: var(--boutton-background-color);
}
.css-vtstzx {
  --tw-text-opacity: 1;
  color: rgb(var(--colors-text-color-teal) / var(--tw-text-opacity));
}

.tradechangebar-4:after {
  border-color: rgb(30, 39, 48) !important;
}

.tradechangebar-5 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}

.tradechangebar-6 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-content: center;
  height: 42px;
  position: relative;
  z-index: 1;
  text-align: center;
  transition: all 0.2s ease 0s;
  padding: 0px;
  margin-right: 1.25rem;
  font-size: 0.875rem;
  width: auto !important;

}

.tradechangebar-6:after {
  border-color: rgb(30, 39, 48) !important;
}

.tradechangebar-7 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  font-family: sans-serif;
  white-space: nowrap;
  color: rgb(100, 101, 102);
}

.tradechangebar-7:after {
  border-color: rgb(30, 39, 48) !important;
}

.tradechangebar-8 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}

.tradechangebar-9 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-content: center;
  height: 42px;
  position: relative;
  z-index: 1;
  text-align: center;
  transition: all 0.2s ease 0s;
  padding: 0px;
  margin-right: 1.25rem;
  font-size: 0.875rem;
  width: auto !important;
  color: rgb(100, 101, 102);
}

.tradechangebar-9:after {
  border-color: rgb(30, 39, 48) !important;
}

.tradechangebar-10 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  font-family: sans-serif;
  white-space: nowrap;
}

.tradechangebar-10:after {
  border-color: rgb(30, 39, 48) !important;
}

.tradechangebar-11 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}

.tradechangebar-12 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  position: absolute;
  transition: all ease 0.25s;
  transform: translateX(33.09375px);
  bottom: 0px;
  width: 23.25px;
  height: 0.1875rem;
  border-radius: 3.125rem;
}

.tradechangebar-12:after {
  border-color: rgb(30, 39, 48) !important;
}

.tradelistruning-1 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  padding: 0px 0.9375rem 4.0625rem;
}

.tradelistruning-1:after {
  border-color: rgb(30, 39, 48) !important;
}

.tradelistruning-2 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  padding-top: 0.9375rem;
  position: relative;
  padding-bottom: 0.625rem;
  margin-bottom: 0.9375rem;
}

.tradelistruning-2:after {
  border-color: rgb(30, 39, 48) !important;
  content: " ";
  position: absolute;
  left: 0px;
  top: 0px;
  pointer-events: none;
  box-sizing: border-box;
  transform-origin: 0px 0px;
  width: 200%;
  height: 200%;
  transform: scale(0.5);
  z-index: 1;
  border: 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.tradelistruning-3 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}

.tradelistruning-3:after {
  border-color: rgb(30, 39, 48) !important;
}

.tradelistruning-4 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  color: #04cf99;
  padding: 0px 0px 0.625rem;
  justify-content: space-between;
}

.tradelistruning-4:after {
  border-color: rgb(30, 39, 48) !important;
}

.tradelistruning-5 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  white-space: pre-line;
  scrollbar-width: none;
  font-family: sans-serif;
}

.tradelistruning-5:after {
  border-color: rgb(30, 39, 48) !important;
}

.tradelistruning-6 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}

.tradelistruning-7 {
  margin-left: auto;
  margin-right: auto;
  padding-left: 14px;
  padding-right: 14px;
  text-align: center;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  background-color: rgb(248, 248, 248);
  cursor: pointer;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  overflow: hidden;
  position: relative;
  outline: 0px;
  justify-content: center;
  display: flex;
  align-items: center;
  margin: 0px;
  font-size: 0.8125rem;
  color: rgb(240, 193, 99);
  border: 1px solid;
  border-radius: 0.25rem;
  height: 1.625rem;
  padding: 0px 0.75rem;
  line-height: 1;
  background: transparent;
}

.tradelistruning-7:after {
  width: 200%;
  top: 0px;
  left: 0px;
  transform: scale(0.5);
  transform-origin: 0px 0px;
  content: "";
  position: absolute;
  inset: 0px;
  pointer-events: none;
  border-color: rgb(30, 39, 48) !important;
}

@media (prefers-color-scheme: dark) {
  .tradelistruning-7 {
    color: rgb(214, 214, 214);
    background-color: rgb(52, 52, 52);
  }
}
.tradelistruning-8 {
  -webkit-tap-highlight-color: transparent;
  display: block;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  border-radius: 0.5rem;
  margin: undefined;
  padding: 11px;
  color: var(--them-color-black-text);
  background-image: url(/public/orderList/order-list-bg.png);
  background-position-x: -2rem;
}

.tradelistruning-8:after {
  border-color: rgb(30, 39, 48) !important;
}

.tradelistruning-9 {
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.75rem;
  position: relative;
  margin: 0px;
  border-radius: 0px;
  padding: 0.1875rem 0px;
}

.tradelistruning-9:after {
  border-color: rgb(30, 39, 48) !important;
}

.tradelistruning-10 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  white-space: pre-line;
  scrollbar-width: none;
  font-family: sans-serif;
  font-size: 0.875rem;
  margin-right: auto;
  /* color: rgba(255, 255, 255, 0.4); */
}

.tradelistruning-10:after {
  border-color: rgb(30, 39, 48) !important;
}

.tradelistruning-11 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}

.tradelistruning-12 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  align-items: center;
  font-size: 0.8125rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 700;
  flex: 1 1 0%;
  margin-left: 0.9375rem;
  justify-content: flex-end;
  /* color: rgb(255, 255, 255); */
}

.tradelistruning-12:after {
  border-color: rgb(30, 39, 48) !important;
}

.tradelistruning-13 {
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.75rem;
  position: relative;
  margin: 0px;
  border-radius: 0px;
  padding: 0.1875rem 0px;
}

.tradelistruning-13:after {
  border-color: rgb(30, 39, 48) !important;
}

.tradelistruning-14 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  white-space: pre-line;
  scrollbar-width: none;
  font-family: sans-serif;
  font-size: 0.875rem;
  margin-right: auto;
  /* color: rgba(255, 255, 255, 0.4); */
}

.tradelistruning-14:after {
  border-color: rgb(30, 39, 48) !important;
}

.tradelistruning-15 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}

.tradelistruning-16 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  align-items: center;
  font-size: 0.8125rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 700;
  flex: 1 1 0%;
  margin-left: 0.9375rem;
  justify-content: flex-end;
  color: rgb(64, 145, 151);
}

.tradelistruning-16-1 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  align-items: center;
  font-size: 0.8125rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 700;
  flex: 1 1 0%;
  margin-left: 0.9375rem;
  justify-content: flex-end;
  color: rgb(243, 100, 100);
}
.tradelistruning-16:after {
  border-color: rgb(30, 39, 48) !important;
}

.tradelistruning-17 {
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.75rem;
  position: relative;
  margin: 0px;
  border-radius: 0px;
  padding: 0.1875rem 0px;
}

.tradelistruning-17:after {
  border-color: rgb(30, 39, 48) !important;
}

.tradelistruning-18 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  white-space: pre-line;
  scrollbar-width: none;
  font-family: sans-serif;
  font-size: 0.875rem;
  margin-right: auto;
  /* color: rgba(255, 255, 255, 0.4); */
}

.tradelistruning-18:after {
  border-color: rgb(30, 39, 48) !important;
}

.tradelistruning-19 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}

.tradelistruning-20 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  align-items: center;
  font-size: 0.8125rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 700;
  flex: 1 1 0%;
  margin-left: 0.9375rem;
  justify-content: flex-end;
  /* color: rgb(255, 255, 255); */
}

.tradelistruning-20:after {
  border-color: rgb(30, 39, 48) !important;
}

.tradelistruning-21 {
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.75rem;
  position: relative;
  margin: 0px;
  border-radius: 0px;
  padding: 0.1875rem 0px;
}

.tradelistruning-21:after {
  border-color: rgb(30, 39, 48) !important;
}

.tradelistruning-22 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  white-space: pre-line;
  scrollbar-width: none;
  font-family: sans-serif;
  font-size: 0.875rem;
  margin-right: auto;
  /* color: rgba(255, 255, 255, 0.4); */
}

.tradelistruning-22:after {
  border-color: rgb(30, 39, 48) !important;
}

.tradelistruning-23 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}

.tradelistruning-24 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  align-items: center;
  font-size: 0.8125rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 700;
  flex: 1 1 0%;
  margin-left: 0.9375rem;
  justify-content: flex-end;
  /* color: rgb(255, 255, 255); */
}

.tradelistruning-24:after {
  border-color: rgb(30, 39, 48) !important;
}

.tradelistruning-25 {
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.75rem;
  position: relative;
  margin: 0px;
  border-radius: 0px;
  padding: 0.1875rem 0px;
}

.tradelistruning-25:after {
  border-color: rgb(30, 39, 48) !important;
}

.tradelistruning-26 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  white-space: pre-line;
  scrollbar-width: none;
  font-family: sans-serif;
  font-size: 0.875rem;
  margin-right: auto;
  /* color: rgba(255, 255, 255, 0.4); */
}

.tradelistruning-26:after {
  border-color: rgb(30, 39, 48) !important;
}

.tradelistruning-27 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}

.tradelistruning-28 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  align-items: center;
  font-size: 0.8125rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 700;
  flex: 1 1 0%;
  margin-left: 0.9375rem;
  justify-content: flex-end;
  /* color: rgb(255, 255, 255); */
}

.tradelistruning-28:after {
  border-color: rgb(30, 39, 48) !important;
}

.tradelistruning-29 {
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.75rem;
  position: relative;
  margin: 0px;
  border-radius: 0px;
  padding: 0.1875rem 0px;
}

.tradelistruning-29:after {
  border-color: rgb(30, 39, 48) !important;
}

.tradelistruning-30 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  white-space: pre-line;
  scrollbar-width: none;
  font-family: sans-serif;
  font-size: 0.875rem;
  margin-right: auto;
  /* color: rgba(255, 255, 255, 0.4); */
}

.tradelistruning-30:after {
  border-color: rgb(30, 39, 48) !important;
}

.tradelistruning-31 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}

.tradelistruning-32 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  align-items: center;
  font-size: 0.8125rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 700;
  flex: 1 1 0%;
  margin-left: 0.9375rem;
  justify-content: flex-end;
  /* color: rgb(255, 255, 255); */
}

.tradelistruning-32:after {
  border-color: rgb(30, 39, 48) !important;
}

.tradelistruning-33 {
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.75rem;
  position: relative;
  margin: 0px;
  border-radius: 0px;
  padding: 0.1875rem 0px;
}

.tradelistruning-33:after {
  border-color: rgb(30, 39, 48) !important;
}

.tradelistruning-34 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  white-space: pre-line;
  scrollbar-width: none;
  font-family: sans-serif;
  font-size: 0.875rem;
  margin-right: auto;
  /* color: rgba(255, 255, 255, 0.4); */
}

.tradelistruning-34:after {
  border-color: rgb(30, 39, 48) !important;
}

.tradelistruning-35 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
}

.tradelistruning-36 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: none;
  box-sizing: border-box;
  font-family: sans-serif;
  display: flex;
  align-items: center;
  font-size: 0.8125rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 700;
  flex: 1 1 0%;
  margin-left: 0.9375rem;
  justify-content: flex-end;
  /* color: rgb(255, 255, 255); */
}

.tradelistruning-36:after {
  border-color: rgb(30, 39, 48) !important;
}
