.css-rmiqmu {
    position: absolute;
    left: 50%;
    display: flex;
    height: 42px;
    width: 42px;
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    align-items: flex-end;
    -webkit-box-pack: center;
    justify-content: center;
    border-bottom-right-radius: var(--borderRadius-full);
    border-bottom-left-radius: var(--borderRadius-full);
    padding-left: 3px;
    padding-right: 3px;
    padding-bottom: 0.25rem;
    --tw-bg-opacity: 1;
    background-color: rgb(124 92 188 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(var(--textColor-white) / var(--tw-text-opacity));
    cursor: pointer;
    z-index: 1;
}
.css-rmiqmu::before {
    content: " ";
    background-image: url(data:image/svg+xml,%3Csvg width='15' height='10' viewBox='0 0 6 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 0L5.23713 4.01749C5.23713 4.01749 4.6684 2.41768 3.51637 1.49606C2.36434 0.574438 0 0 0 0H5Z' fill='%237C5CBC'/%3E%3C/svg%3E);
    position: absolute;
    left: -0.625rem;
    bottom: 10px;
    height: 10px;
    width: 15px;
}
.css-rmiqmu::after {
    content: " ";
    background-image: url(data:image/svg+xml,%3Csvg width='15' height='10' viewBox='0 0 6 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 0L0.762873 4.01749C0.762873 4.01749 1.3316 2.41768 2.48363 1.49606C3.63566 0.574438 6 0 6 0H1Z' fill='%237C5CBC'/%3E%3C/svg%3E);
    position: absolute;
    right: -0.625rem;
    bottom: 10px;
    height: 10px;
    width: 15px;
}